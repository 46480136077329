/** @format */

import React, { Component } from "react";
import Modal from "../../components/modal";
import {
  CheckCircle,
  UploadCloud,
  Download,
  Edit2,
  XCircle,
} from "react-feather";
import LButton from "../../components/loadingButton";
import Nav from "../../components/Nav";
import Form from "../../components/form";
import { creatCsvFile, downloadFile } from "download-csv";
import readXlsxFile from "read-excel-file";
import moment from "moment";

class CheckOff extends Component {
  state = {
    downloadModal: false,
    reciept: "",
    cheque_no: "",
    cheque_amount: "",
    chequeUsers: "",
    cheque: "",
  };
  render() {
    return (
      <div className="">
        <Nav
          name="Checkoff"
          buttons={[
            {
              text: "Download Format",
              onClick: () => {
                this.setState({ downloadModal: true });
              },
            },
          ]}
        ></Nav>

        <form className="py-5 mt-3 table-card  border-0 card shado mx-3 shadow ">
          <div className="container ">
            <div className="p-3">
              <div className="row">
                <div className="col-md-6">
                  <span className="ml-1 mb-2 d-block ">Cheque Number</span>
                  <input
                    type="text"
                    // style={{
                    //   margin: "15px",
                    //   border: "1px dotted #CC8433",
                    //   minHeight: "25px",
                    //   display: "block",
                    // }}
                    placeholder="..."
                    className="form-control bg-light text-input"
                    name="cheque_no"
                    // value={this.state.cheque_no}
                    required={true}
                    onChange={(e) =>
                      this.setState({ cheque_no: e.target.value })
                    }
                  />
                </div>

                <div className="col-md-6">
                  <span className="ml-1 mb-2 d-block ">Cheque Amount</span>
                  <input
                    type="text"
                    // style={{
                    //   margin: "15px",
                    //   border: "1px dotted #CC8433",
                    //   minHeight: "25px",
                    //   display: "block",
                    // }}
                    placeholder="..."
                    className="form-control bg-light text-input"
                    name="cheque_amount"
                    // value={this.state.cheque_amount}
                    required={true}
                    onChange={(e) =>
                      this.setState({ cheque_amount: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="p-3">
              <div className="row">
                <div className="col-md-6">
                  <span className="ml-1 mb-2 d-block ">Cheque Date</span>
                  <input
                    type="date"
                    // style={{
                    //   margin: "15px",
                    //   border: "1px dotted #CC8433",
                    //   minHeight: "25px",
                    //   display: "block",
                    // }}
                    placeholder="..."
                    className="form-control bg-light text-input"
                    name="cheque_amount"
                    // value={this.state.cheque_amount}
                    required={true}
                    onChange={(e) =>
                      this.setState({ cheque_date: e.target.value })
                    }
                  />
                </div>

                <div className="col-md-6">
                  <span className="ml-1 mb-2 d-block ">Upload users list</span>
                  <input
                    type="file"
                    // style={{
                    //   margin: "15px",
                    //   border: "1px dotted #CC8433",
                    //   minHeight: "25px",
                    //   display: "block",
                    // }}
                    // accept="csv/*"
                    placeholder="..."
                    className="form-control bg-light text-input"
                    id="chequeUsers"
                    name="chequeUsers"
                    // value={this.state.selectedFile}
                    required={true}
                    onChange={(e) =>
                      this.setState({ chequeUsers: e.target.files[0] })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="p-3">
              <div className="row">
                <div className="col-md-6">
                  <span className="ml-1 mb-2 d-block ">
                    Upload Cheque Image
                  </span>
                  <input
                    type="file"
                    // style={{
                    //   margin: "15px",
                    //   border: "1px dotted #CC8433",
                    //   minHeight: "25px",
                    //   display: "block",
                    // }}
                    // accept="csv/*"
                    placeholder="..."
                    className="form-control bg-light text-input"
                    name="cheque"
                    // value={this.state.selectedFile}
                    required={true}
                    onChange={(e) =>
                      this.setState({ cheque: e.target.files[0] })
                    }
                  />
                </div>

                <div className="col-md-6">
                  <span className="ml-1 mb-2 d-block ">
                    Upload Reciept Image
                  </span>
                  <input
                    type="file"
                    // style={{
                    //   margin: "15px",
                    //   border: "1px dotted #CC8433",
                    //   minHeight: "25px",
                    //   display: "block",
                    // }}
                    // accept="csv/*"
                    placeholder="..."
                    className="form-control bg-light text-input"
                    name="reciept"
                    // value={this.state.selectedFile}
                    required={true}
                    onChange={(e) =>
                      this.setState({ reciept: e.target.files[0] })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="mt-3">
              <button
                type="submit"
                className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round"
                disabled={!this.state.reciept || !this.state.cheque}
                onClick={this.processCheckoff}
              >
                Submit Checkoff
              </button>
            </div>
          </div>
        </form>

        <Modal
          visible={this.state.modalVisible}
          dismiss={false}
          close={() => this.setState({ modalVisible: false })}
        >
          <div>
            <div className="d-flex flex-row align-items-center">
              {this.state.uploadSuccessful ? (
                <CheckCircle color="#4caf50" size={64} />
              ) : (
                <div className="lds-spinner">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              )}
              <div className="ml-5 text-dark">
                {!this.state.uploadSuccessful ? (
                  <h3>Uploading ...</h3>
                ) : (
                  <h3>{this.state.message}</h3>
                )}
              </div>
            </div>
            {this.state.uploadSuccessful && (
              <div className="text-center">
                <button
                  className="btn btn-primary mt-4 px-5"
                  onClick={() =>
                    this.setState({ modalVisible: false }, function () {
                      window.location.reload();
                    })
                  }
                >
                  Done
                </button>
              </div>
            )}
          </div>
        </Modal>

        <Modal
          visible={this.state.downloadModal}
          close={() => this.setState({ downloadModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Download Format</h5>
          </div>

          <div className="mt-3">
            <Form
              submitText={"Generate"}
              back={false}
              inputs={[
                {
                  label: "File  Name",
                  name: "name",
                  readonly: "readonly",
                  value: "checkoff.csv",
                },
              ]}
              submit={(data) => {
                //console.log(data);
                this.setState({ details: data });
                setTimeout(() => {
                  this.download(data);
                }, 0);
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  processCheckoff = (e) => {
    e.preventDefault();
    // console.log(this.state.cheque_no);
    // console.log(this.state.cheque_amount);
    // console.log(this.state.chequeUsers);
    // console.log(this.state.cheque);
    // console.log(this.state.cheque_date);
    let formData = new FormData();

    let postData = {
      cheque_no: this.state.cheque_no,
      cheque_amount: this.state.cheque_amount,
      chequeUsers: this.state.chequeUsers,
      cheque_date: this.state.cheque_date,
      cheque: this.state.cheque,
      reciept: this.state.reciept,
    };

    Object.keys(postData).map((d) => {
      let data = postData[d];
      try {
        console.log("data", data);
        if (data.substring(0, 11) === "data:image/") {
          formData.append(d, this.dataURLtoFile(data), "file.png");
        } else {
          formData.append(d, data);
        }
      } catch (error) {
        console.error(error);
        formData.append(d, data);
      }
    });

    // console.log(postData)

    fetch(`${window.server}/utils/admin/upload-checkoff`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        // "Content-Type": "application/json",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        if (response.status == "201") {
          this.setState({ uploadSuccessful: true, message: response.result });
          window.location.reload();
          // alert(
          //   response.result +
          //   " \n " +
          //   (response.errors[0] ? response.errors[0].result : "")
          // );
          // this.setState({ modalVisible: false });
        } else {
          window.alert2.show({
            loader: false,
            icon: <XCircle size={60}></XCircle>,
            title: "Error " + response.code,
            buttons: true,
            message:
              response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : ""),
          });

          this.setState({ modalVisible: false });
        }
      })
      .catch((d) => {
        //console.log("Error saving the data");
        //console.log(d);
        this.setState({ modalVisible: false });
      });
  };

  download = (data) => {
    // if (
    //   !window.confirm("Are you sure that you want to download?")
    // )
    //   return false;

    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    // let values = Object.values(obj);
    let sampleData = [
      {
        full_name: "Bruno Wabwire",
        msisdn: "254701194812",
        amount: 4000,
        employee_no: "ONF_00178",
      },
      // { full_name: "John Muchiri", msisdn: "254714705745", amount: 3000 },
      // { full_name: "Cornelius Okaya", msisdn: "254743411403", amount: 2000 },
    ];
    let name = obj.name;
    let json = sampleData;
    let jsonArray = [];
    json.map((d, i) => {
      jsonArray.push(d);
    });

    // console.log(name);
    // let json2 = [];
    // json2.push(json)
    console.log(jsonArray);

    /* File Name */
    let file_name = name;
    let filename = file_name.split(".csv")[0];
    let exportFileName = filename.concat(".csv");
    console.log(filename);

    let columns = [
      { header: "Full Names", key: "full_name", width: 5 },
      { header: "Phone Number", key: "msisdn", width: 25 },
      { header: "Amount", key: "user_amount", width: 25 },
    ];

    let csvfile = creatCsvFile(jsonArray, columns);
    downloadFile(csvfile, exportFileName);
  };
}

export default CheckOff;
