/* eslint-disable no-undef */
import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import {
  Plus,
  Target,
  Circle,
  Smartphone,
  Edit2,
  Download,
} from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";
import Form from "../../components/form";
import { creatCsvFile, downloadFile} from 'download-csv';

class ReconciliationStatus extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    downloadModal: false,
    query: {},
    filter: {},
    table_loading: false,
  };

  timeout = null;
  render() {
    !this.state.response[this.state.currentUser] ||
      console.log("name", this.state.response[this.state.currentUser].name);
    return (
      <div className="">
        <Nav
          name="Reconciliation Status"
          // buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              search={[]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>

        <Modal
          visible={this.state.downloadModal}
          close={() => this.setState({ downloadModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Download Results</h5>
          </div>
          {typeof this.state.currentUser !== "undefined" && (
            <div className="mt-3">
              <Form
                submitText={"Generate"}
                back={false}
                inputs={[
                  {
                    label: "File Name",
                    name: "name",
                    readonly: "readonly",
                    value: this.state.response.data[this.state.currentUser]
                      .name,
                  },
                  {
                    name: "recons",
                    type: "hidden",
                    value: this.state.response.data[this.state.currentUser]
                      .recons,
                  },
                ]}
                submit={(data) => {
                  //console.log(data);
                  this.setState({ details: data });
                  setTimeout(() => {
                    this.download(data);
                  }, 0);
                }}
              />
            </div>
          )}
        </Modal>
      </div>
    );
  }

  fetch = () => {
    this.setState({ table_loading: true });

    let q = {
      //...this.state.filter,
      ...this.state.query,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    fetch(`${window.server}/utils/download?${urlParams}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log(response);

        let data = [];
        response.data.map((d, i) => {
          let download_string = window.server + (d.url).replace('public' , '')
          data.push({
            name: d.name,
            url: download_string,
            action: (
              <div className="d-flex flex-row">
                <button
                  onClick={() => {
                    /* this.setState({
                      currentUser: i,
                      fileName: d.name,
                      downloadModal: true,
                    }); */
                    window.open(download_string)
                    console.log("download_string",download_string)
                  }}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                >
                  <Download size={18} />
                  <span className="pl-1 font-weight-bold">Download</span>
                </button>
                {/* <Link
                  // to={}
                  className="btn btn-sm btn-primary px-3 btn-round"
                >
                  Download
                </Link> */}
              </div>
            ),
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidMount = () => {
    this.fetch();
  };

  download = (data) => {
    // if (
    //   !window.confirm("Are you sure that you want to download?")
    // )
    //   return false;

    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    // let values = Object.values(obj);
    let name = obj.name;
    let json = obj.recons;
    let jsonArray = [];
    json.map((d, i) => {
      jsonArray.push(d);
    });

    // console.log(name);
    // let json2 = [];
    // json2.push(json)
    // console.log(jsonArray);

    /* File Name */
    let file_name = name;
    let filename = file_name.split(".xlsx")[0];
    let exportFileName = filename.concat(".csv");
    console.log(filename);

     let columns = [
      { header: "Mpesa Code", key: "receipt_no", width: 5 },
      { header: "completion Time", key: "completion_time", width: 25 },
      { header: "Initiation Time", key: "initiation_time", width: 25 },
      { header: "Details", key: "details", width: 50 },
      { header: "Transaction Status", key: "transaction_status", width: 5 },
      { header: "Amount", key: "paid_in", width: 25 },
      { header: "Withdrawn", key: "withdrawn", width: 25 },
      { header: "Balance", key: "balance", width: 10 },
      { header: "Balance Confirmed", key: "balance_confirmed", width: 25 },
      { header: "Reason Type", key: "reason_type", width: 10 },
      { header: "Other Party Info", key: "other_party_info", width: 25 },
      { header: "Linked Trans Id", key: "linked_trans_id", width: 10 },
      { header: "Account No", key: "account_no", width: 25 },
      { header: "Recon Status", key: "recon_status", width: 10 },
    ];
     
   let csvfile = creatCsvFile(jsonArray, columns); 
    downloadFile(csvfile, exportFileName); 
  };
}

export default ReconciliationStatus;
