import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class MemberPayments extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              search={["firstname"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchMemberPayments = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      loan_id: this.props.match.params.id,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    /*   */

    // console.log(urlParams);
    fetch(`${window.server}/loan-transactions-log?${urlParams}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        let data = [];
        console.log("response.data", response.data[0].customer_id);
        let q = {
          // ...this.state.filter,
          ...this.state.query,
          customer_id: response.data[0].customer_id,
        };

        let urlParams = Object.entries(q)
          .map((e) => e.join("="))
          .join("&");

        fetch(`${window.server}/utils/wallet?${urlParams}`, {
          headers: {
            Authorization: localStorage.token,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            console.log("xx", response.data);
            let payments = response.data.filter((a) => {
              return a.narration.includes(
                `Loan Repayment For Loan Id:${this.props.match.params.id}`
              );
            });
            console.log("payments", payments);
            payments.map((d, i) => {
              console.log("xx", d.narration);
              data.push({
                /* load_id: d.loan_id, */
                transaction_id: d.transaction_id,
                transaction_ref_no: d.transaction_ref_no,
                debit: parseFloat(parseFloat(d.debit)).toLocaleString(),
                credit: parseFloat(parseFloat(d.credit)).toLocaleString(),
                balance_before: parseFloat(
                  parseFloat(d.balance_before)
                ).toLocaleString(),
                balance_after: parseFloat(
                  parseFloat(d.balance_after)
                ).toLocaleString(),

                description: d.description,
                narration: d.narration,
                date_created: moment(d.date_created).format(
                  "Do MMMM YYYY, h:mm:ss a"
                ),
                //load_id: d.id,
                //loan_request_id: d.loan_request_id,
                //customer_name: `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`,
                //loan_type: d.product.product_name,
                // loan_type: d.product_name,
                // amount: parseFloat(parseFloat(d.request.amount)).toLocaleString(),
                // interest_charged: parseFloat(
                //   parseFloat(d.request.amount * 0.05)
                // ).toLocaleString(),
                // processing_fee: parseFloat(
                //   parseFloat(d.request.amount * 0.025)
                // ).toLocaleString(),
                // amount_paid: parseFloat(
                //   parseFloat(d.account.amount_paid)
                // ).toLocaleString(),
                // interest_balance: parseFloat(
                //   parseFloat(d.account.interest_balance)
                // ).toLocaleString(),
                // commission_balance: parseFloat(
                //   parseFloat(d.account.commission_balance)
                // ).toLocaleString(),
                // outstanding_balance: parseFloat(
                //   parseFloat(d.account.principal_balance)
                // ).toLocaleString(),
                // payment_status: ["Not cleared", "Cleared"][d.clearance_status],
                // repayment_duration: d.request.repayment_duration + " months",
                // status: d.status,
                // status: parseFloat(d.loan_status) === 1 ? "Normal" : "Defaulted",
                // guarantor_status: ["Not Guaranteed", "Fully Guaranteed"][
                //   d.request.guarantor_status
                // ],
                // guaranteed_amount: "KES " +
                //   parseFloat(
                //     parseFloat(d.request.guaranteed_amount)
                //   ).toLocaleString(),
                // loan_products: d["loan-products"],
                /* loan_date: moment(d.account.date_created).format(
              "Do MMMM YYYY, h:mm:ss a"
            ),
            repayment_date: moment(d.account.date_created)
              .add(d.request.repayment_duration, "months")
              .format("Do MMMM YYYY, h:mm:ss a"), */
              });
            });
            let dts = {};
            dts.data = data;

            this.setState({
              tableData: { ...response, ...dts },
              response,
              table_loading: false,
            });
          });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchMemberPayments();
      }, 100);
    }
  }
}

export default MemberPayments;
