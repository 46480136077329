import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


export default class Example extends PureComponent {

  render() {
    return (
      <div style={{ width: '100%', height: 450 }}>
      <ResponsiveContainer>
        <LineChart
          width={800}
          height={300}
          data={this.props.data}
          margin={{
            top: 100,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis dataKey={"DAY"} />
          <YAxis type="number"/>
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="expected_amount" stroke="#82ca9d" />
          <Line type="monotone" dataKey="amount_paid" stroke="#8884d8" activeDot={{ r: 8 }} />          
          <Line type="monotone" dataKey="balance" stroke="#FF0000" />
        </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
