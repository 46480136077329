/** @format */

import React, { Component } from "react";
import { Route, Link, Switch } from "react-router-dom";
import moment from "moment";
// import Details from "./clients/details";
import {
  Plus,
  Edit,
  Check,
  AlertTriangle,
  ThumbsUp,
  RefreshCcw,
  Edit2,
  UserX,
  LogOut,
  LogIn,
  UserPlus,
  CheckCircle,
  Briefcase,
  DollarSign,
  FilePlus,
  RefreshCw,
  XCircle,
  Bell,
  BellOff,
} from "react-feather";
import Tabs from "../../components/tabs";
import Details from "./details";
import MemberPayments from "./payments";
import Modal from "../../components/modal";
import Form from "../../components/form";
import MemberLoans from "./loans";
import MemberDeposits from "./deposits";
import MemberSMS from "./sms";
import MemberSavings from "./savings";
import MemberShares from "./shares";
import MemberWallet from "./wallet";
import MemberWithdrawals from "./withdrawals";
import Guarantors from "./guarantors";
import Guarantees from "./guarantees";
import PendingLoanRequests from "./pending";
import MemberLinkedAccount from "./memberLinkAccount";
import { User, Image } from "react-feather";

class MembersView extends Component {
  state = {
    currentRoute: "",
    editModal: false,
    leaveModal: false,
    cancelModal: false,
    smsModal: false,
    response: { data: [] },
    linkModal: false,
    customerPhotos: {},
  };
  render() {
    let user = [];

    return (
      <div className="bg-light">
        <div className="card table-card border-0 shadow m-3 user-card">
          <div className="text-mute pt-3 pl-3">
            <small className="text-mute">Members > View</small>
          </div>

          <div className="profile p-3 d-md-flex flex-row align-items-center justify-content-between">
            <div className="d-md-flex flex-row align-items-center">
              <div className="border avatar-lg bg-light d-flex flex-row align-items-center justify-content-center">
                <span className="initials">
                  {this.state.customer === null
                    ? "--"
                    : this.state.customer && this.state.customer.firstname[0]}
                </span>
                <img
                  src={`${window.server}${
                    this.state.details &&
                    (this.state.details.passport_path
                      ? this.state.details.passport_path.replace("public", "")
                      : "")
                  }`}
                  className="avatar"
                  alt=""
                />
              </div>
              <div className="ml-md-4 my-3 my-md-0">
                <h4 className="text-capitalize">
                  {this.state.customer === null
                    ? "--"
                    : this.state.customer && this.state.customer.firstname}{" "}
                  {this.state.customer && this.state.customer.middlename}{" "}
                  {this.state.customer === null
                    ? "--"
                    : this.state.customer && this.state.customer.surname}
                </h4>
                <div>+ {this.state.msisdn && this.state.msisdn.msisdn}</div>
                <div className="ml-2 mt-1">
                  <span className="badge badge-secondary px-2">Member</span>
                </div>
              </div>
            </div>

            <div className="d-flex flex-row flex-wrap member-view ">
              <div className="d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end">
                <button
                  onClick={() => {
                    this.setState({ leaveModal: true });
                    //console.log(this.state.response.data[0]);
                  }}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                >
                  <LogOut size={18} />
                  <span className="pl-1 font-weight-bold">Exit Member</span>
                </button>

                <button
                  disabled={
                    this.state.customer &&
                    ((this.state.exits && this.state.exits.status != 1) || // member has not  exited
                      (this.state.exits && this.state.exits.status != 0)) && // member is exit not pending
                    this.state.customer.account_status != 1 // member is not active
                      ? true
                      : false
                  }
                  onClick={this.unblockUser}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                >
                  <CheckCircle size={18} />
                  <p>{this.state.exits?.status}</p>
                  <p>{this.state.customer?.account_status}</p>
                  <span className="pl-1 font-weight-bold">Unblock Account</span>
                </button>

                <button
                  disabled={
                    this.state.customer &&
                    ((this.state.exits && this.state.exits.status == 1) || // member   exited
                      (this.state.exits && this.state.exits.status == 0)) && // member is exit  pending
                    this.state.customer.account_status != 1 // member is not active
                      ? true
                      : false
                  }
                  onClick={this.unsuspendUser}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                >
                  <XCircle size={18} />
                  <span className="pl-1 font-weight-bold">
                    unsuspend Account
                  </span>
                </button>
                <button
                  onClick={() => {
                    this.setState({ linkModal: true });
                    //console.log(this.state.response.data[0]);
                  }}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                >
                  <Briefcase size={18} />
                  <span className="pl-1 font-weight-bold">Link Account</span>
                </button>
                <button
                  onClick={() => {
                    this.setState({ smsModal: true });
                    //console.log(this.state.response.data[0]);
                  }}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                >
                  <Briefcase size={18} />
                  <span className="pl-1 font-weight-bold">Send SMS</span>
                </button>

                <button
                  onClick={this.resetPin}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                >
                  <RefreshCw size={18} />
                  <span className="pl-1 font-weight-bold">Reset pin</span>
                </button>

                <button
                  onClick={() => {
                    this.setState({ editModal: true });
                    //console.log(this.state.response.data[0]);
                  }}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                >
                  <Edit size={18} />
                  <span className="pl-1 font-weight-bold">Edit profile</span>
                </button>

                <button
                  onClick={() => {
                    this.setState({ cancelModal: true });
                    //console.log(this.state.response.data[0]);
                  }}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                >
                  <AlertTriangle size={18} />
                  <span className="pl-1 font-weight-bold">Cancel Exit</span>
                </button>

                {/* <Link
                  to={`/loan-add/1/${this.props.match.params.id}`}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                >
                  <FilePlus size={18} />
                  <span className="pl-1 font-weight-bold">Add a Loan</span>
                </Link> */}
              </div>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between">
            {this.state.msisdn && (
              <Tabs
                tabs={[
                  {
                    label: "DETAILS",
                    link:
                      "/member-view/details/" +
                      this.props.match.params.customer_id,
                  },
                  {
                    label: "LOANS",
                    link:
                      "/member-view/loans/" +
                      this.props.match.params.customer_id,
                  },
                  {
                    label: "DEPOSITS",
                    link:
                      "/member-view/deposits/" +
                      this.props.match.params.customer_id,
                  },
                  {
                    label: "WALLET",
                    link:
                      "/member-view/wallet/" +
                      this.props.match.params.customer_id,
                  },
                  {
                    label: "MESSAGES",
                    link:
                      "/member-view/sms/" +
                      this.props.match.params.customer_id +
                      "/" +
                      this.state.msisdn.msisdn,
                  },
                  {
                    label: "WITHDRAWS",
                    link:
                      "/member-view/withdraws/" +
                      this.props.match.params.customer_id,
                  },
                  {
                    label: "SAVINGS",
                    link:
                      "/member-view/savings/" +
                      this.props.match.params.customer_id,
                  },
                  {
                    label: "SHARES",
                    link:
                      "/member-view/shares/" +
                      this.props.match.params.customer_id,
                  },
                  {
                    label: "GUARANTORS",
                    link:
                      "/member-view/guarantors/" +
                      this.props.match.params.customer_id,
                  },
                  {
                    label: "GUARANTEES",
                    link:
                      "/member-view/guarantees/" +
                      this.props.match.params.customer_id,
                  },
                  {
                    label: "LOAN REQUEST",
                    link:
                      "/member-view/pending/" +
                      this.props.match.params.customer_id,
                  },
                  {
                    label: "LINKED ACCOUNTS",
                    link:
                      "/member-view/linkedAccounts/" +
                      this.props.match.params.customer_id,
                  },
                ]}
              ></Tabs>
            )}
          </div>
        </div>
        <Route
          path="/member-view/details/:customer_id"
          exact
          component={Details}
        />
        <Route
          path="/member-view/loans/:customer_id"
          exact
          component={MemberLoans}
        />
        <Route
          path="/member-view/deposits/:customer_id"
          exact
          component={MemberDeposits}
        />
        <Route
          path="/member-view/sms/:customer_id/:msisdn"
          exact
          component={MemberSMS}
        />
        <Route
          path="/member-view/savings/:customer_id"
          exact
          component={MemberSavings}
        />
        <Route
          path="/member-view/shares/:customer_id"
          exact
          component={MemberShares}
        />
        <Route
          path="/member-view/wallet/:customer_id"
          exact
          component={MemberWallet}
        />
        <Route
          path="/member-view/withdraws/:customer_id"
          exact
          component={MemberWithdrawals}
        />
        <Route
          path="/member-view/guarantors/:customer_id"
          exact
          component={Guarantors}
        />
        <Route
          path="/member-view/guarantees/:customer_id"
          exact
          component={Guarantees}
        />

        <Route
          path="/member-view/pending/:customer_id"
          exact
          component={PendingLoanRequests}
        />
        <Route
          path="/member-view/linkedAccounts/:customer_id"
          exact
          component={MemberLinkedAccount}
        />

        <Modal
          visible={this.state.editModal}
          close={() => this.setState({ editModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Update Member details</h5>
          </div>
          {this.state.id && (
            <div className="mt-3">
              <Form
                submitText={"Update"}
                back={false}
                inputs={[
                  {
                    label: "Passport photo",
                    name: "passport_path",
                    value: this.state.passport_path,
                    type: "passport",
                  },
                  {
                    label: "First name",
                    name: "firstname",
                    value: this.state.customer.firstname,
                    type: "tel",
                    readonly: "readonly",
                  },
                  {
                    label: "Middle name",
                    name: "middlename",
                    value: this.state.customer.middlename,
                    type: "tel",
                    readonly: "readonly",
                  },
                  {
                    label: "Surname",
                    name: "surname",
                    value: this.state.customer.surname,
                    type: "tel",
                    readonly: "readonly",
                  },
                  {
                    label: "Phone number",
                    name: "msisdn",
                    value: this.state.msisdn.msisdn,
                    type: "tel",
                    readonly: "readonly",
                  },
                  // {
                  //   label: "Date Of Birth",
                  //   name: "date_of_birth",
                  //   value: this.state.details.date_of_birth,
                  //   type: "date",
                  //   readonly: "readonly"
                  // },
                  // {
                  //   label: "Date Of Registration",
                  //   name: "created_at",
                  //   value: moment(this.state.created_at).format('YYYY MM DD'),
                  //   type: "date",
                  //   readonly: "readonly"
                  // },
                  {
                    label: "Gender",
                    name: "gender",
                    // readonly: "readonly",
                    value: this.state.details.gender,
                    type: "select",
                    options: [
                      { name: "Male", value: "M" },
                      { name: "Female", value: "F" },
                    ],
                  },
                  // {
                  //   label: "Marital Status",
                  //   name: "marital_status",
                  //   value: this.state.marital_status,
                  //   type: "select",
                  //   options: [
                  //     { name: "Single", value: 1 },
                  //     { name: "Married", value: "2" }
                  //   ]
                  // },

                  {
                    label: "Address",
                    name: "address",
                    value: this.state.details.address,
                    readonly: "readonly",
                  },
                  {
                    label: "nationality",
                    name: "nationality",
                    readonly: "readonly",
                    value: this.state.details.nationality,
                    type: "select",
                    options: [
                      { name: "Kenyan", value: 1 },
                      { name: "Nigerian", value: 2 },
                    ],
                  },
                  {
                    label: "Pledge (Kshs)",
                    name: "pledge",
                    value:
                      this.state.customer === null
                        ? "--"
                        : this.state.customer.pledge,
                    type: "number",
                    readonly: "readonly",
                  },
                  { type: "break" },
                  {
                    label: "Identification Type",
                    name: "identity_type",
                    readonly: "readonly",
                    value: this.state.details.identity_type,
                    type: "select",
                    options: [
                      { name: "Identity Card", value: 1 },
                      { name: "Passport Number", value: 2 },
                    ],
                  },
                  {
                    label: "ID number",
                    name: "id_number",
                    type: "number",
                    readonly: "readonly",
                    value: this.state.details.id_number,
                  },
                  {
                    label: "KRA pin",
                    name: "kra_pin",
                    readonly: "readonly",
                    value: this.state.details.kra_pin,
                  },
                  {
                    label: "",
                    name: "id_front_path",
                    value: this.state.details.id_front_path,
                    type: "id",
                  },
                  {
                    label: "",
                    name: "id_back_path",
                    value: this.state.details.id_back_path,
                    type: "hidden",
                  },
                ]}
                submit={(data) => {
                  console.log(data);

                  // alert(JSON.stringify(data));
                  this.setState({ details: data });
                  setTimeout(() => {
                    this.updateUser(data);
                  }, 0);
                }}
              />
            </div>
          )}{" "}
        </Modal>

        <Modal
          visible={this.state.leaveModal}
          close={() => this.setState({ leaveModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Exit Member</h5>
          </div>

          <div className="mt-3">
            <Form
              submitText={"Exit Member"}
              back={false}
              inputs={[
                {
                  label: "Exit Reason",
                  name: "exit_reason",
                  value: this.state.exit_reason,
                },
              ]}
              submit={(data) => {
                console.log(data);
                this.setState({ details: data });
                setTimeout(() => {
                  this.exitMember(data);
                }, 0);
              }}
            />
          </div>
        </Modal>

        <Modal
          visible={this.state.smsModal}
          close={() => this.setState({ smsModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Send SMS</h5>
          </div>
          {this.state.id && (
            <div className="mt-3">
              <Form
                submitText={"Send SMS"}
                back={false}
                inputs={[
                  {
                    label: "Message",
                    name: "msg_text",
                    type: "textarea",
                    value: this.state.msg_text,
                  },
                  {
                    label: "Phone number",
                    name: "msisdn",
                    value: this.state.msisdn.msisdn,
                    type: "tel",
                    readonly: "readonly",
                  },
                ]}
                submit={(data) => {
                  console.log(data);
                  this.setState({ details: data });
                  setTimeout(() => {
                    this.sendSms(data);
                  }, 0);
                }}
              />
            </div>
          )}{" "}
        </Modal>

        <Modal
          visible={this.state.linkModal}
          close={() => this.setState({ linkModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Link Account</h5>
          </div>
          {this.state.id && (
            <div className="mt-3">
              <Form
                submitText={"Link Account"}
                back={false}
                inputs={[
                  {
                    label: "ID Number",
                    name: "national_id",
                    type: "text",
                    value: this.state.details.id_number,
                  },
                  {
                    label: "Phone number",
                    name: "msisdn",
                    value: this.state.msisdn.msisdn,
                    type: "tel",
                  },
                  {
                    label: "New number",
                    name: "new_msisdn",
                    value: this.state.new_msisdn,
                    type: "tel",
                  },
                  {
                    label: "Comment",
                    name: "comment",
                    value: this.state.comment,
                    type: "textarea",
                  },
                ]}
                submit={(data) => {
                  console.log(data);
                  this.setState({ details: data });
                  setTimeout(() => {
                    this.linkAccount(data);
                  }, 0);
                }}
              />
            </div>
          )}{" "}
        </Modal>
        <Modal
          visible={this.state.cancelModal}
          close={() => this.setState({ cancelModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Cancel Member Exit</h5>
          </div>

          <div className="mt-3">
            <Form
              submitText={"Cancel Exit"}
              back={false}
              inputs={[
                {
                  label: "Description",
                  name: "reason_to_cancel",
                  value: this.state.reason_to_cancel,
                },
              ]}
              submit={(data) => {
                console.log(data);
                this.setState({ details: data });
                setTimeout(() => {
                  this.cancelExit(data);
                  this.setState({ cancelModal: false });
                }, 0);
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    let user_id = this.props.match.params.customer_id;
    console.log(user_id);
    fetch(`${window.server}/entity-accounts?customer_id=${user_id}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("rrrrr", response);
        this.setState({ ...response.data[0] });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };

  verify(data) {
    let result = true;
    let missing = [];
    Object.keys(data).map((d) => {
      if (!data[d] || data[d] === "") {
        missing.push(d.replace("_id", "").replace(/_/g, " "));
        result = false;
      }
    });
    missing.join(", ");
    if (!result) alert("Please fill all the require fields : " + missing);
    return result;
  }

  updateUser = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to update this member's data?"
      )
    )
      return false;
    let postData = data;
    console.log("ccc", postData);
    postData.msisdn = window.verifyNumber(postData.msisdn);
    const formData = new FormData();
    Object.keys(postData).map((d) => {
      let data = postData[d];
      try {
        if (
          d == "id_back_path" ||
          d == "id_front_path" ||
          d === "passport_path"
        ) {
          formData.append(d, this.dataURLtoFile(data));
        } else {
          formData.append(d, data);
        }
      } catch (error) {
        console.error(error);
        formData.append(d, data);
      }
    });
    fetch(
      `${window.server}/utils/admin/customer_update/${this.props.match.params.customer_id}`,
      {
        method: "PATCH",
        headers: {
          Authorization: localStorage.token,
          // "content-type": "multipart/form-data",
        },
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("fetch", response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
        } else {
          console.log(response);
          alert("Member details have been updated successfully");
          this.setState({ uploadSuccessful: true });
          this.setState({ editModal: false });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ editModal: false });
      });
  };

  unblockUser = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to unblock this member's account?"
      )
    )
      return false;

    fetch(`${window.server}/utils/admin/unblock`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id: this.props.match.params.customer_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
        } else {
          console.log(response);
          alert(response.result);
          this.setState({ uploadSuccessful: true });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
      });
  };

  unsuspendUser = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to unsuspend  this member's account?"
      )
    )
      return false;

    fetch(`${window.server}/utils/admin/unsuspend`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id: this.props.match.params.customer_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
        } else {
          console.log(response);
          alert(response.result);
          this.setState({ uploadSuccessful: true });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
      });
  };

  exitMember = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to exit this member's account ?"
      )
    )
      return false;
    //console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    let values = Object.values(obj);

    //alert(values);

    fetch(`${window.server}/utils/admin/exit-member`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id: this.props.match.params.customer_id,
        exit_reason: data.exit_reason,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
        } else {
          console.log(response);
          alert(response.result);
          // this.setState({ uploadSuccessful: true });
          this.setState({ leaveModal: false });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ leaveModal: false });
      });
  };

  resetPin = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to Reset the pin for this member's account ?"
      )
    )
      return false;

    fetch(`${window.server}/utils/admin/reset-pin`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id: this.props.match.params.customer_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
        } else {
          console.log(response);
          alert(response.result);
          this.setState({ uploadSuccessful: true });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
      });
  };

  sendSms = (data) => {
    fetch(`${window.server}/utils/admin/send-sms`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id: this.props.match.params.customer_id,
        msg_text: data.msg_text,
        msisdn: data.msisdn,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert("Message Sent");
          /* alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          ); */
          this.setState({ smsModal: false });
        } else {
          console.log(response);
          alert(response.result);
          // this.setState({ uploadSuccessful: true });
          this.setState({ smsModal: false });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        alert(d.message);
        this.setState({ smsModal: false });
      });
  };
  linkAccount = (data) => {
    fetch(`${window.server}/utils/link-account-temp`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id: this.props.match.params.customer_id,
        national_id: data.national_id,
        msisdn: data.msisdn,
        new_msisdn: window.verifyNumber(data.new_msisdn),
        comment: data.comment,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
        } else {
          console.log(response);
          alert(response.result);
          // this.setState({ uploadSuccessful: true });
          this.setState({ smsModal: false });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ smsModal: false });
      });
  };
  cancelExit = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to cancel exit  of this member's account ?"
      )
    )
      return false;
    //console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    let values = Object.values(obj);

    //alert(values);

    fetch(`${window.server}/utils/admin/unexit`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id: this.props.match.params.customer_id,
        exit_reason: data.exit_reason,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
        } else {
          console.log(response);
          alert(response.result);
          // this.setState({ uploadSuccessful: true });
          this.setState({ leaveModal: false });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ leaveModal: false });
      });
  };
}

export default MembersView;
