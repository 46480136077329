/** @format */

import React, { Component } from "react";
import { Route, Link, Switch } from "react-router-dom";
import moment from "moment";
import Access from "../../components/accessManager";
// import Details from "./clients/details";
import {
  Plus,
  Edit,
  Check,
  AlertTriangle,
  ThumbsUp,
  RefreshCcw,
  Edit2,
  UserX,
  LogOut,
  LogIn,
  UserPlus,
  CheckCircle,
  Briefcase,
  Delete,
  DollarSign,
  FilePlus,
  RefreshCw,
  XCircle,
  Bell,
  BellOff,
} from "react-feather";
import Tabs from "../../components/tabs";
import Details from "./details";
import EmployeeSMS from "./sms";
import Modal from "../../components/modal";
import Form from "../../components/form";

class EmployeesView extends Component {
  state = {
    currentRoute: "",
    editModal: false,
    smsModal: false,
    response: { data: [] },
  };
  render() {
    let user = [];

    return (
      <div className='bg-light'>
        <div className='card table-card border-0 shadow m-3 user-card'>
          <div className='text-mute pt-3 pl-3'>
            <small className='text-mute'>Employee > View</small>
          </div>

          <div className='profile p-3 d-md-flex flex-row align-items-center justify-content-between'>
            <div className='d-md-flex flex-row align-items-center'>
              <div className='border avatar-lg bg-light d-flex flex-row align-items-center justify-content-center'>
                <span className='initials'>
                  {this.state.firstname && this.state.firstname[0]}
                </span>
                <img
                  src={`${window.server}${
                    this.state.passport_path &&
                    this.state.passport_path.replace("public", "")
                  }`}
                  className='avatar'
                  alt=''
                />
              </div>
              <div className='ml-md-4 my-3 my-md-0'>
                <h4 className='text-capitalize'>
                  {this.state.firstname && this.state.firstname}{" "}
                  {this.state.middlename && this.state.middlename}{" "}
                  {this.state.surname && this.state.surname}
                </h4>
                <div>+ {this.state.msisdn && this.state.msisdn}</div>
                <div className='ml-2 mt-1'>
                  <span className='badge badge-secondary px-2'>Employee</span>
                </div>
              </div>
            </div>

            <div className='d-flex flex-row flex-wrap member-view '>
              <div className='d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end'>
                <button
                  onClick={() => {
                    this.setState({ smsModal: true });
                    //console.log(this.state.response.data[0]);
                  }}
                  className='option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3'
                >
                  <Briefcase size={18} />
                  <span className='pl-1 font-weight-bold'>Send SMS</span>
                </button>

                <button
                  onClick={() => {
                    this.setState({ editModal: true });
                    //console.log(this.state.response.data[0]);
                  }}
                  className='option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3'
                >
                  <Edit size={18} />
                  <span className='pl-1 font-weight-bold'>Edit profile</span>
                </button>
              </div>
            </div>
          </div>
          <div className='d-flex flex-row align-items-center justify-content-between'>
            {this.state.msisdn && (
              <Tabs
                tabs={[
                  {
                    label: "DETAILS",
                    link:
                      "/employee-view/details/" + this.props.match.params.id,
                  },
                  {
                    label: "MESSAGES",
                    link:
                      "/employee-view/sms/" +
                      this.props.match.params.id +
                      "/" +
                      this.state.msisdn,
                  },
                ]}
              ></Tabs>
            )}
          </div>
        </div>
        <Route path='/employee-view/details/:id' exact component={Details} />
        <Route
          path='/employee-view/sms/:id/:msisdn'
          exact
          component={EmployeeSMS}
        />

        <Modal
          visible={this.state.editModal}
          close={() => this.setState({ editModal: false })}
        >
          <div className='d-flex flex-row align-items-center'>
            <Edit2 className='mr-3'></Edit2>
            <h5 className='m-0 mt-1'>Update Employee details</h5>
          </div>
          {this.state.id && (
            <div className='mt-3'>
              <Form
                submitText={"Update"}
                back={false}
                inputs={[
                  // {
                  //   label: "Passport photo",
                  //   name: "passport",
                  //   value: this.state.passport,
                  //   type: "passport"
                  // },
                  {
                    label: "First name",
                    name: "firstname",
                    value: this.state.firstname,
                  },
                  {
                    label: "Middle name",
                    name: "middlename",
                    value: this.state.middlename,
                  },
                  {
                    label: "Surname",
                    name: "surname",
                    value: this.state.surname,
                  },
                  {
                    label: "Phone number",
                    name: "msisdn",
                    value: this.state.msisdn,
                    type: "tel",
                    //readonly: "readonly"
                  },
                  // {
                  //   label: "Date Of Birth",
                  //   name: "date_of_birth",
                  //   value: this.state.details.date_of_birth,
                  //   type: "date",
                  //   readonly: "readonly"
                  // },
                  // {
                  //   label: "Date Of Registration",
                  //   name: "created_at",
                  //   value: moment(this.state.created_at).format('YYYY MM DD'),
                  //   type: "date",
                  //   readonly: "readonly"
                  // },
                  // {
                  //   label: "Gender",
                  //   name: "gender",
                  // //   readonly: "readonly",
                  //   value: this.state.gender,
                  //   type: "select",
                  //   options: [
                  //     { name: "Male", value: "1" },
                  //     { name: "Female", value: "2" }
                  //   ]
                  // },
                  {
                    label: "Access Role",
                    name: "group_id",
                    value: this.state.group_id,
                    type: "select",
                    options: window.groups.reverse().map((d) => {
                      return { name: d.name, value: d.id };
                    }),
                  },
                  {
                    label: "ID number",
                    name: "id_number",
                    type: "number",
                    readonly: "readonly",
                    value: this.state.id_number,
                  },
                  {
                    label: "KRA pin",
                    name: "kra_pin",
                    readonly: "readonly",
                    value: this.state.kra_pin,
                  },
                ]}
                submit={(data) => {
                  console.log(data);
                  this.setState({ details: data });
                  setTimeout(() => {
                    this.updateUser(data);
                  }, 0);
                }}
              />
            </div>
          )}{" "}
        </Modal>

        <Modal
          visible={this.state.smsModal}
          close={() => this.setState({ smsModal: false })}
        >
          <div className='d-flex flex-row align-items-center'>
            <Edit2 className='mr-3'></Edit2>
            <h5 className='m-0 mt-1'>Send SMS</h5>
          </div>
          {this.state.id && (
            <div className='mt-3'>
              <Form
                submitText={"Send SMS"}
                back={false}
                inputs={[
                  {
                    label: "Message",
                    name: "msg_text",
                    type: "textarea",
                    value: this.state.msg_text,
                  },
                  {
                    label: "Phone number",
                    name: "msisdn",
                    value: this.state.msisdn,
                    type: "tel",
                    readonly: "readonly",
                  },
                ]}
                submit={(data) => {
                  console.log(data);
                  this.setState({ details: data });
                  setTimeout(() => {
                    this.sendSms(data);
                  }, 0);
                }}
              />
            </div>
          )}{" "}
        </Modal>
      </div>
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    let user_id = this.props.match.params.id;
    console.log(user_id);
    fetch(`${window.server}/users?id=${user_id}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({ ...response.data[0] });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };

  verify(data) {
    let result = true;
    let missing = [];
    Object.keys(data).map((d) => {
      if (!data[d] || data[d] === "") {
        missing.push(d.replace("_id", "").replace(/_/g, " "));
        result = false;
      }
    });
    missing.join(", ");
    if (!result) alert("Please fill all the require fields : " + missing);
    return result;
  }

  updateUser = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to update this employee's data?"
      )
    )
      return false;
    let postData = data;

    postData.msisdn = window.verifyNumber(postData.msisdn);

    this.setState({ modalVisible: true });
    fetch(`${window.server}/users/${this.props.match.params.id}`, {
      method: "PATCH",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
        } else {
          console.log(response);
          alert("Employee details have been updates successfully");
          this.setState({ uploadSuccessful: true });
          this.setState({ editModal: false });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ editModal: false });
      });
  };

  sendSms = (data) => {
    fetch(`${window.server}/utils/admin/send-sms`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: this.props.match.params.id,
        msg_text: data.msg_text,
        msisdn: data.msisdn,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
        } else {
          console.log(response);
          alert(response.result);
          // this.setState({ uploadSuccessful: true });
          this.setState({ smsModal: false });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ smsModal: false });
      });
  };

  deleteUser = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to Remove  this employee's account?"
      )
    )
      return false;

    fetch(`${window.server}/users/${this.props.match.params.id}`, {
      method: "DELETE",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: this.props.match.params.id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
        } else {
          console.log(response);
          alert(response.result);
          this.setState({ uploadSuccessful: true });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
      });
  };

  ResetPassword = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to Reset  this employee's Password?"
      )
    )
      return false;

    fetch(`${window.server}/utils/admin/reset-password`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: this.props.match.params.id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
        } else {
          console.log(response);
          alert(response.result);
          this.setState({ uploadSuccessful: true });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
      });
  };
}

export default EmployeesView;
