/** @format */

import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";
import Form from "../../components/form";
import { isToday } from "date-fns/esm";
import { toDate } from "date-fns";

// import Modal from "react-modal-view";

class ExitMembers extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <Nav name="Exit Members"></Nav>
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              search={["firstname", "middlename", "surname"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
          {/* <div>
            <Modal visible={true}>
              <h2>Hello from inside your modal</h2>
              <p>The data to be displayed</p>
            </Modal>
          </div> */}

          {/* {this.state.html && (
            <Modal
              visible={true}
              close={() => {
                this.setState({ html: null });
              }}
            >
              <Modal
                displayDataTypes={false}
                displayObjectSize={false}
                src={this.state.html}
              />
            </Modal>
          )} */}
          {/* 
          {this.state.json && (
            <Modal
              visible={true}
              close={() => {
                this.setState({ json: null });
              }}
            >
              <ReactJson
                displayDataTypes={false}
                displayObjectSize={false}
                src={this.state.json}
              />
            </Modal>
          )} */}
        </div>

        <Modal
          visible={this.state.detailsModal}
          close={() => this.setState({ detailsModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <h5 className="m-0 mt-1">More info</h5>
          </div>
          {typeof this.state.activeDetails !== "undefined" && (
            <div className="mt-3">
              <Form
                key={this.state.activeDetails}
                submitText={"Close"}
                back={false}
                inputs={[
                  {
                    label: "id",
                    name: "id",
                    type: "text",
                    readonly: "readonly",
                    value: this.state.activeDetails.id,
                  },
                  {
                    label: "exit reason",
                    name: "exit_reason",
                    type: "text",
                    readonly: "readonly",
                    value: this.state.activeDetails.exit_reason,
                  },
                  // {
                  //   label: "amount refunded",
                  //   name: "amount_refunded",
                  //   type: "text",
                  //   readonly: "readonly",
                  //   value: this.state.activeDetails.amount_refunded,
                  // },
                ]}
                submit={(data) => {
                  this.setState({ detailsModal: false });
                  console.log(data);
                }}
              />
            </div>
          )}
        </Modal>
      </div>
    );
  }

  fetchExitMembers = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/customer-exits?${urlParams}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        // alert(JSON.stringify(response));

        let data = [];

        response.data.map((d, i) => {
          let time = moment().diff(
            moment(d.request_date).add(61, "days"),
            "days"
          );
          let updatedTime = "";
          //Checks for the days remaining to refund date
          if (time >= 0) {
            updatedTime = "Time elapsed";
          } else {
            updatedTime = time * -1;
          }

          // console.log(updatedTime);

          data.push({
            // id: d.id,
            phone_number: d.customer_msisdn.msisdn,
            full_name: `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`,
            request_date: moment(d.request_date).format("Do MMMM YYYY"),
            days_remaining: updatedTime,
            // moment().diff(moment(d.request_date).add(61, "days"), "days") *
            // -1, //moment(d.request_date).add(60, "days").fromNow(),
            refund_date: moment(d.request_date)
              .add(60, "days")
              .format("Do MMMM YYYY"),
            amount_to_refund: d.amount_to_refund,
            initiated_by: d.initiated_by,
            // amount_refunded:
            //   d.amount_refunded === null ? "--" : d.amount_refunded,
            // exit_reason: d.exit_reason,
            action: d.id && (
              <button
                className="btn btn-sm btn-primary font-weight-bold px-3 btn-round"
                onClick={() => {
                  this.setState({ detailsModal: true, activeDetails: d });
                  // console.log("gjgjghujg", d);
                }}
              >
                View
              </button>
            ),
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchExitMembers();
      }, 100);
    }
  }
}

export default ExitMembers;
