/** @format */

import React, { Component } from "react";
import Nav from "../../components/Nav";
import { Route } from "react-router-dom";
import Form from "../../components/form";
import { X, Printer, XCircle, CheckCircle } from "react-feather";
import logo from "../../img/digital2.png";
import moment from "moment";

class memberAdd extends Component {
  state = {
    details: {
      passport: null,
      firstname: "gabriel",
      middlename: "kamau",
      surname: "muhangia",
      msisdn: "0717638548",
      date_of_birth: "2020-01-08",
      // gender: "1",
      marital_status: "1",
      identity_type: "1",
      id_number: "2324234",
      kra_pin: "KSDNF32324234",
      // sub_county_id: "1",
      // ward_id: "2",
      village: "KIJABE",
      address: "17 kingstion road",
      nationality: "1",
      pledge: "3000",
    },
    kins: [
      {
        type: "parent",
        firstname: "samuel",
        othername: "kariega",
        id_number: "22323423",
        msisdn: "0717342422",
        relationship: "father",
      },
    ],
    acceptAgreement: false,
  };
  render() {
    return (
      <div className='page border-left'>
        <Nav name='Add a Member'></Nav>
        <div className='mt-3 table-card  border-0 card shado mx-3 shadow mb-5'>
          <div className='p-md-4 p-3'>
            <Route
              path='/member-add/1'
              exact
              render={(props) => (
                <div className='mt-2 '>
                  <h4>1. Member Details</h4>

                  <Form
                    submitText={"Next"}
                    back={false}
                    inputs={[
                      {
                        label: "Passport photo",
                        name: "passport",
                        value: this.state.details.passport,
                        type: "passport",
                      },
                      {
                        label: "First name",
                        name: "firstname",
                        value: this.state.details.firstname,
                      },
                      {
                        label: "Middle name",
                        name: "middlename",
                        value: this.state.details.middlename,
                      },
                      {
                        label: "Surname",
                        name: "surname",
                        value: this.state.details.surname,
                      },
                      {
                        label: "Phone number",
                        name: "msisdn",
                        value: this.state.details.msisdn,
                        type: "tel",
                      },
                      {
                        label: "Date Of Birth",
                        name: "date_of_birth",
                        value: this.state.details.date_of_birth,
                        type: "date",
                      },
                      // {
                      //   label: "Gender",
                      //   name: "gender",
                      //   value: this.state.details.gender,
                      //   type: "select",
                      //   options: [
                      //     { name: "Male", value: 1 },
                      //     { name: "Female", value: "2" }
                      //   ]
                      // },
                      {
                        label: "Marital Status",
                        name: "marital_status",
                        value: this.state.details.marital_status,
                        type: "select",
                        options: [
                          { name: "Single", value: 1 },
                          { name: "Married", value: "2" },
                        ],
                      },

                      {
                        label: "Address",
                        name: "address",
                        value: this.state.details.address,
                      },
                      {
                        label: "nationality",
                        name: "nationality",
                        value: this.state.details.nationality,
                        type: "select",
                        options: [
                          { name: "Kenyan", value: 1 },
                          { name: "Nigerian", value: 2 },
                        ],
                      },
                      {
                        label: "Pledge (Kshs)",
                        name: "pledge",
                        value: this.state.details.pledge,
                        type: "number",
                      },
                      { type: "break" },
                      {
                        label: "Identification Type",
                        name: "identity_type",
                        value: this.state.details.identity_type,
                        type: "select",
                        options: [
                          { name: "Identity Card", value: 1 },
                          { name: "Passport Number", value: 2 },
                        ],
                      },
                      {
                        label: "ID number",
                        name: "id_number",
                        type: "number",
                        value: this.state.details.id_number,
                      },
                      {
                        label: "KRA pin",
                        name: "kra_pin",
                        value: this.state.details.kra_pin,
                      },
                      {
                        label: "Village",
                        name: "village",
                        value: this.state.details.village,
                      },
                    ]}
                    submit={(data) => {
                      this.setState({ details: data });
                      console.log(data);
                      props.history.push("/member-add/2");
                    }}
                  />
                </div>
              )}
            />

            <Route
              path='/member-add/2'
              exact
              render={(props) => (
                <div className='mt-2 '>
                  <h4>2. Member Documents</h4>

                  <Form
                    submitText={"Next"}
                    back={true}
                    inputs={[
                      {
                        label: "",
                        name: "idFront",
                        value: this.state.details.idFront,
                        type: "id",
                      },
                      {
                        label: "",
                        name: "idBack",
                        value: this.state.details.idBack,
                        type: "hidden",
                      },
                    ]}
                    submit={(data) => {
                      this.setState({
                        details: { ...this.state.details, ...data },
                      });
                      props.history.push("/member-add/3");
                    }}
                  />
                </div>
              )}
            />

            <Route
              path='/member-add/3'
              exact
              render={(props) => (
                <div className='mt-2 '>
                  <h4>3. Next Of Kin</h4>
                  <hr className='mb-4' />
                  <div className='container'>
                    {this.state.kins.map((d, i) => (
                      <div
                        key={i}
                        className='p-3 kin-container mb-4 position-relative'
                      >
                        <div className='position-absolute icon-hover modal-close-icon'>
                          <X
                            className='icon modal-close-icon m-2 mr-4'
                            onClick={() => {
                              let { kins } = this.state;
                              kins.splice(i, 1);
                              this.setState({ kins });
                            }}
                          />
                        </div>
                        <h5 className='ml-3 mb-3 font-weight-bold text-capitalize'>
                          {d.type}
                        </h5>
                        {Object.keys(d).map(
                          (d1, i1) =>
                            d1 !== "type" && (
                              <div
                                className='mx-3 d-inline-block mb-3'
                                key={i1}
                              >
                                <span className='ml-1 mb-2 d-block text-capitalize'>
                                  {d1.replace(/_/g, " ")}
                                </span>
                                <input
                                  value={this.state.kins[i][d1]}
                                  placeholder='...'
                                  className='form-control text-input bg-light'
                                  onChange={(e) => {
                                    let { kins } = this.state;
                                    kins[i][d1] = e.target.value;
                                    this.setState({ kins });
                                  }}
                                  type={d1 === "birth_date" ? "date" : "text"}
                                />
                              </div>
                            )
                        )}
                      </div>
                    ))}

                    <div className='d-flex flex-row justify-content-center'>
                      <select
                        name=''
                        value=''
                        className='btn btn-lg btn-round btn-primary mt-5 text-center'
                        onChange={(e) => {
                          let { kins } = this.state;
                          kins.push({
                            type: e.target.value,
                            firstname: "",
                            othername: "",
                            id_number: "",
                            msisdn: "",
                            relationship: "",
                          });
                          // //console.log(e.target.value);
                          this.setState({ kins });
                        }}
                      >
                        <option value='0' selected hidden>
                          Add a kin
                        </option>
                        <option value='spouse'>Spouse</option>
                        <option value='child'>Child</option>
                        <option value='parent'>Parent</option>
                        <option value='sibling'>Sibling</option>
                        <option value='extended_family'>Extended family</option>
                      </select>
                    </div>

                    <div className=' my-3 d-flex flex-row justify-content-between mt-5'>
                      <button
                        className='btn btn-outline-primary px-5  btn-round'
                        onClick={() => window.history.back()}
                      >
                        Back
                      </button>

                      <button
                        className='btn btn-primary px-5  btn-round'
                        disabled={this.state.kins.length < 1}
                        onClick={() => {
                          props.history.push("/member-add/4");
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>

                  <div></div>
                </div>
              )}
            />

            <Route
              path={`/member-add/4`}
              exact
              render={(props) => (
                <>
                  <div className='d-flex flex-row align-items-center justify-content-between mb-5 verification-title'>
                    <h4>4. Verification</h4>

                    <button
                      className='btn btn-primary btn-round pr-3'
                      onClick={window.print}
                    >
                      <Printer size={18}></Printer> Print Document
                    </button>
                  </div>
                  <div className='d-flex flex-row justify-content-'>
                    <div className='virtual-form w-100' id='virtual-form'>
                      <div className='text-center'>
                        <img src={logo} alt='' className='logo' />
                        <h4 className='font-weight-bold my-3'>
                          DIGITAL SACCO MANAGEMENT SYSTEM
                        </h4>
                        <h5 className='font-weight-bold'>
                          MEMBER REGISTRATION FORM
                        </h5>
                      </div>

                      <div className='mt-5'>
                        <div className='document-passport'>
                          <img
                            src={this.state.details.passport}
                            className='w-109 h-100'
                            alt=''
                          />
                        </div>

                        <div className='mb-5'>
                          <div className='page-title'>APPLICANT'S DETAILS</div>
                          <div className=''>
                            <div className='page-card p-3'>
                              <div className='row'>{this.generateInputs()}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='mb-5'>
                        <div className='page-title'>NEXT OF KIN(S)</div>
                        <div className=''>
                          <div className='page-card'>
                            <table className='table table-striped'>
                              <thead>
                                {Object.keys(this.state.kins[0]).map((d) => (
                                  <th className='text-capitalize'>
                                    {d.replace(/_/g, " ")}
                                  </th>
                                ))}
                              </thead>
                              <tbody>
                                {this.state.kins.map((d) => (
                                  <tr>
                                    {Object.keys(this.state.kins[0]).map(
                                      (d1) => (
                                        <td className='text-uppercas'>
                                          {d[d1]}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='mb-2 font-weight-bold'>ID Front</div>
                          <div className='id-photo'>
                            <img
                              src={this.state.details.idFront}
                              className='w-100 h-100'
                              alt=''
                            />
                          </div>
                        </div>

                        <div className='col-md-6'>
                          <div className='mb-2 font-weight-bold'>ID Back</div>
                          <div className='id-photo'>
                            <img
                              src={this.state.details.idBack}
                              className='w-100 h-100'
                              alt=''
                            />
                          </div>
                        </div>
                      </div>

                      <hr className='print-hide my-5' />

                      <div className='text-center print-hide'>
                        <div className='d-flex flex-row align-items-center'>
                          <label>
                            <input
                              type='checkbox'
                              className='mr-3 styled-checkbox'
                              id='342'
                              name=''
                              checked={this.state.acceptAgreement}
                              onChange={() => {
                                this.setState({
                                  acceptAgreement: !this.state.acceptAgreement,
                                });
                              }}
                            />
                            <div></div>
                          </label>
                          <div className='text-left'>
                            I declare that the information given herein is true
                            to the best of my knowledge and belief: I further
                            authorise the SACCO to verify the information given
                            herein and make reference from any
                            person(s)/institution(s) named herein.
                          </div>
                        </div>

                        <button
                          className='btn btn-primary btn-round px-5 mt-4 print-hide'
                          onClick={this.addClient}
                          disabled={!this.state.acceptAgreement}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            />
          </div>
        </div>
      </div>
    );
  }

  generateInputs = () => {
    let data = {
      full_name: `${this.state.details.firstname} ${this.state.details.middlename} ${this.state.details.surname}`,
      date_of_birth: moment(this.state.details.date_of_birth).format(
        "Do MMMM YYYY"
      ),
      "Phone Number": this.state.details.msisdn,
      KRA_pin: this.state.details.kra_pin,
      // gender: ["Male", "Female"][this.state.details.gender],
      // sub_county: window.wardsList[parseInt(this.state.details.sub_county_id)],
      // ward: window.wardsList[parseInt(this.state.details.ward_id)],
      village: this.state.details.village,
      ID_number: this.state.details.id_number,
      address: this.state.details.address,
      nationality: this.state.details.nationality,
      pledge: "KES " + parseInt(this.state.details.pledge).toLocaleString(),
    };

    console.log(data);

    return Object.keys(data).map((d) => (
      <div className='col-md-3 pb-3'>
        <div className='page-label text-capitalize'>{d.replace(/_/g, " ")}</div>
        <div>{data[d] || "-"}</div>
      </div>
    ));
  };

  verify(data) {
    let result = true;
    let missing = [];
    Object.keys(data).map((d) => {
      if (data[d] === "") {
        missing.push(d.replace("_id", "").replace(/_/g, " "));
        result = false;
      }
    });
    missing.join(", ");
    if (!result) alert("Please fill all the require fields : " + missing);
    return result;
    // return true;
  }

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  addClient = () => {
    let formData = new FormData();
    let { details } = this.state;
    details.msisdn = window.verifyNumber(details.msisdn);
    let postData = {
      ...this.state.details,
      ...this.state.documents,
      ...this.state.business,
      kins: this.state.kins,
      ...this.state.other,
    };

    Object.keys(postData).map((d) => {
      if (d === "kins") return;
      let data = postData[d];
      try {
        if (data.substring(0, 11) === "data:image/") {
          formData.append(d, this.dataURLtoFile(data), "file.png");
        } else {
          formData.append(d, data);
        }
      } catch (error) {
        console.error(error);
        formData.append(d, data);
      }
    });

    //console.log(postData);

    postData.kins.map((d, i) => {
      Object.keys(d).map((d1) => {
        formData.append(`kins[${i}][${d1}]`, d[d1]);
      });
    });

    window.alert2.show({
      loader: true,
      title: "Adding User",
      buttons: true,
    });

    fetch(`${window.server}/customer/registration`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.code) {
          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: "User Created Successfully",
            buttons: true,
            onSubmit: () => {
              this.props.history.push(`/members`);
            },
          });

          this.setState({ modalVisible: false });
        } else {
          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: "User Created Successfully",
            buttons: true,
            onSubmit: () => {
              // this.props.history.push(
              //   `/member-view/details/${response.user_id}`
              // );

              this.props.history.push(`/members`);
            },
          });
        }
      })
      .catch((d) => {
        //console.log("Error saving the data");
        //console.log(d);
        this.setState({ modalVisible: false });
      });
  };
}

export default memberAdd;
