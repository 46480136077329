import React, { Component } from "react";
import Nav from "../../components/Nav";
import { Users } from "react-feather";
import moment from "moment";
import Form from "../../components/form";
import Modal from "../../components/modal";
class Marketing extends Component {
  state = {
    marketing: [],
  };
  render() {
    return (
      <div>
        <Nav
          name="Marketing"
          buttons={[
            {
              text: "Add Marketing item",
              onClick: () => {
                this.setState({ addModal: true });
              },
              access: "all",
            },
          ]}
        ></Nav>
        <div className="mt-3 table-card sms-table-card  border-0 card shadow mx-3 shadow p-4 ">
          <div className="">
            {this.state.marketing.map((d, i) => (
              <div
                className=" address-card my-3 d-flex flex-row align-items-center w-100"
                key={i}
                onClick={() => {
                  this.setState({ addressModal: false, activeMarketing: i });
                }}
              >
                <div className="ico ml-3">
                  <Users size={30}></Users>
                </div>

                <div className="content p-3">
                  <h5 className="font-weight-bold">Name: {d.name}</h5>
                  <div>
                    <p>Description: {d.description}</p>
                  </div>
                  {d.marketing_text && <p>{d.marketing_text}</p>}
                  <p>Type: {d.marketing_type == 0 ? "Text" : "Banner"}</p>
                  <img src={d.url} alt="image" width={200} height={200}></img>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Modal
          visible={this.state.addModal}
          close={() => this.setState({ addModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Users className="mr-3"></Users>
            <h5 className="m-0 mt-1">Add marketing item</h5>
          </div>
          <div className="mt-3">
            <Form
              inputs={[
                {
                  label: "Name",
                  name: "name",
                  type: "text",
                  value: "",
                },
                {
                  label: "Description",
                  name: "description",
                  type: "textarea",
                  value: "",
                },
                {
                  label: "marketing_text",
                  name: "marketing_text",
                  required: false,
                  type: "textarea",
                  value: "",
                },
                {
                  label: "marketing_type",
                  name: "marketing_type",
                  type: "select",
                  value: 1,
                  options: [
                    { name: "Text", value: 1 },
                    { name: "Banners", value: 2 },
                  ],
                },
                {
                  label: "url",
                  name: "url",
                  required: false,
                  type: "text",
                  value: "",
                },
              ]}
              submit={(data) => {
                this.createMarketing(data);
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }
  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      id: this.props.match.params.id,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/utils/admin/marketing`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("address", response);
        var data = [];
        response.data.map((d, i) => {
          data.push({
            name: d.name,
            description: d.description,
            marketing_type: d.marketing_type,
            url: d.url,
          });
        });
        console.log("data", data);
        this.setState({ marketing: data });
      })
      .catch((d) => {
        console.error(d);
      });
  };
  createMarketing = (data) => {
    console.log("creating Marketing ...");

    if (
      !window.confirm("Are you sure that you want to create this Marketing?")
    ) {
      return;
    }
    data.status = 0;
    fetch(`${window.server}/utils/admin/marketing`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ addModal: false });
        this.fetch();
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ modalVisible: false });
      });
  };

  editMarketing = (data) => {
    console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    let values = Object.values(obj);
    let id = obj.id;
    let postData = data;
    //alert(id);

    fetch(`${window.server}/utils/admin/marketing/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        alert("Marketing Updated successfully");
        //this.verifyComplete();
        this.fetch();
        this.setState({ editModal: false });
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ editModal: false });
      });
  };

  deleteMarketing = (data) => {
    console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    let values = Object.values(obj);
    //alert(values);

    fetch(`${window.server}/utils/admin/marketing/${values}`, {
      method: "DELETE",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        alert("Marketing removed successfully");
        //this.verifyComplete();
        this.fetch();
        this.setState({ deleteModal: false });
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ deleteModal: false });
      });
  };
}

export default Marketing;
