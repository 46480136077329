/** @format */

import React, { Component } from "react";
import moment from "moment";
import Form from "../../components/form";
import { DollarSign, User, LogOut } from "react-feather";

class Details extends Component {
  randomize = (start, end) => {
    return Math.floor(Math.random() * end) + start;
  };

  state = {
    data: {},
    balanceInfo: {},
    salaryVisible: false,
    exitInfo: {},
  };
  render() {
    return (
      <div className='p-3'>
        <div className='row'>
          <div className='col-md-12'>
            <ul className='list-group user-details table-card shadow border-0 w-100'>
              <div className='p-3 bg-light font-weight-bold'>
                <User size={18}></User> Employee Details
              </div>
              {Object.keys(this.state.data).map((d) => (
                <li className='list-group-item d-flex flex-row  text-dark'>
                  <div className='user-detail-title font-weight-bold text-capitalize'>
                    {d.replace(/_/g, " ")}
                  </div>
                  <div>{this.state.data[d]}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    let user_id = this.props.match.params.id;
    fetch(`${window.server}/users?id=${user_id}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log(response);
        let data = [];
        response.data.map((d) => {
          data.push({
            user_id: d.user_id,
            full_name: `${d.firstname} ${d.middlename} ${d.surname}`,
            date_of_birth: moment(d.date_of_birth).format("Do MMMM YYYY"),
            "Phone Number": d.msisdn,
            KRA_pin: d.kra_pin,
            // gender: { 1: "Male", 2: "Female" }[d.gender],
            // marital_status: {1:"Single", 2:"Married"}[d.marital_status],
            ID_number: d.id_number,
            access_role: d.roles.name,
          });
        });
        response.data = data;
        this.setState({ data: data[0] });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };
}

export default Details;
