import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class PendingLoanRequests extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              //search={["firstname", "middlename", "surname", "msisdn"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchPendingLoanRequests = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      customer_id: this.props.match.params.customer_id
      // status: 0
    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/utils/loan-requests?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);

        let data = [];
        response.data.map((d, i) => {
          data.push({
            // load_id: d.id,
            loan_request_id : d.id,
            //customer_name: `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`,
            loan_type: d.product_name,
            // loan_type: d.product_name,
            amount: parseFloat(
                parseFloat(d.amount)
              ).toLocaleString(),
            // interest_charged: parseFloat(
            //     parseFloat((d.request.amount * 0.05))
            //   ).toLocaleString(),
            // processing_fee: parseFloat(
            //     parseFloat((d.request.amount * 0.025))
            //   ).toLocaleString() ,
            repayment_duration: d.repayment_duration + " months",
            // status: d.status,
            status: parseFloat(d.status) === 1 ? "Approved" : "Pending",
            guarantor_type: ["Self Guarantor", "Guaranteed"][
              d.guarantor_status
            ],
            guaranteed_amount: parseFloat(
                parseFloat(d.guaranteed_amount)
              ).toLocaleString(),
            // loan_products: d["loan-products"],
            loan_date: moment.utc(d.date_created).format('DD MMMM, YYYY.  HH:mm:ss'),
            //repayment_date: moment(d.date_created).add(d.repayment_duration, 'months').format('Do MMMM YYYY')

            action: (
              <div className="d-flex flex-row">
                <Link
                  to={"/guarantor-view/details/" + d.id}
                  className="btn btn-sm btn-primary px-3 btn-round"
                >
                  View
                </Link>
              </div>
            )
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function() {
        $t.fetchPendingLoanRequests();
      }, 100);
    }
  }
}

export default PendingLoanRequests;
