import React, { Component } from "react";
import Nav from "../../components/Nav";
import { Users } from "react-feather";
import moment from "moment";
import Form from "../../components/form";
import Modal from "../../components/modal";
class AddressBook extends Component {
  state = {
    addressBooks: []
  };
  render() {
    return (
      <div>
        <Nav
          name="Address Books"
          buttons={[{ text: "Add Address Book",
         onClick: () => {
           this.setState({ addModal: true });
         },
         access: "all" 
       }]}
        ></Nav>
        <div className="mt-3 table-card sms-table-card  border-0 card shado mx-3 shadow p-4 ">
          <div className="">
            {this.state.addressBooks.map((d, i) => (
              <div
                className=" address-card my-3 d-flex flex-row align-items-center w-100"
                key={i}
                onClick={() => {
                  this.setState({ addressModal: false, activeAddressBook: i });
                }}
              >
                <div className="ico ml-3">
                  <Users size={30}></Users>
                </div>
                <div className="content p-3">
                  <h5 className="font-weight-bold">{d.name}</h5>
                  <div>
                    <p>{d.description}</p>
                  </div>
                  <div className="text-muted">
                    created {moment().format("Do MMMM YYYY, h:mm a")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Modal
          visible={this.state.addModal}
          close={() => this.setState({ addModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Users className="mr-3"></Users>
            <h5 className="m-0 mt-1">Create an address Book</h5>
          </div>
          <div className="mt-3">
            <Form
              inputs={[
                {
                  label: "Name",
                  name: "name",
                  type: "text",
                  value: ""
                },
                {
                  label: "Description",
                  name: "description",
                  type: "textarea",
                  value: ""
                }
              ]}
              submit={data => {
                this.createAddressBook(data);
              }}
            />
          </div>
        </Modal>

      </div>
    );
  }
  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      id: this.props.match.params.id
    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/utils/admin/message-address-book`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
      console.log("address", response);
      var data = []
      response.data.map((d, i) => {
        data.push({
          name:d.name, 
          description:d.description,

        })
      })
      console.log("data", data)
        this.setState({ addressBooks:data });
      })
      .catch(d => {
        console.error(d);
      });
  };
  createAddressBook = data => {
    console.log("creating AddressBook ...");

    if (!window.confirm("Are you sure that you want to create this AddressBook?")) {
      return;
    }

    fetch(`${window.server}/utils/admin/message-address-book`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(response => {
        this.setState({ addModal: false });
        this.fetch();
      })
      .catch(d => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ modalVisible: false });
      });
  };

  editAddressBook = data => {
       console.log(data);
      let sdata = JSON.stringify(data);
      let obj = JSON.parse(sdata);
      let values = Object.values(obj);
      let id = obj.id;
      let postData = data;
      //alert(id);

      fetch(`${window.server}/utils/admin/message-address-book/${id}`, {
          method: "PATCH",
          headers: {
            Authorization: localStorage.token,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(postData)
        })
          .then(response => response.json())
          .then(response => {
            console.log(response);
            alert(
                "AddressBook Updated successfully"
              );
            //this.verifyComplete();
            this.fetch();
            this.setState({ editModal: false });
          })
          .catch(d => {
            console.log("Error saving the data");
            console.log(d);
            this.setState({ editModal: false });
        });

  };

  deleteAddressBook = data =>{

      console.log(data);
      let sdata = JSON.stringify(data);
      let obj = JSON.parse(sdata);
      let values = Object.values(obj);
      //alert(values);

      fetch(`${window.server}/utils/admin/message-address-book/${values}`, {
          method: "DELETE",
          headers: {
            Authorization: localStorage.token,
            "Content-Type": "application/json"
          }
        })
          .then(response => response.json())
          .then(response => {
            console.log(response);
            alert(
                "AddressBook removed successfully"
              );
            //this.verifyComplete();
            this.fetch();
            this.setState({ deleteModal: false });
          })
          .catch(d => {
            console.log("Error saving the data");
            console.log(d);
            this.setState({ deleteModal: false });
        });

  };
}

export default AddressBook;
