import React, { Component } from "react";
import Table from "../components/Table";
import Filter from "../components/filter";
import {
  Plus,
  Target,
  Circle,
  Smartphone,
  Users,
  Edit2,
  CheckCircle,
  UserPlus,
  UserCheck
} from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../components/Nav";
import Form from "../components/form";

class Groups extends Component{
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    editModal: false,
    deleteModal: false,
    groupRoles: [],
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Groups"
          buttons={[{ text: "Add a group",
              onClick: () => {
                this.setState({ addModal: true });
              },
              access: "all" 
            }]}
        ></Nav>
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              search={["name"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
        <Modal
          visible={this.state.addModal}
          close={() => this.setState({ addModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Users className="mr-3"></Users>
            <h5 className="m-0 mt-1">Create a Group</h5>
          </div>
          <div className="mt-3">
            <Form
              inputs={[
                {
                  label: "Group Name",
                  name: "name",
                  type: "text",
                  value: ""
                }
              ]}
              submit={data => {
                this.createGroup(data);
              }}
            />
          </div>
        </Modal>

        <Modal 
         visible={this.state.editModal}
         close={() => this.setState({ editModal: false })}
        >
         <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Edit Group</h5>
          </div>
          {typeof this.state.activeDetails !== 'undefined' && (
           <div className="mt-3">
             <Form
              submitText={"Edit Group"}
              back={false}
              inputs={[
                {
                  label: "Id",
                  name: "id",
                  type: "text",
                  readonly: "readonly",
                  value:  this.state.response.data[this.state.activeDetails]
                    .id
                },
                {
                  label: "Group Name",
                  name: "name",
                  type: "text",
                  value:  this.state.response.data[this.state.activeDetails].name
                }

              ]}
              submit={data => {
                //console.log(data);
                //this.setState({ details: data });
                setTimeout(() => {
                    this.editGroup(data);
                  }, 0);
              }}
            />
        
          </div>
         )}
        </Modal>

        <Modal 
         visible={this.state.deleteModal}
         close={() => this.setState({ deleteModal: false })}
        >
         <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Delete Group</h5>
          </div>
          {typeof this.state.activeDetails !== 'undefined' && (
           <div className="mt-3">
             <Form
              submitText={"Delete Group"}
              back={false}
              inputs={[
                {
                  label: "Are you sure that you want to delete this group?",
                  name: "id",
                  type: "text",
                  readonly: "readonly",
                  value:  this.state.response.data[this.state.activeDetails]
                    .id
                }

              ]}
              submit={data => {
                //console.log(data);
                //this.setState({ details: data });
                setTimeout(() => {
                    this.deleteGroup(data);
                  }, 0);
              }}
            />
        
          </div>
         )}
        </Modal>
         
      </div>
    );
  }
   fetchGroups = () =>{
      this.setState({ table_loading: true });

      let q = {
        // ...this.state.filter,
        ...this.state.query
      };

       let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/admin-groups?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
     .then(response => response.json())
     .then(response => {
        console.log(response);
        let data = [];
        response.data.map((d, i) => {
          data.push({
            id: d.id,
            name: d.name,
            action: (
              <div className="d-flex flex-row">
                <button 
                  onClick={() => {
                    this.setState({ editModal: true ,activeDetails: i});
                    //console.log(this.state.response.data[0]);
                  }}
                  className="btn btn-sm btn-info px-3 btn-round"
                >
                  Edit
                </button>

                <button 
                  onClick={() => {
                    this.setState({ deleteModal: true ,activeDetails: i});
                    //console.log(this.state.response.data[0]);
                  }}
                  className="btn btn-sm btn-danger px-3 btn-round"
                >
                  Delete
                </button>

                <Link
                      to={"/manage-view/" + d.id}
                      className="btn btn-sm btn-success px-3 btn-round"
                    >
                      Manage Roles
                </Link>
              </div>
            )
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });

   };
   

   componentDidMount() {
    this.fetchGroups();
  }
   
  createGroup = data => {
    console.log("creating group ...");

    if (!window.confirm("Are you sure that you want to create this group?")) {
      return;
    }

    fetch(`${window.server}/admin-groups`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        alert("added");
        this.setState({ addModal: false });
        this.fetchGroups();
      })
      .catch(d => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ modalVisible: false });
      });
  };

  editGroup = data => {
       console.log(data);
      let sdata = JSON.stringify(data);
      let obj = JSON.parse(sdata);
      let values = Object.values(obj);
      let id = obj.id;
      let postData = data;
      //alert(id);

      fetch(`${window.server}/admin-groups/${id}`, {
          method: "PATCH",
          headers: {
            Authorization: localStorage.token,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(postData)
        })
          .then(response => response.json())
          .then(response => {
            console.log(response);
            alert(
                "Group Updated successfully"
              );
            //this.verifyComplete();
            this.fetchGroups();
            this.setState({ editModal: false });
          })
          .catch(d => {
            console.log("Error saving the data");
            console.log(d);
            this.setState({ editModal: false });
        });

  };

  deleteGroup = data =>{

      console.log(data);
      let sdata = JSON.stringify(data);
      let obj = JSON.parse(sdata);
      let values = Object.values(obj);
      //alert(values);

      fetch(`${window.server}/admin-groups/${values}`, {
          method: "DELETE",
          headers: {
            Authorization: localStorage.token,
            "Content-Type": "application/json"
          }
        })
          .then(response => response.json())
          .then(response => {
            console.log(response);
            alert(
                "Group removed successfully"
              );
            //this.verifyComplete();
            this.fetchGroups();
            this.setState({ deleteModal: false });
          })
          .catch(d => {
            console.log("Error saving the data");
            console.log(d);
            this.setState({ deleteModal: false });
        });

  };

   componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function() {
        $t.fetchGroups();
      }, 100);
    }
  }
}

export default Groups;
