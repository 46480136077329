import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class MemberLinkedAccount extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              //search={["firstname", "middlename", "surname", "msisdn"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchMemberLinkedAccount = () => {
    this.setState({ table_loading: true });
    console.log("props",this.props.match.params.customer_id)
    let user_id = this.props.match.params.customer_id;
    fetch(`${window.server}/entity-accounts?customer_id=${user_id}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log("id" , response.data[0].details.id_number);
        let q = {
          // ...this.state.filter,
          ...this.state.query,
          //customer_id: this.props.match.params.customer_id
          national_id : response.data[0].details.id_number
          // status: 0
        };
    
        let urlParams = Object.entries(q)
          .map(e => e.join("="))
          .join("&");
    
        console.log(urlParams);
        fetch(`${window.server}/utils/link-account?${urlParams}`, {
          headers: {
            Authorization: localStorage.token
          }
        })
          .then(response => response.json())
          .then(response => {
            console.log(response);
    
            let data = [];
            response.data.map((d, i) => {
              console.log("d",d)
              data.push({
                id: d.id,
                existing_msisdn: d.existing_msisdn,
                new_msisdn: d.new_msisdn,
                status: parseFloat(d.status) === 1 ? "Approved" : "Pending",
               
              });
            });
    
            let dts = {};
            dts.data = data;
    
            this.setState({
              tableData: { ...response, ...dts },
              response,
              table_loading: false
            });
          })
          .catch(d => {
            this.setState({ table_loading: false });
            console.error(d);
          });
      })
      .catch(d => {
        this.setState({ tableError: true });
        //console.log(d);
      });
    
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function() {
        $t.fetchMemberLinkedAccount();
      }, 100);
    }
  }
}

export default MemberLinkedAccount;
