/** @format */

import React, { Component } from "react";
import moment from "moment";
import Form from "../../components/form";
import { DollarSign, User, Image, LogOut } from "react-feather";
class Details extends Component {
  randomize = (start, end) => {
    return Math.floor(Math.random() * end) + start;
  };

  state = {
    data: {},
    balanceInfo: {},
    salaryVisible: false,
    exitInfo: {},
    customerPhotos: {},
  };
  render() {
    return (
      <div className="p-3">
        <div className="row">
          <div className="col-md-6">
            <ul className="list-group user-details table-card shadow border-0 w-100">
              <div className="p-3 bg-light font-weight-bold">
                <User size={18}></User> Member Details
              </div>
              {Object.keys(this.state.data).map((d) => (
                <li className="list-group-item d-flex flex-row  text-dark">
                  <div className="user-detail-title font-weight-bold text-capitalize">
                    {d.replace(/_/g, " ")}
                  </div>
                  <div>{this.state.data[d]}</div>
                </li>
              ))}
            </ul>
          </div>
          {this.state.balanceInfo === "--" ? (
            ""
          ) : (
            <div className="col-md-6">
              <ul className="list-group user-details table-card shadow border-0 w-100">
                <div className="p-3 bg-light font-weight-bold">
                  <DollarSign size={18}></DollarSign> Account Details
                </div>
                {Object.keys(this.state.balanceInfo).map((d) => {
                  if (d === "break" || d === "brea")
                    return <div className="p-1 bg-light"></div>;

                  return (
                    <li className="list-group-item d-flex flex-row text-dark">
                      <div className="user-detail-title font-weight-bold text-capitalize">
                        {d.replace(/_/g, " ")}
                      </div>
                      <div>{this.state.balanceInfo[d]}</div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <br />
        <div className="row">
          {this.state.customerPhotos[0] === undefined &&
          this.state.customerPhotos[0] === undefined ? (
            <></>
          ) : (
            <>
              <div className="col-md-6">
                <ul className="list-group user-details table-card shadow border-0 w-100">
                  {this.state.customerPhotos[0].id_front_path === undefined ? (
                    <h4>Loading...</h4>
                  ) : (
                    <>
                      <div className="p-3 bg-light font-weight-bold">
                        <Image size={18}></Image> ID Front Image
                      </div>

                      <img
                        src={`${window.server}${
                          this.state.data &&
                          (this.state.customerPhotos[0].id_front_path
                            ? this.state.customerPhotos[0].id_front_path.replace(
                                "public",
                                ""
                              )
                            : "")
                        }`}
                      ></img>
                    </>
                  )}
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="list-group user-details table-card shadow border-0 w-100">
                  {this.state.customerPhotos[0].id_back_path === undefined ? (
                    <h4>Loading...</h4>
                  ) : (
                    <>
                      <div className="p-3 bg-light font-weight-bold">
                        <Image size={18}></Image> ID Back Image
                      </div>

                      <img
                        src={`${window.server}${
                          this.state.data &&
                          (this.state.customerPhotos[0].id_back_path
                            ? this.state.customerPhotos[0].id_back_path.replace(
                                "public",
                                ""
                              )
                            : "")
                        }`}
                      ></img>
                    </>
                  )}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    let user_id = this.props.match.params.customer_id;
    fetch(`${window.server}/entity-accounts?customer_id=${user_id}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        let data = [];
        let photoData = [];
        response.data.map((d) => {
          data.push({
            full_name:
              d.customer === null
                ? "--"
                : `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`,
            date_of_birth:
              d.details.date_of_birth === null
                ? "--"
                : moment(d.details.date_of_birth).format("Do MMMM YYYY"),
            date_of_registration:
              d.customer === null
                ? "--"
                : moment(d.customer.created_at).format("Do MMMM YYYY"),
            "Phone Number": d.msisdn.msisdn,
            KRA_pin: d.details.kra_pin,
            // gender: { M: "Male", F: "Female" }[d.details.gender],
            // marital_status: {1:"Single", 2:"Married"}[d.marital_status],
            ID_number: d.details.id_number,
            // village: d.village,
            address: d.details.address === null ? "--" : d.details.address,
            nationality: { 1: "Kenyan", 2: "Nigerian" }[d.details.nationality],
            pledge:
              d.customer === null
                ? "--"
                : "KES " + parseInt(d.customer.pledge).toLocaleString(),
            EXIT_DETAILS: "",
            "Exit Request Date":
              !d.exits || d.exits.status === 3
                ? "--"
                : moment(d.exits.request_date).format("Do MMMM YYYY"),

            // "Exit Update Date":
            //   d.exits === null
            //     ? "--"
            //     : moment(d.exits.request_date).format("Do MMMM YYYY"),

            "Refund Date":
              !d.exits || d.exits.status === 3
                ? "--"
                : moment(d.exits.request_date)
                    .add(60, "days")
                    .format("Do MMMM YYYY"),
            "Days Remaining":
              !d.exits || d.exits.status === 3
                ? "--"
                : moment(d.exits.request_date).add(60, "days").fromNow(),
            exit_reason:
              !d.exits || d.exits.status === 3 ? "--" : d.exits.exit_reason,
            "Notification Status":
              d.exits === null
                ? "--"
                : d.customer.account_status == 1 && d.exits.status == 1
                ? "Exited and Reinstated"
                : ["Pending Exit", "Exited", "Canceled Exit", "Reinstated"][
                    d.exits.status
                  ],

            /* data.customer.account_status == 1 && d.exits.status == 1
                ? "Exited and Reinstated"
                : "" */
          });
          photoData.push({
            id_front_path:
              d.details.id_front_path === null ? null : d.details.id_front_path,
            id_back_path:
              d.details.id_back_path === null ? null : d.details.id_back_path,
            passport_path:
              d.details.passport_path === null ? null : d.details.passport_path,
          });
          this.setState({ customerPhotos: photoData });
        });
        response.data = data;
        this.setState({ data: data[0] });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });

    fetch(`${window.server}/utils/fetch/balances?id=${user_id}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log(response);
        // alert(JSON.stringify(response));
        let balanceInfo = [];

        response.data.map((d) => {
          balanceInfo.push({
            SAVINGS_BALANCE: "",
            available_balance:
              "KES " +
              parseFloat(
                parseFloat(d.savings.available_balance)
              ).toLocaleString(),
            actual_balance:
              "KES " +
              parseFloat(parseFloat(d.savings.actual_balance)).toLocaleString(),
            WALLET_BALANCE: "",
            wallet_available_balance:
              "KES " +
              parseFloat(
                parseFloat(d.wallet.available_balance)
              ).toLocaleString(),
            wallet_actual_balance:
              "KES " +
              parseFloat(parseFloat(d.wallet.actual_balance)).toLocaleString(),
            account_status:
              parseFloat(d.account_status) == 0
                ? "In-active"
                : parseFloat(d.account_status) == 1
                ? "Active"
                : parseFloat(d.account_status) == 3
                ? "Blocked"
                : parseFloat(d.account_status) == 4
                ? "Suspended"
                : "Dormant",

            // account_status:
            //   parseFloat(d.account_status) === 2 ? "Blocked" : "Suspended",

            member_status:
              // parseFloat(d.reg_status) === 1 ? "Active" : "Pending",
              // parseFloat(d.shares.share_status) === 1 ? "Active" : "In-active",
              parseFloat(d.account_status) === 1 ? "Active" : "In-active",

            // TOTAL_GUARANTEED: "",
            // total_guaranteed: "",
            // total_guarantee: "",
            SHARE_BALANCE: "",
            share_status:
              parseFloat(d.reg_status) === 0
                ? "Not Paid a thing"
                : parseFloat(d.reg_status) === 2
                ? "Paid Reg Fees Partially"
                : parseFloat(d.reg_status) === 1
                ? "Paid Reg Fees Fully"
                : parseFloat(d.reg_status) === 3
                ? "Bought Shares Partially"
                : parseFloat(d.reg_status) === 4
                ? "Bought Shares Fully"
                : parseFloat(d.reg_status) === 5
                ? "Never Dialled Code or Exited member"
                : parseFloat(d.reg_status) === 6
                ? "KYC Pending"
                : "Info doesn't exist",

            share_value:
              "KES " +
              parseFloat(parseFloat(d.shares.paid_share)).toLocaleString(),
            share_balance:
              "KES " +
              parseFloat(parseFloat(d.shares.total_shares)).toLocaleString(),
          });
        });

        response.result = balanceInfo;
        //console.log(balanceInfo);
        this.setState({
          balanceInfo:
            typeof balanceInfo[0] === "undefined" ? "--" : balanceInfo[0],
        });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });

    /* fetch(`${window.server}/utils/admin/customer_update_images?id=${user_id}`, {
      headers: {
        Authorization: localStorage.token,
      },
    }) 
      .then((response) => response.json())
      .then((response) => {
        // console.log("Hi", response);

        var data = response.data.map((d) => {
          //let download_string = window.server + d.url.replace("public", "");
          var result = {};

          result.id_front_path =
            d.id_front_path === null
              ? "--"
              : d.id_front_path.replace("public", window.server);
          result.id_back_path =
            d.id_back_path === null
              ? "--"
              : d.id_back_path.replace("public", window.server);
          return result;
        });
        response.data = data[0];
        // console.log("ddddd", data);
        this.setState({ customerPhotos: data[0] });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });
      */
  };
}

export default Details;
