import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class LoanInterest extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              search={["firstname", "middlename", "surname", "msisdn"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchLoanRequests = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      //customer_id: this.props.match.params.id
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/utils/loan_interest_report?${urlParams}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        let data = [];
        let status_text = "";
        response.data.map((d, i) => {
          let status_text = {
            1: "Normal",
            2: "Defaulted",
            3: "Cancelled",
            0: "Pending",
          };
          let diff =
            d.clearance_status === 1
              ? d.repayment_duration
              : parseInt(
                  moment().diff(new Date(d.date_created), "months", true) >
                    d.repayment_duration
                    ? d.repayment_duration
                    : parseInt(
                        moment().diff(new Date(d.date_created), "months", true)
                      )
                );
          data.push({
            load_id: d.loan_id,
            date_created: d.date_created,
            //customer_name: `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`,
            //loan_type: d.product.product_name,
            // loan_type: d.product_name,
            amount: parseFloat(parseFloat(d.principal_amount)).toLocaleString(),
            expected_interest: parseFloat(
              parseFloat(
                d.principal_amount *
                  ((d.interest_rate / 100) * d.repayment_duration)
              )
            ).toLocaleString(),
            /* interest_charged:
              diff < 1
                ? parseFloat(
                    parseFloat(d.principal_amount * (d.interest_rate / 100) * 1)
                  ).toLocaleString()
                : parseFloat(
                    parseFloat(
                      d.principal_amount *
                        (d.interest_rate / 100) *
                        diff
                    )
                  ).toLocaleString(), */
            /* processing_fee: parseFloat(
              parseFloat(d.principal_amount * 0.025)
            ).toLocaleString(), */
            repayment_duration: d.repayment_duration + " months",
            interest_rate: d.interest_rate,
            /* principal_balance: parseFloat(
              parseFloat(d.principal_balance)
            ).toLocaleString(), */
            total_interest_paid: parseFloat(
              parseFloat(d.total_interest_paid)
            ).toLocaleString(),
            interest_balance: parseFloat(
              parseFloat(d.interest_balance)
            ).toLocaleString(),
            /* outstanding_balance: parseFloat(
              parseFloat(d.principal_balance) +
                parseFloat(d.interest_balance) +
                parseFloat(d.commission_balance)
            ).toLocaleString(), */
            // payment_status: ["Not cleared", "Cleared"][d.clearance_status],
            // status: d.status,
            // status: status_text[d.loan_status], //parseFloat(d.loan_status) === 1 ? "Normal" : "Defaulted",
            /* guarantor_status: ["Not Guaranteed", "Fully Guaranteed"][
              d.guarantor_status
            ],
            guaranteed_amount: parseFloat(
              parseFloat(d.guaranteed_amount)
            ).toLocaleString(),
            guaranteed_interest: parseFloat(
              parseFloat(d.guaranteed_interest)
            ).toLocaleString(), */
            // loan_products: d["loan-products"],
          });
        });

        let dts = {};
        dts.data = data;
        console.log("dts", dts.data[0]);
        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchLoanRequests();
      }, 100);
    }
  }
}

export default LoanInterest;
