import React, { Component } from 'react';

class Access extends Component {
  permissions = window.roles;

  state = {
    authorized:
      window.user &&
      window.user.group.group_id &&
      ((this.permissions[this.props.permission] &&
        this.permissions[this.props.permission].indexOf(
          window.user.group.group_id
        ) !== -1) ||
        this.props.permission === 'all') 
  };

  render() {
    console.log("permisions" , this.props.permission,/* this.props.permission,  this.permissions[this.props.permission] */);
    return this.state.authorized ? <>{this.props.children}</> : <></>;
  }
}

export default Access;
