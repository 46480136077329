import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import Form from "../../components/form";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class AccountsReport extends Component {
  state = {
    startValue: "",
    endValue: "",
    members: [],
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Accounts"
          // buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className="mt-3 border-0 card shado mx-3 shadow">
          <div className="text-center">
            <h4 className="font-weight-bold">Accounts</h4>
          </div>
          <div className="mt-3 align-items-center">
            <Form
              submitText={"Generate"}
              back={false}
              inputs={[
                {
                  label: "From",
                  name: "start",
                  type: "date",
                  value: this.state.startValue,
                },
                {
                  label: "To",
                  name: "end",
                  type: "date",
                  value: this.state.endValue,
                },
                {
                  label: "Account Type",
                  name: "account_type_id",
                  value: this.state.account_type_id,
                  type: "select",
                  options: [
                    { name: "MPESA C2B UTILITY ACCOUNT", value: 1 },
                    { name: "CUSTOMER WALLET DEPOSITS", value: 2 },
                    { name: "DMS REGISTRATION FEES ACCOUNT", value: 3 },
                    { name: "DMS SHARES CAPITAL ACCOUNT", value: 4 },
                    { name: "DMS SAVINGS ACCOUNT", value: 5 },
                    { name: "LOAN ACCOUNT INTEREST REVENUE", value: 6 },
                    { name: "LOAN PORTFOLIO", value: 7 },
                    { name: "LOAN ACCOUNT COMMISSION REVENUE", value: 15 },
                    { name: "LOAN ACCOUNT PRINCIPAL", value: 16 },
                    { name: "LOAN ACCOUNT ROLLOVER REVENUE", value: 17 },
                    {
                      name: "CUSTOMER WALLET ACCOUNT LOANS WITHDRAWAL",
                      value: 19,
                    },
                  ],
                },
              ]}
              submit={(data) => {
                //console.log(data);
                //this.setState({ details: data });
                setTimeout(() => {
                  this.handleGenerate(data);
                }, 0);
              }}
            />
          </div>
        </div>

        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              // search={["firstname", "middlename", "surname", "msisdn", "id_number"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  handleOnChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleGenerate = (data) => {
    console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    let values = Object.values(obj);
    let fdat = obj.start;
    let ldat = obj.end;
    let account_type_id = obj.account_type_id;

    console.log(obj.start);
    console.log(obj.end);
    console.log(obj.account_type_id);

    alert(values);
    //  let q = {
    //     // ...this.state.filter,
    //     ...this.state.query,
    //     //"$or[0][account_type_id]": 20
    //     start: sdata,
    //     end: ldat
    //   };

    //   let urlParams = Object.entries(q)
    //     .map(e => e.join("="))
    //     .join("&");

    fetch(
      `${window.server}/utils/account-report?start=${fdat}&end=${ldat}&account_type=${account_type_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.token,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        let data = [];

        response.data.map((d, i) => {
          data.push({
            id: d.id,
            transaction_id: d.transaction_id,
            ref_no: d.transaction_ref_no,
            // member:
            //   !d.customer ||
            //   `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`,
            account_type: d.account.name,
            debit: parseFloat(parseFloat(d.debit)).toLocaleString(),
            credit: parseFloat(parseFloat(d.credit)).toLocaleString(),
            // amount:
            //   "KES " +
            //   parseFloat(
            //     parseFloat(d.debit) !== 0 ? d.debit : d.credit
            //   ).toLocaleString(),
            // type: parseFloat(d.debit) === 0 ? "Credit" : "Debit",
            // balance_before:
            //   parseFloat(
            //     parseFloat(d.balance_before)
            //   ).toLocaleString(),
            // balance_after:
            //   parseFloat(
            //     parseFloat(d.balance_after)
            //   ).toLocaleString(),
            description: d.description,
            narration: d.narration,
            date_time: moment(d.date_created).format("Do MMMM YYYY, h:mm:ss a"),
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    let $t = this;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      $t.handleGenerate();
    }, 100);
  }
}

export default AccountsReport;
