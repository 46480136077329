import React, { Component } from "react";
import Table from "../../../components/Table";
import Form from "../../../components/form";
import moment from "moment";
import "moment-timezone";
import Nav from "../../../components/Nav";

class MobileLoansApprovals extends Component {
  state = {
    startValue: "",
    endValue: "",
    members: [],
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav name="Mobile Loans Approvals"></Nav>
        <div className="mt-3 border-0 card  mx-3 shadow">
          <div className="text-center">
            <h4 className="font-weight-bold">Mobile Loans Approvals</h4>
          </div>
          <div className="mt-3 align-items-center">
            <Form
              submitText={"Generate"}
              back={false}
              inputs={[
                {
                  label: "From",
                  name: "start",
                  type: "datetime-local",
                  value: this.state.startValue,
                },
                {
                  label: "To",
                  name: "end",
                  type: "datetime-local",
                  value: this.state.endValue,
                },
              ]}
              submit={(data) => {
                //console.log(data);
                //this.setState({ details: data });
                setTimeout(() => {
                  this.handleGenerate(data);
                }, 0);
              }}
            />
          </div>
        </div>

        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              // search={["msisdn"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  handleOnChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleGenerate = (data) => {
    if (!data) return;
    console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    let fdat = obj.start;
    let ldat = obj.end;

    let sdat = moment.utc(fdat).format("YYYY-MM-DD HH:mm:00");
    let edat = moment.utc(ldat).format("YYYY-MM-DD HH:mm:59");

    console.log(obj.start);
    console.log(obj.end);

    //alert(values);
    fetch(`${window.server}/utils/mobile_loans?start=${sdat}&end=${edat}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("response", response.data[0]);
        let data = [];

        response.data.map((d, i) => {
          console.log("d", d.id);
          data.push({
            // msisdn: d.msisdn,
            date_time: moment
              .utc(d.date_created)
              .format("DD MMMM, YYYY.  HH:mm:ss"),
            member: d.customer
              ? `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`
              : "",
            loan_type: d.product.product_name,
            amount: parseFloat(parseFloat(d.amount)).toLocaleString(),
            processing_fee: parseFloat(
              parseFloat(d.amount * 0.025)
            ).toLocaleString(),
            repayment_duration: d.repayment_duration + " months",
          });
        });
        console.log("data", data);
        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    let $t = this;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      $t.handleGenerate(null);
    }, 100);
  }
}

export default MobileLoansApprovals;
