import React, { Component } from "react";
import Nav from "../../components/Nav";
import { Send, Users, Plus, Search } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";

class SMSCreate extends Component {
  state = {
    addressBooks: [],
    MessageTemplate: [],
    addressModal: false,
    messageTemplateModal: false,
    activeAddressBook: false,
    activeMessageTemplate: false,
    msisdn: null,
    message: null,
    receiver: undefined,
    visible: false,
  };
  render() {
    return (
      <div>
        <Nav name="Create an SMS"></Nav>
        <div className="mt-3 3 table-card sms-table-card  border-0 card shado mx-3 shadow p-4">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              //this.sendSms();
              this.setState({ visible: true });
            }}
          >
            <hr />

            <div class="mx-3 d-inline-block mb-3">
              <span class="ml-1 mb-2 d-block font-weight-bold">Receiver</span>
              <select
                placeholder="..."
                class="form-control bg-light sms-input"
                required=""
                onChange={(e) => {
                  this.setState({ activeAddressBook: false });
                  this.setState({ activeMessageTemplate: false });
                  this.setState({ receiver: e.target.value });
                }}
              >
                <option value="0" selec>
                  Choose a receiver
                </option>
                <option value="1">Individual</option>
                <option value="2">Group</option>
              </select>
            </div>

            {this.state.receiver === "1" && (
              <div>
                <div class="row">
                  <div class="mx-3 d-inline-block mb-3">
                    <span class="ml-1 mb-2 d-block font-weight-bold">
                      Message
                    </span>
                    <textarea
                      placeholder="..."
                      class="form-control bg-light sms-input"
                      rows={5}
                      required={true}
                      onChange={(e) => {
                        this.setState({ message: e.target.value });
                      }}
                    ></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="mx-3 d-inline-block mb-3">
                    <span class="ml-1 mb-2 d-block font-weight-bold">
                      Enter Phone Number
                    </span>
                    <input
                      placeholder="..."
                      class="form-control bg-light sms-input"
                      rows={5}
                      type="number"
                      required={true}
                      onChange={(e) => {
                        this.setState({ msisdn: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {this.state.receiver === "2" && (
              <div className="d-flex flex-row flex-wrap align-items-center">
                {/* {this.state.activeAddressBook === false && (
                  <>
                  <div class="row">
                    <div
                      className="m-3 address-card m-3 d-flex flex-row align-items-center"
                      onClick={() => {
                        this.setState({ addressModal: true });
                      }}
                    >
                      <div className="ico ml-3 my-3">
                        <Plus size={30}></Plus>
                      </div>
                      <div className="content p-3">
                        <h5 className="font-weight-bold">
                          Choose An Address Book
                        </h5>
                        <div>
                          <small>
                            If you Do not have one, You can create one{" "}
                            <Link to="/address-book-create">Here</Link>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
                )} */}
                {this.state.activeMessageTemplate === false && (
                  <>
                    <div class="row">
                      <div
                        className="m-3 address-card m-3 d-flex flex-row align-items-center"
                        onClick={() => {
                          this.setState({ messageTemplateModal: true });
                        }}
                      >
                        <div className="ico ml-3 my-3">
                          <Plus size={30}></Plus>
                        </div>
                        <div className="content p-3">
                          <h5 className="font-weight-bold">
                            Choose A Message Template
                          </h5>
                          <div>
                            <small>
                              If you Do not have one, You can create one{" "}
                              <Link to="/message-template">Here</Link>
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {this.state.activeMessageTemplate !== false && (
                  <div>
                    <h5 className="font-weight-bold">Message Template</h5>
                    <div className="m-3 address-card m-3 d-flex flex-row align-items-center">
                      <div className="ico ml-3">
                        <Users size={30}></Users>
                      </div>
                      <div className="content p-3">
                        <div className="font-weight-bold">
                          {
                            this.state.MessageTemplate[
                              this.state.activeMessageTemplate
                            ].name
                          }
                        </div>
                        <div>
                          <small>
                            {
                              this.state.MessageTemplate[
                                this.state.activeMessageTemplate
                              ].message
                            }
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.activeMessageTemplate !== false && (
                  <div>
                    <h5 className="font-weight-bold">
                      Address book Attached to template
                    </h5>
                    <div className="m-3 address-card m-3 d-flex flex-row align-items-center">
                      <div className="ico ml-3">
                        <Users size={30}></Users>
                      </div>
                      <div className="content p-3">
                        <div className="font-weight-bold">
                          {
                            this.state.MessageTemplate[
                              this.state.activeMessageTemplate
                            ].address_book_name
                          }
                        </div>
                        <div>
                          <small>
                            {
                              this.state.MessageTemplate[
                                this.state.activeMessageTemplate
                              ].address_book_description
                            }
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {typeof this.state.receiver !== "undefined" && (
              <div>
                <hr />
                <button className="btn btn-primary btn-round pl-2 pr-4">
                  <Send size={20} className="mr-2"></Send>
                  Send Message
                </button>
              </div>
            )}
          </form>
        </div>

        <Modal
          visible={this.state.addressModal}
          close={() => this.setState({ addressModal: false })}
        >
          <h5 className="font-weight-bold">Find An AddressBook</h5>
          <hr />
          <form
            className="d-flex flex-column justify-content-center position-relative"
            onSubmit={(e) => {
              e.preventDefault();
              this.findAddressBook();
            }}
          >
            <Search className="search-icon cursor-pointer" color="grey" />
            <input
              type="search"
              className="form-control pl-5 rounded bg-light"
              placeholder="search"
              onChange={(e) => {
                this.setState({ addressBookSearch: `%${e.target.value}%` });
              }}
            />
          </form>
          <div className="">
            {this.state.addressBooks.map((d, i) => (
              <div
                className=" address-card my-3 d-flex flex-row align-items-center w-100"
                key={i}
                onClick={() => {
                  this.setState({ addressModal: false, activeAddressBook: i });
                }}
              >
                <div className="ico ml-3">
                  <Users size={30}></Users>
                </div>
                <div className="content p-3">
                  <div className="font-weight-bold">{d.name}</div>
                  <div>
                    <small>{d.description}</small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal>

        <Modal
          visible={this.state.messageTemplateModal}
          close={() => this.setState({ messageTemplateModal: false })}
        >
          <h5 className="font-weight-bold">Pick A Template</h5>
          <hr />
          <form
            className="d-flex flex-column justify-content-center position-relative"
            onSubmit={(e) => {
              e.preventDefault();
              //this.findMessageTemplate();
            }}
          >
            {/*  <Search className="search-icon cursor-pointer" color="grey" />
            <input
              type="search"
              className="form-control pl-5 rounded bg-light"
              placeholder="search"
              onChange={e => {
                this.setState({ messageTemplateSearch: `%${e.target.value}%` });
              }}
            /> */}
          </form>
          <div className="">
            {this.state.MessageTemplate.map((d, i) => (
              <div
                className=" address-card my-3 d-flex flex-row align-items-center w-100"
                key={i}
                onClick={() => {
                  this.setState({
                    messageTemplateModal: false,
                    activeMessageTemplate: i,
                  });
                }}
              >
                <div className="ico ml-3">
                  <Users size={30}></Users>
                </div>
                <div className="content p-3">
                  <div className="font-weight-bold">{d.name}</div>
                  <div>
                    <small>{d.message}</small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal>
        <Modal
          visible={this.state.visible}
          close={() => this.setState({ messageTemplateModal: false })}
        >
          <h5 className="font-weight-bold">Please confirm</h5>
          <hr />
          <form
            className="d-flex flex-column justify-content-center position-relative"
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({ visible: false });
              this.sendSms();
            }}
          >
            <div className="">
              <p>Confirm that you want to queue the messages </p>
            </div>
            <button className="btn btn-primary btn-round pl-2 pr-4">
              <Send size={20} className="mr-2"></Send>
              Okay
            </button>
          </form>
        </Modal>
      </div>
    );
  }
  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      id: this.props.match.params.id,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/utils/admin/message-address-book`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("address", response);
        var data = [];
        response.data.map((d, i) => {
          data.push({
            id: d.id,
            name: d.name,
            description: d.description,
            query: d.query,
          });
        });
        console.log("data", data);
        this.setState({ addressBooks: data });
      })
      .catch((d) => {
        console.error(d);
      });

    fetch(`${window.server}/utils/admin/message-template-address`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("template-aaddress", response);
        var data = [];
        response.data.map((d, i) => {
          data.push({
            template_id: d.template_id,
            name: d.template_name,
            message: d.template,
            address_book_id: d.address_book_id,
            address_book_name: d.address_book_name,
            address_book_description: d.address_book_description,
          });
        });
        console.log("data", data);
        this.setState({ MessageTemplate: data });
      })
      .catch((d) => {
        console.error(d);
      });
  };
  openSmsPopUp() {
    return (
      <div
        class={this.state.visible ? "modal fade d-block show" : "modal fade"}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: 500 }}
          role="document"
        >
          <div className="modal-content">
            <div
              className="icon position-absolute modal-close-icon m-2"
              onClick={() => {
                this.setState({ visible: false });
                if (this.props.close) {
                  this.props.close();
                }
              }}
            ></div>
            <div className="modal-body">
              <p>Are you sure you want to send the sms ?</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  sendSms = () => {
    let data = {};
    if (this.state.receiver == 1) {
      data = {
        msisdn: this.state.msisdn,
        msg_text: this.state.message,
      };
    } else if (this.state.receiver == 2) {
      data = {
        active_address_book_id:
          this.state.MessageTemplate[this.state.activeMessageTemplate]
            .address_book_id,
        active_message_template_id:
          this.state.MessageTemplate[this.state.activeMessageTemplate]
            .template_id,
        message:
          this.state.MessageTemplate[this.state.activeMessageTemplate].message,
      };
    }
    if (
      this.state.receiver == 2 &&
      !this.state.MessageTemplate[this.state.activeMessageTemplate]
        .address_book_id
    ) {
      alert("Message Template has no Address Book attached to it");
    } else {
      console.log("data", data);
      this.setState({ modalVisible: true });
      fetch(`${window.server}/utils/admin/send-sms`, {
        method: "POST",
        headers: {
          Authorization: localStorage.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("smsm response ", response);
          alert(response.result);
          this.setState({ activeAddressBook: false });
          this.setState({ activeMessageTemplate: false });
          this.setState({ receiver: undefined });
          //window.location = "/sms";
        })
        .catch((d) => {
          alert(d.message);
          console.log(d);
        });
    }
  };
  findMessageTemplate = () => {
    this.setState({ tableLoading: true });
    let params = {
      $skip: this.state.offset,
      $limit: this.state.limit,
    };

    if (this.state.sort !== "") {
      params[`$sort[${this.state.sort}]`] = this.state.sortDirection;
    }
    if (this.state.searchEnabled) {
      // $or[0][archived][$ne]=true
      let search = this.state.searchValue.replace(/\s/, "+");
      let count = 0;
      // this.state.searchValue
      //   .trim()
      //   .split(" ")
      //   .map(search => {
      // if (search === "") return;

      if (typeof this.state.search !== "object") {
        params[this.state.search] =
          this.state.search === "msisdn" ? window.verifyNumber(search) : search;
        return false;
      }
      this.state.search.map((d, i1) => {
        // if (d === "msisdn") {
        //   console.log(true, window.verifyNumber(search));
        //   if (window.verifyNumber(search) === 254) {
        //     return false;
        //   }
        // }

        params[`$or[${count++}][${d}][$like]`] = `%${
          d === "msisdn" ? parseInt(search) : search
        }%`;
      });
      console.log("g");
      // });
    }
    console.log("params", params);
    this.props.fetch(params);
  };
}

export default SMSCreate;
