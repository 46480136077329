import React, { Component } from "react";
import moment from "moment";
import {
  Search,
  Edit,
  Trash,
  Pause,
  Slash,
  FileText,
  Download,
  Settings,
  File,
  Check,
  CheckCircle,
} from "react-feather";
import Chart from "../components/chart";
import Chart2 from "../components/chart2";
import Filter from "../components/filter";
import Form from "../components/form";
import Modal from "../components/modal";
import { Link } from "react-router-dom";
import logo from "../img/logo.png";

class Dashboard extends Component {
  state = {
    loanDataset: [],
    titles: [],
    data: [],
    loading: this.props.loading,
    csv_file_name: "monthly loan collections",
    CSVmodal: this.path ? this.path : window.location.pathname.split("/")[2],
    start: "",
    end: "",
    generating: false,
    generateComplete: false,
    csvMode: false,
    checkbox: this.props.checkbox,
    checked: this.props.checked,
    checkAll: false,
    checkboxIDS: [],
  };
  fetchTable = () => {
    console.log("params");
    this.props.fetch();
  };

  render() {
    return (
      <div>
        <div className="px-3 my-5">
          <div className="d-flex flex-row align-items-center">
            <img src={logo} className="dasboard-logo mr-3" alt="" />
            <div>
              <h1 className="mb-0 font-weight-bold text-primary">
                DIGITAL SACCO
              </h1>
            </div>
          </div>
        </div>

        <div className="p-3 d-flex flex-row justify-content-between align-items-center d-flex flex-row align-items-center'">
          <h2 className="font-weight-bold">Loan Collection Summary</h2>
        </div>
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <Form
            key={this.state.activeDetails}
            submitText={"Generate"}
            back={false}
            inputs={[
              {
                label: "Date",
                name: "start",
                type: "datetime-local",
                value: this.state.startValue,
              },
            ]}
            submit={(data) => {
              //console.log(data);
              this.setState({ details: data });
              setTimeout(() => {
                this.fetchLoanChartData(data);
              }, 0);
            }}
          />
          <div className="px-3">
            {/* <button
                  onClick={() => {
                    this.setState({ CSVmodal: true });
                  }}
                  className="option-card pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-outline-primary btn-round btn-sm mr-3"
                >
                  <Download size={16} />
                  <span className="pl-2 font-weight-bold no-wrap">
                    Generate Excel
                  </span>
                </button> */}
            <Chart2 data={this.state.loanDataset}></Chart2>
          </div>
        </div>
        <Modal
          visible={this.state.CSVmodal}
          close={() =>
            this.setState({
              CSVmodal: false,
              generating: false,
              generateComplete: false,
              csvMode: false,
              limit: 10,
            })
          }
        >
          <div className="py-3 generate-csv-modal d-flex flex-column justify-content-between">
            <h5 className="text-center font-weight-bold">Generate Excel</h5>

            {!this.state.generating && !this.state.generateComplete && (
              <>
                <div className="my-4 text-center d-flex flex-column align-items-center">
                  <div>
                    <File size={18} className="text-dark"></File>{" "}
                    <label htmlFor="">File name</label>
                  </div>
                  <input
                    type="text"
                    class="form-control rounded bg-light file-name-input text-center text-dark"
                    placeholder="File name"
                    value={this.state.csv_file_name}
                    onChange={(e) => {
                      this.setState({ csv_file_name: e.target.value });
                    }}
                  />
                </div>

                <div className="d-flex flex-row justify-content-center">
                  <button
                    onClick={() => {
                      this.setState({
                        generating: true,
                        csvMode: true,
                        limit: 50000,
                      });
                      setTimeout(() => {
                        this.fetchLoanChartData({
                          start: this.state.start,
                          end: this.state.end,
                        });
                      }, 0);
                    }}
                    className="option-card pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-outline-primary btn-round mr-3"
                  >
                    <Settings size={16} />
                    <span className="pl-2 font-weight-bold no-wrap">
                      Generate
                    </span>
                  </button>
                </div>
              </>
            )}
            {this.state.generating && (
              <>
                <div className="d-flex flex-row justify-content-center">
                  <div class="lds-hourglass"></div>
                </div>
                <div></div>
              </>
            )}

            {this.state.generateComplete && (
              <>
                <div className="my-4 text-center d-flex flex-column align-items-center text-success">
                  <CheckCircle size={70}></CheckCircle>
                  <span>Complete</span>
                </div>

                <div className="d-flex flex-row justify-content-center">
                  <a
                    href={"data:text/plain;charset=utf-8," + this.state.CSVURI}
                    download={this.state.csv_file_name + ".csv"}
                    className="option-card pr-4 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-outline-primary btn-round mr-3"
                  >
                    <Download size={16} />
                    <span className="pl-2 font-weight-bold no-wrap">
                      Download
                    </span>
                  </a>
                </div>
              </>
            )}
          </div>
        </Modal>
      </div>
    );
  }

  fetchLoanChartData = (data) => {
    console.log("data", data.start);
    let sdat = moment(data.start)
      .startOf("month")
      .format("YYYY-MM-DD HH:mm:59");
    let edat = moment(data.start).endOf("month").format("YYYY-MM-DD HH:mm:59");
    this.setState({
      start: data.start,
      end: data.end,
    });
    console.log("dates", sdat, edat);
    fetch(
      `${window.server}/utils/admin/monthly-loan-standing-report?start=${sdat}&end=${edat}`,
      {
        headers: {
          Authorization: localStorage.token,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("month response", response.data);

        if (response.code) {
        } else {
          let interest_gained = [];

          /* response.map(d => {
                interest_gained.push({ t: new Date(d.date), y: d.interest_gained });
              }); */
          console.log("response", response);
          if (response.data.length > 0) {
            this.setState({ loanDataset: response.data });
          } else {
            window.alert2.notify({
              title: "NO DATA AVAILABLE",
              description: "no data available for specified month",
              color: "material-deep-orange",
              autoClose: true,
              onClose: () => {},
            });
          }
        }
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };
  componentDidMount() {
    //this.fetchLoanChartData()
  }
}

export default Dashboard;
