import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class MemberWithdrawals extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              search={["firstname", "middlename", "surname", "msisdn"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              hasPdf={true}
              pdfData={null}
              pdfName={`${this.props.match.params.customer_id}_withdrawal_statement`}
              pdf_type={"statement"}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchMemberWithdrawals = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      "$or[0][account_type_id]": 11,
      "$or[1][account_type_id]": 22,
      customer_id: this.props.match.params.customer_id,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/customer-logs?${urlParams}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        let data = [];
        response.data.map((d, i) => {
          data.push({
            id: d.id,
            transaction_id: d.transaction_id,
            ref_no: d.transaction_ref_no,
            // member:
            //   !d.customer ||
            //   `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`,
            // account_type_id: d.account_type_id,
            debit: parseFloat(parseFloat(d.debit)).toLocaleString(),
            credit: parseFloat(parseFloat(d.credit)).toLocaleString(),
            // amount:
            //   "KES " +
            //   parseFloat(
            //     parseFloat(d.debit) !== 0 ? d.debit : d.credit
            //   ).toLocaleString(),
            // type: parseFloat(d.debit) === 0 ? "Credit" : "Debit",
            balance_before: parseFloat(
              parseFloat(d.balance_before)
            ).toLocaleString(),
            balance_after: parseFloat(
              parseFloat(d.balance_after)
            ).toLocaleString(),
            description: d.description,
            narration: d.narration,
            date_time: moment
              .utc(d.date_created)
              .format("DD MMMM, YYYY.  HH:mm:ss"),
            // status: d.status  moment.utc(fixture.kick_off).format('HH:mm A')
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchMemberWithdrawals();
      }, 100);
    }
  }
}

export default MemberWithdrawals;
