/** @format */

import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class CheckOffApproval extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };

  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav name='Checkoff Approval'></Nav>
        <div className='mt-3 table-card  border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Table
              //search={["firstname", "middlename", "surname"]}
              sort='id'
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchCheckOffApproval = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    fetch(`${window.server}/utils/admin/checkoff?${urlParams}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        let data = [];
        response.data.map((d, i) => {
          data.push({
            // id: d.id,
            cheque_no: d.cheque_no,
            cheque_amount: parseFloat(d.cheque_amount).toLocaleString(),
            total_users: d.total_users,
            status: [
              <button className='btn btn-warning btn-round pc-3'>
                Pending
              </button>,
              <button className='btn btn-success btn-round pc-3'>
                Approved
              </button>,
              <button className='btn btn-danger btn-round pc-3'>
                Declined
              </button>,
            ][d.cheque_status],
            // created_by: d.created_by,
            date_created: moment
              .utc(d.date_created)
              .format("Do MMMM YYYY, h:mm:ss a"),

            // cheque_id: d.cheque_id,
            action: (
              <div className='d-flex flex-row'>
                <Link
                  to={"/check-view/details/" + d.id + "/" + d.cheque_id}
                  className='btn btn-sm btn-primary px-3 btn-round'
                >
                  View
                </Link>
              </div>
            ),
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchCheckOffApproval();
      }, 100);
    }
  }
}

export default CheckOffApproval;
