import React, { Component } from "react";
import moment from "moment";
//import Form from "../../components/form";
import { DollarSign, User, LogOut } from "react-feather";
import Filter from "../components/filter";
import Nav from "../components/Nav";

class Summaries extends Component {
  randomize = (start, end) => {
    return Math.floor(Math.random() * end) + start;
  };

  state = {
    filter: {
      startdate: moment().subtract(300, "days").format("YYYY-MM-DD"),
      enddate: moment().format("YYYY-MM-DD"),
    },
    totals: {},
    monthly: {},
    weekly: {},
    todays: {},
    salaryVisible: false,
    exitInfo: {},
  };
  render() {
    return (
      <div>
        <Nav name="Summaries"></Nav>
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-3">
            <Filter
              getFilter={(filter) => {
                setTimeout(() => {
                  this.setState({
                    filter,
                  });
                }, 0);
              }}
              dateRange={["startdate", "enddate"]}
            />

            <div className="row">
              <div className="col-md-6">
                <ul className="list-group user-details table-card shadow border-0 w-100">
                  <div className="p-3 bg-light font-weight-bold">
                    Total Summary
                  </div>
                  {Object.keys(this.state.totals).map((d) => (
                    <li className="list-group-item d-flex flex-row  text-dark">
                      <div className="user-detail-title font-weight-bold text-capitalize">
                        {d.replace(/_/g, " ")}
                      </div>
                      <div>{this.state.totals[d]}</div>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-md-6">
                <ul className="list-group user-details table-card shadow border-0 w-100">
                  <div className="p-3 bg-light font-weight-bold">
                    Month Summary
                  </div>
                  {Object.keys(this.state.monthly).map((d) => {
                    if (d === "break" || d === "brea")
                      return <div className="p-1 bg-light"></div>;

                    return (
                      <li className="list-group-item d-flex flex-row text-dark">
                        <div className="user-detail-title font-weight-bold text-capitalize">
                          {d.replace(/_/g, " ")}
                        </div>
                        <div>{this.state.monthly[d]}</div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <ul className="list-group user-details table-card shadow border-0 w-100">
                  <div className="p-3 bg-light font-weight-bold">
                    Week Summary
                  </div>
                  {Object.keys(this.state.weekly).map((d) => (
                    <li className="list-group-item d-flex flex-row  text-dark">
                      <div className="user-detail-title font-weight-bold text-capitalize">
                        {d.replace(/_/g, " ")}
                      </div>
                      <div>{this.state.weekly[d]}</div>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-md-6">
                <ul className="list-group user-details table-card shadow border-0 w-100">
                  <div className="p-3 bg-light font-weight-bold">
                    Today Summary
                  </div>
                  {Object.keys(this.state.totals).map((d) => {
                    if (d === "break" || d === "brea")
                      return <div className="p-1 bg-light"></div>;

                    return (
                      <li className="list-group-item d-flex flex-row text-dark">
                        <div className="user-detail-title font-weight-bold text-capitalize">
                          {d.replace(/_/g, " ")}
                        </div>
                        <div>{this.state.todays[d]}</div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    let user_id = this.props.match.params.id;
    fetch(`${window.server}/utils/admin/total-summaries`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log(response);
        let totals = [];
        response.data.map((d) => {
          totals.push({
            "Members Registered": d.total_members,
            "Fully Registered Members": d.total_fully_reg_members,
            //"Active Members": d.total_fully_reg_members,
            "Blocked Member Accounts": d.total_blocked_members,
            "suspended Member Accounts": d.total_suspended_members,
            "Dormant Member Accounts": d.total_dormant_members,
            "Total Exit Members": d.total_exit_members,
            "Total Amount Refunded": parseFloat(
              parseFloat(d.total_refunds)
            ).toLocaleString(),
            "Mpesa Deposits": parseFloat(
              parseFloat(d.total_deposits)
            ).toLocaleString(),
            "Savings Actual Balance": parseFloat(
              parseFloat(d.savings.actual_balance)
            ).toLocaleString(),
            "Savings Available Balance": parseFloat(
              parseFloat(d.savings.available_balance)
            ).toLocaleString(),
            "Interest Earned": parseFloat(
              parseFloat(d.interest.earned)
            ).toLocaleString(),
            "Interest Receivable": parseFloat(
              parseFloat(d.interest.receivable)
            ).toLocaleString(),
            "Borrowed Loan Count": d.loans.borrowed_loan_count,
            "Borrowed Loan Amount": parseFloat(
              parseFloat(d.loans.borrowed_loan_amount)
            ).toLocaleString(),
            "Repaid Loan Count": d.loans.repaid_loan_count,
            "Repaid Loan Amount": parseFloat(
              parseFloat(d.loans.repaid_loan_amount)
            ).toLocaleString(),
            Shares: parseFloat(parseFloat(d.total_shares)).toLocaleString(),
          });
        });
        response.data = totals;
        this.setState({ totals: totals[0] });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });

    fetch(`${window.server}/utils/admin/month-summaries`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log(response);
        let monthly = [];
        response.data.map((d) => {
          monthly.push({
            "Members Registered": d.total_members,
            "Fully Registered Members": d.total_fully_reg_members,
            //"Active Members": d.total_fully_reg_members,
            "Blocked Member Accounts": d.total_blocked_members,
            "suspended Member Accounts": d.total_suspended_members,
            "Dormant Member Accounts": d.total_dormant_members,
            "Total Exit Members": d.total_exit_members,
            "Total Amount Refunded": parseFloat(
              parseFloat(d.total_refunds)
            ).toLocaleString(),
            "Mpesa Deposits": parseFloat(
              parseFloat(d.total_deposits)
            ).toLocaleString(),
            "Savings Actual Balance": parseFloat(
              parseFloat(d.savings.actual_balance)
            ).toLocaleString(),
            "Savings Available Balance": parseFloat(
              parseFloat(d.savings.available_balance)
            ).toLocaleString(),
            "Interest Earned": parseFloat(
              parseFloat(d.interest.earned)
            ).toLocaleString(),
            "Interest Receivable": parseFloat(
              parseFloat(d.interest.receivable)
            ).toLocaleString(),
            "Borrowed Loan Count": d.loans.borrowed_loan_count,
            "Borrowed Loan Amount": parseFloat(
              parseFloat(d.loans.borrowed_loan_amount)
            ).toLocaleString(),
            "Repaid Loan Count": d.loans.repaid_loan_count,
            "Repaid Loan Amount": parseFloat(
              parseFloat(d.loans.repaid_loan_amount)
            ).toLocaleString(),
            Shares: parseFloat(parseFloat(d.total_shares)).toLocaleString(),
          });
        });
        response.data = monthly;
        this.setState({ monthly: monthly[0] });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });

    fetch(`${window.server}/utils/admin/week-summaries`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log(response);
        let weekly = [];
        response.data.map((d) => {
          weekly.push({
            "Members Registered": d.total_members,
            "Fully Registered Members": d.total_fully_reg_members,
            //"Active Members": d.total_fully_reg_members,
            "Blocked Member Accounts": d.total_blocked_members,
            "suspended Member Accounts": d.total_suspended_members,
            "Dormant Member Accounts": d.total_dormant_members,
            "Total Exit Members": d.total_exit_members,
            "Total Amount Refunded": parseFloat(
              parseFloat(d.total_refunds)
            ).toLocaleString(),
            "Mpesa Deposits": parseFloat(
              parseFloat(d.total_deposits)
            ).toLocaleString(),
            "Savings Actual Balance": parseFloat(
              parseFloat(d.savings.actual_balance)
            ).toLocaleString(),
            "Savings Available Balance": parseFloat(
              parseFloat(d.savings.available_balance)
            ).toLocaleString(),
            "Interest Earned": parseFloat(
              parseFloat(d.interest.earned)
            ).toLocaleString(),
            "Interest Receivable": parseFloat(
              parseFloat(d.interest.receivable)
            ).toLocaleString(),
            "Borrowed Loan Count": d.loans.borrowed_loan_count,
            "Borrowed Loan Amount": parseFloat(
              parseFloat(d.loans.borrowed_loan_amount)
            ).toLocaleString(),
            "Repaid Loan Count": d.loans.repaid_loan_count,
            "Repaid Loan Amount": parseFloat(
              parseFloat(d.loans.repaid_loan_amount)
            ).toLocaleString(),
            Shares: parseFloat(parseFloat(d.total_shares)).toLocaleString(),
          });
        });
        response.data = weekly;
        this.setState({ weekly: weekly[0] });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });

    fetch(`${window.server}/utils/admin/today-summaries`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log(response);
        let todays = [];
        response.data.map((d) => {
          todays.push({
            "Members Registered": d.total_members,
            "Fully Registered Members": d.total_fully_reg_members,
            //"Active Members": d.total_fully_reg_members,
            "Blocked Member Accounts": d.total_blocked_members,
            "suspended Member Accounts": d.total_suspended_members,
            "Dormant Member Accounts": d.total_dormant_members,
            "Total Exit Members": d.total_exit_members,
            "Total Amount Refunded": parseFloat(
              parseFloat(d.total_refunds)
            ).toLocaleString(),
            "Mpesa Deposits": parseFloat(
              parseFloat(d.total_deposits)
            ).toLocaleString(),
            "Savings Actual Balance": parseFloat(
              parseFloat(d.savings.actual_balance)
            ).toLocaleString(),
            "Savings Available Balance": parseFloat(
              parseFloat(d.savings.available_balance)
            ).toLocaleString(),
            "Interest Earned": parseFloat(
              parseFloat(d.interest.earned)
            ).toLocaleString(),
            "Interest Receivable": parseFloat(
              parseFloat(d.interest.receivable)
            ).toLocaleString(),
            "Borrowed Loan Count": d.loans.borrowed_loan_count,
            "Borrowed Loan Amount": parseFloat(
              parseFloat(d.loans.borrowed_loan_amount)
            ).toLocaleString(),
            "Repaid Loan Count": d.loans.repaid_loan_count,
            "Repaid Loan Amount": parseFloat(
              parseFloat(d.loans.repaid_loan_amount)
            ).toLocaleString(),
            Shares: parseFloat(parseFloat(d.total_shares)).toLocaleString(),
          });
        });
        response.data = todays;
        this.setState({ todays: todays[0] });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };
}

export default Summaries;
