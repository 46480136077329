import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Edit2, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import Form from "../../components/form";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import "moment-timezone";
import Nav from "../../components/Nav";

class Suspense extends Component {
  state = {
    startValue: "",
    endValue: "",
    status: "",
    suspense: [],
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Suspense Account"
          // buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className="mt-3 border-0 card shado mx-3 shadow">
          <div className="text-center">
            <h4 className="font-weight-bold">Suspense Account</h4>
          </div>
          <div className="mt-3 align-items-center">
            <Form
              key={this.state.activeDetails}
              submitText={"Generate"}
              back={false}
              inputs={[
                {
                  label: "From",
                  name: "start",
                  type: "datetime-local",
                  value: this.state.startValue,
                },
                {
                  label: "To",
                  name: "end",
                  type: "datetime-local",
                  value: this.state.endValue,
                },
                {
                  label: "Status",
                  name: "status",
                  value: this.state.status,
                  type: "select",
                  options: [
                    { name: "Pending", value: 0 },
                    { name: "Confirmed", value: 500 },
                    { name: "All", value: " " },
                  ],
                },
              ]}
              submit={(data) => {
                //console.log(data);
                this.setState({ details: data });
                setTimeout(() => {
                  this.handleGenerate(data);
                }, 0);
              }}
            />
          </div>
        </div>

        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              // search={["firstname", "middlename", "surname", "msisdn", "id_number"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>

        <Modal
          visible={this.state.reversalModal}
          close={() => this.setState({ reversalModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Reversal</h5>
          </div>
          {typeof this.state.activeDetails !== "undefined" && (
            <div className="mt-3">
              <Form
                key={this.state.activeDetails}
                submitText={"Reversal"}
                back={false}
                inputs={[
                  {
                    label: "Id",
                    name: "id",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails].id,
                  },
                  {
                    label: "Payment Ref No",
                    name: "payment_ref_no",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .payment_ref_no,
                  },
                  {
                    label: "Comment",
                    name: "comment",
                    type: "textarea",
                    placeholder: "comment",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .comment || "",
                  },
                  {
                    label: "Confirm Pin",
                    name: "user_pin",
                    type: "password",
                    placeholder: "enter your user password",
                    value: this.state.user_pin,
                  },
                ]}
                submit={(data) => {
                  //console.log(data);
                  this.setState({ details: data });
                  setTimeout(() => {
                    this.Reversal(data);
                  }, 0);
                }}
              />
            </div>
          )}
        </Modal>

        <Modal
          visible={this.state.repostModal}
          close={() => this.setState({ repostModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Repost</h5>
          </div>
          {typeof this.state.activeDetails !== "undefined" && (
            <div className="mt-3">
              <Form
                key={this.state.activeDetails}
                submitText={"Repost"}
                back={false}
                inputs={[
                  {
                    label: "Id",
                    name: "id",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails].id,
                  },
                  {
                    label: "Old Phone Number",
                    name: "msisdn",
                    type: "tel",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails].msisdn,
                  },
                  {
                    label: "New Phone Number",
                    name: "new_msisdn",
                    type: "tel",
                    value: this.state.new_msisdn,
                  },
                  {
                    label: "transaction Id",
                    name: "transaction_id",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .transaction_id,
                  },
                  {
                    label: "Payment Ref No",
                    name: "payment_ref_no",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .payment_ref_no,
                  },
                  {
                    label: "Amount",
                    name: "credit",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails].credit,
                  },
                  // {
                  //   label: "Repost Date",
                  //   name: "repost_date",
                  //   type: "date",
                  //   value: this.state.repost_date
                  // },
                  {
                    label: "Comment",
                    name: "comment",
                    type: "textarea",
                    placeholder: "comment",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .comment || " ",
                  },
                  {
                    label: "Confirm Pin",
                    name: "user_pin",
                    type: "password",
                    placeholder: "enter your user password",
                    value: this.state.user_pin,
                  },
                ]}
                submit={(data) => {
                  //console.log(data);
                  this.setState({ details: data });
                  setTimeout(() => {
                    this.repost(data);
                  }, 0);
                }}
              />
            </div>
          )}
        </Modal>
      </div>
    );
  }

  handleOnChange = (e) => {
    this.setState({ startValue: e.target.value });
    //alert(e.target.value);
  };

  handleGenerate = (data) => {
    console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    //let values = Object.values(obj);
    let fdat = obj.start;
    let ldat = obj.end;
    let sts = obj.status;
    let sdat = moment.utc(fdat).format("YYYY-MM-DD HH:mm:00");
    let edat = moment.utc(ldat).format("YYYY-MM-DD HH:mm:59");
    // let sdat = sdate.tz('Africa/Nairobi').format('YYYY-MM-DD HH:mm:ss');
    // let edat = edate.tz('Africa/Nairobi').format('YYYY-MM-DD HH:mm:ss');
    console.log(obj.start);
    console.log(obj.end);
    console.log(sts);

    // alert(sdat);
    // alert(edat);
    fetch(
      `${window.server}/utils/suspense-account?start=${sdat}&end=${edat}&status=${sts}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.token,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        let data = [];
        console.log(response.data[1]);
        response.data.map((d, i) => {
          data.push({
            id: d.id,
            "Phone No": d.msisdn,
            trans_id: d.transaction_id,
            credit: d.credit,
            debit: d.debit,
            ref_no: d.payment_ref_no,
            comment: d.comment,
            channel:
              parseFloat(d.payment_account_type) === 1 ? "Mpesa" : "System",
            status:
              parseFloat(d.status) === 0 ? "Waiting action" : "Confirmed ",
            time: moment(d.date_created).format("DD-MM-YYYY, h:mm:ss a"),
            action: (
              <div className="d-flex flex-row">
                {d.status === 0 && (
                  <button
                    onClick={() => {
                      this.setState({ reversalModal: true, activeDetails: i });
                      //console.log(this.state.response.data[0]);
                    }}
                    className="btn btn-sm btn-info px-3 btn-round"
                  >
                    Reversal
                  </button>
                )}
                {d.status === 0 && (
                  <button
                    onClick={() => {
                      this.setState({ repostModal: true, activeDetails: i });
                      //console.log(this.state.response.data[0]);
                    }}
                    className="btn btn-sm btn-primary px-3 btn-round"
                  >
                    Repost
                  </button>
                )}
              </div>
            ),
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  repost = (data) => {
    // if (
    //   !window.confirm("Are you sure that you want to Repost this transaction?")
    // )
    //   return false;
    console.log(data);
    this.setState({ modalVisible: true });
    data.new_msisdn = window.verifyNumber(data.new_msisdn);
    let postData = data;

    fetch(`${window.server}/utils/admin/repost-suspense`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
        } else {
          console.log(response);
          alert("Suspense account updated successfully");
          this.setState({ uploadSuccessful: true });
          this.setState({ repostModal: false });
          /*   console.log("generate" , this.state.details)
                this.handleGenerate(this.state.details) */
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ repostModal: false });
      });
  };
  Reversal = (data) => {
    if (
      !window.confirm("Are you sure that you want to Reverse this transaction?")
    )
      return false;

    console.log(data);
    this.setState({ modalVisible: true });

    let postData = data;
    fetch(`${window.server}/utils/admin/reversal`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
        } else {
          console.log(response);
          alert("Suspense account queued for reversal successfully");
          this.setState({ uploadSuccessful: true });
          this.setState({ reversalModal: false });
          this.handleGenerate();
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ reversalModal: false });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    let $t = this;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      $t.handleGenerate();
    }, 100);
  }
}

export default Suspense;
