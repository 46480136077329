import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import Form from "../../components/form";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import "moment-timezone";
import Nav from "../../components/Nav";

class SavingsSharesReport extends Component {
  state = {
    startValue: "",
    endValue: "",
    members: [],
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Savings share Report"
          // buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className="mt-3 border-0 card shado mx-3 shadow">
          <div className="text-center">
            <h4 className="font-weight-bold">Savings share Report </h4>
          </div>
          <div className="mt-3 align-items-center">
            <Form
              submitText={"Generate"}
              back={false}
              inputs={[
                {
                  label: "From",
                  name: "start",
                  type: "datetime-local",
                  value: this.state.startValue,
                },
                {
                  label: "To",
                  name: "end",
                  type: "datetime-local",
                  value: this.state.endValue,
                },
              ]}
              submit={(data) => {
                //console.log(data);
                //this.setState({ details: data });
                setTimeout(() => {
                  this.handleGenerate(data);
                }, 0);
              }}
            />
          </div>
        </div>

        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              // search={["firstname", "middlename", "surname", "msisdn", "id_number"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  handleOnChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleGenerate = (data) => {
    console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    let values = Object.values(obj);
    let fdat = obj.start;
    let ldat = obj.end;
    let sdat = moment.utc(fdat).format("YYYY-MM-DD HH:mm:00");
    let edat = moment.utc(ldat).format("YYYY-MM-DD HH:mm:59");
    // let sdate = moment(fdat);
    // let edate = moment(ldat);
    // let sdat = sdate.tz('Africa/Nairobi').format('YYYY-MM-DD HH:mm:00');
    // let edat = edate.tz('Africa/Nairobi').format('YYYY-MM-DD HH:mm:59');

    console.log(obj.start);
    console.log(obj.end);

    //alert(values);
    // let q = {
    //    // ...this.state.filter,
    //    ...this.state.query,
    //    "$or[0][account_type_id]": 20
    //    // date_created: values[0],
    //    // date_created: values[1]
    //  };

    //  let urlParams = Object.entries(q)
    //    .map(e => e.join("="))
    //    .join("&");

    //alert(values);
    fetch(
      `${window.server}/utils/admin/savings-share-report?start=${sdat}&end=${ldat}`,
      {
        headers: {
          Authorization: localStorage.token,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        let data = [];
        console.log("EXECUTIVE", response);
        const d = response.data;
        data.push({
          outstanding_savings: d.OutstandingSavings.outstanding_savings
            ? parseFloat(
                parseFloat(d.OutstandingSavings.outstanding_savings)
              ).toLocaleString()
            : 0,
          TotalShares: d.TotalShares.total_shares
            ? parseFloat(
                parseFloat(d.TotalShares.total_shares)
              ).toLocaleString()
            : 0,
          pending_exits: d.pending_exits.pending_exits
            ? parseFloat(
                parseFloat(d.pending_exits.pending_exits)
              ).toLocaleString()
            : 0,
        });

        console.log("data", data);

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    let $t = this;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      $t.handleGenerate();
    }, 100);
  }
}

export default SavingsSharesReport;
