/** @format */

import React, { Component } from "react";
import { Route, Link, Switch } from "react-router-dom";
import moment from "moment";
// import Details from "./clients/details";
import {
  CheckCircle,
  Briefcase,
  DollarSign,
  FilePlus,
  RefreshCw,
  XCircle,
  Bell,
  BellOff,
} from "react-feather";
import Tabs from "../../components/tabs";
import Details from "./details";
import MemberPayments from "./payments";
import Modal from "../../components/modal";
import Form from "../../components/form";

class CheckoffView extends Component {
  state = {
    currentRoute: "",
    editModal: false,
    leaveModal: false,
    smsModal: false,
    response: { data: [] },
  };
  render() {
    let user = [];

    return (
      <div className='bg-light'>
        <div className='card table-card border-0 shadow m-3 user-card'>
          <div className='text-mute pt-3 pl-3'>
            <small className='text-mute'>Checkoff > View</small>
          </div>

          <div className='profile p-3 d-md-flex flex-row align-items-center justify-content-between'>
            <div className='d-md-flex flex-row align-items-center'>
              <div className='border avatar-lg bg-light d-flex flex-row align-items-center justify-content-center'>
                <span className='initials'>
                  {this.state.cheque_no && this.state.cheque_no[0]}
                </span>
                <img
                  // src={`${window.server}${this.state.passport_path &&
                  //   this.state.passport_path.replace("public", "")}`}
                  className='avatar'
                  alt=''
                />
              </div>
              <div className='ml-md-4 my-3 my-md-0'>
                <h4 className='text-capitalize'>
                  {this.state.cheque_no === null
                    ? "--"
                    : this.state.cheque_no && this.state.cheque_no}
                </h4>
                <div>
                  {
                    [
                      <button className='btn btn-warning btn-round pc-3'>
                        Pending
                      </button>,
                      <button className='btn btn-success btn-round pc-3'>
                        Approved
                      </button>,
                      <button className='btn btn-danger btn-round pc-3'>
                        Declined
                      </button>,
                    ][this.state.cheque_status]
                  }
                </div>
                {/* <div className="ml-2 mt-1">
                  <span className="badge badge-secondary px-2">Check</span>
                </div> */}
              </div>
            </div>
            {this.state.cheque_status === 0 && (
              <div className='d-flex flex-row flex-wrap member-view '>
                <div className='d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end'>
                  <button
                    onClick={this.approveCheckoff}
                    className='option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-success btn-round mr-3'
                  >
                    <CheckCircle size={18} />
                    <span className='pl-1 font-weight-bold'>Approve</span>
                  </button>

                  <button
                    onClick={this.declineCheckoff}
                    className='option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-danger btn-round mr-3'
                  >
                    <XCircle size={18} />
                    <span className='pl-1 font-weight-bold'>Decline</span>
                  </button>
                </div>
              </div>
            )}{" "}
          </div>
          <div className='d-flex flex-row align-items-center justify-content-between'>
            {/* {this.state.msisdn && ( */}
            <Tabs
              tabs={[
                {
                  label: "DETAILS",
                  link:
                    "/check-view/details/" +
                    this.props.match.params.id +
                    "/" +
                    this.state.cheque_id,
                },
              ]}
            ></Tabs>
            {/* )} */}
          </div>
        </div>
        <Route
          path='/check-view/details/:id/:cheque_id'
          exact
          component={Details}
        />
      </div>
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    let id = this.props.match.params.id;
    console.log(id);
    fetch(`${window.server}/utils/admin/get-checkoff?id=${id}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({ ...response.data });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };

  verify(data) {
    let result = true;
    let missing = [];
    Object.keys(data).map((d) => {
      if (!data[d] || data[d] === "") {
        missing.push(d.replace("_id", "").replace(/_/g, " "));
        result = false;
      }
    });
    missing.join(", ");
    if (!result) alert("Please fill all the require fields : " + missing);
    return result;
  }

  declineCheckoff = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to decline  this cheque details?"
      )
    )
      return false;

    fetch(`${window.server}/utils/admin/decline-checkoff`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: this.props.match.params.id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
        } else {
          console.log(response);
          alert(response.result);
          window.location.reload();
          this.setState({ uploadSuccessful: true });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
      });
  };

  approveCheckoff = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to approve  this cheque details?"
      )
    )
      return false;

    fetch(`${window.server}/utils/admin/approve-checkoff`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: this.props.match.params.id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
        } else {
          console.log(response);
          alert(response.result);
          window.location.reload();
          this.setState({ uploadSuccessful: true });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
      });
  };
}

export default CheckoffView;
