import React, { Component } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController
} from "react-dates";
import { Calendar } from "react-feather";

class DatePicker extends Component {
  state = {};
  render() {
    return (
      <>
        <SingleDatePicker
          date={this.state.date} // momentPropTypes.momentObj or null
          onDateChange={date => {
            if (this.props.onChange) this.props.onChange(date);
            this.setState({ date });
          }} // PropTypes.func.isRequired
          focused={this.state.focused} // PropTypes.bool
          onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
          id="startdate" // PropTypes.string.isRequired,
          isOutsideRange={() => false}
          isInsideRange={() => true}
          placeholder={this.props.placeholder}
        />{" "}
      </>
    );
  }
}

export default DatePicker;
