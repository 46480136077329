import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import {
  Plus,
  Target,
  Circle,
  Smartphone,
  CheckCircle,
  XCircle,
} from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import Form from "../../components/form";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import "moment-timezone";
import Nav from "../../components/Nav";

class LoanPortfolioReport extends Component {
  state = {
    startValue: "",
    endValue: "",
    members: [],
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    start_date: "",
    end_date: "",
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Loan Classification Report"
          // buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className="mt-3 border-0 card shado mx-3 shadow">
          <div className="text-center">
            <h4 className="font-weight-bold">Loan Classification Report </h4>
            <br></br>
          </div>
          <div className="mt-3 align-items-center">
            <Form
              submitText={"Generate"}
              back={false}
              inputs={[
                {
                  label: "From",
                  name: "start",
                  type: "datetime-local",
                  value: this.state.startValue,
                },
                {
                  label: "To",
                  name: "end",
                  type: "datetime-local",
                  value: this.state.endValue,
                },
              ]}
              submit={(data) => {
                //console.log(data);
                this.setState({ details: data });
                setTimeout(() => {
                  this.handleGenerate(data);
                }, 0);
              }}
            />
          </div>
          {/* <div>
              <button className="btn btn-primary btn-round px-5 " onClick = {() => 
                this.queryTable("")
              }>
                      Extract report
              </button>
              </div> */}
        </div>

        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              // search={["firstname", "middlename", "surname", "msisdn", "id_number"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
        <Modal
          visible={this.state.modalVisible}
          dismiss={false}
          close={() => this.setState({ modalVisible: false })}
        >
          <div>
            <div className="d-flex flex-row align-items-center">
              {this.state.uploadSuccessful ? (
                <CheckCircle color="#4caf50" size={64} />
              ) : (
                <div className="lds-spinner">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              )}
              <div className="ml-5 text-dark">
                {!this.state.uploadSuccessful ? (
                  <h3>Generating report ...</h3>
                ) : (
                  <h3>
                    <button
                      className="btn btn-primary mt-4 px-5"
                      onClick={() =>
                        this.setState(
                          {
                            modalVisible: false,
                            uploadSuccessful: null,
                            modalVisible2: true,
                          },
                          () => {
                            //window.location.reload();
                            this.setState({ uploadSuccessful: false });
                            console.log("start", moment());
                            setTimeout(() => {
                              console.log("end", moment());
                              this.queryTable("");
                            }, 3000);
                          }
                        )
                      }
                    >
                      Done . Click to Extract
                    </button>
                  </h3>
                )}
              </div>
            </div>
            {this.state.generationSuccessful && (
              <div className="text-center">
                <button
                  className="btn btn-primary mt-4 px-5"
                  onClick={() =>
                    this.setState({ modalVisible: false }, function () {
                      //window.location.reload();
                      alert("done  you can now extract the report ");
                    })
                  }
                >
                  Done
                </button>
              </div>
            )}
          </div>
        </Modal>
        <Modal
          visible={this.state.modalVisible2}
          dismiss={false}
          close={() => this.setState({ modalVisible2: false })}
        >
          <div>
            <div className="d-flex flex-row align-items-center">
              <div className="lds-spinner">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
              <div className="ml-5 text-dark">
                <h3>Waiting ...</h3>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  handleOnChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleGenerate = (data) => {
    console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    let values = Object.values(obj);
    let fdat = obj.start;
    let ldat = obj.end;
    this.setState({ start_date: fdat, end_date: ldat });
    let sdat = moment.utc(fdat).format("YYYY-MM-DD HH:mm:00");
    let edat = moment.utc(ldat).format("YYYY-MM-DD HH:mm:59");
    this.setState({ start_date: sdat, end_date: edat });
    console.log(obj.start);
    console.log(obj.end);
    this.setState({ modalVisible: true });
    //fetch(`${window.server}/utils/admin/loan-portfolio-report?start=${sdat}&end=${ldat}`, {
    fetch(
      `${window.server}/utils/admin/initiate-store-procedure?start=${sdat}&end=${edat}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.token,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("got to tim put ");
        /* setTimeout(this.queryTable(""),
                    30000); */
        setTimeout(this.setState({ uploadSuccessful: true }), 3000);
        console.log("timeout done ");
      })
      .catch((d) => {
        // this.setState({ generationSuccessful: true });
        setTimeout(this.setState({ uploadSuccessful: true }), 3000);
        console.log("timeout done ");
        //console.log(d);
      });
  };
  queryTable = (data) => {
    let fdat = this.state.start_date;
    let ldat = this.state.end_date;
    let sdat = moment.utc(fdat).format("YYYY-MM-DD HH:mm:00");
    let edat = moment.utc(ldat).format("YYYY-MM-DD HH:mm:59");
    // let sdate = moment(fdat);

    console.log("www", sdat, "rrr", ldat);
    fetch(
      `${window.server}/utils/admin/extended-portfolio-report?start=${sdat}&end=${ldat}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.token,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        let data = [];
        //console.log("dts", response);
        response.data.map((d, i) => {
          data.push({
            Loan_Number: d.Loan_Number,
            Member_Number: d.Member_Number,
            Member_Name: d.Member_Name,
            Member_Number: d.Member_Number,
            Employer_Name: d.Employer_Name,
            Employer_Number: d.Employer_Number,
            Contact: d.Contact,
            Loan_Type: d.Loan_Type,
            Loan_Amortization_Type: d.Loan_Amortization_Type,
            Loan_Installments: parseFloat(
              parseFloat(d.Loan_Installments)
            ).toLocaleString(),
            "Loan_Interest_Rate(Per_Month) ": parseFloat(
              parseFloat(d["Loan_Interest_Rate(Per_Month) "])
            ).toLocaleString(),

            loan_balance: parseFloat(
              parseFloat(d.loan_balance)
            ).toLocaleString(),

            /* loan_balance: parseFloat(
              parseFloat(d.loan_balance)
            ).toLocaleString(), */

            Loan_Frequency: d.Loan_Frequency,

            Disbursement_Date: d.Disbursement_Date,
            Loan_Amount: parseFloat(parseFloat(d.Loan_Amount)).toLocaleString(),
            FinBal: d.FinBal,
            Days_in_Arrears: d.Days_in_Arrears,
            Loan_Classification: d.Loan_Classification,
          });
        });

        let dts = {};
        dts.data = data;
        console.log("dts", dts);
        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
          uploadSuccessful: null,
          modalVisible2: false,
        });
      })
      .catch((d) => {
        this.setState({ uploadSuccessful: false });
        this.setState({ tableError: true });
        console.log("errpr", d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    let $t = this;

    /* clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      $t.handleGenerate();
    }, 100); */
  }
}

export default LoanPortfolioReport;
