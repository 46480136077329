/** @format */

import React, { Component } from "react";
import moment from "moment";
import Form from "../../components/form";
import { DollarSign, User, LogOut } from "react-feather";
import Table from "../../components/Table";
import Filter from "../../components/filter";

class Details extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className='p-3'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='mt-3 table-card  border-0 card shado mx-3 shadow'>
              <div className='p-3 bg-light font-weight-bold'>
                <User size={18}></User> Cheque Usage
              </div>
              <div className='p-4'>
                <Table
                  search={["full_name"]}
                  // sort="created_at"
                  // sortDirection={-1}
                  data={this.state.tableData}
                  fetch={(params) => {
                    this.setState({ query: params });
                  }}
                  loading={this.state.table_loading}
                  fetchError={this.state.tableError}
                />
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='mt-3 table-card  border-0 card shado mx-3 shadow'>
              <div className='p-3 bg-light font-weight-bold'>
                <User size={18}></User> Cheque Images
              </div>
              <div className=''>
                <img
                  src={`${window.server}${
                    this.state.cheque_path &&
                    this.state.cheque_path.replace("public", "")
                  }`}
                  alt='image'
                  height='300px'
                  width='400px'
                />
              </div>
              <div className=''>
                <img
                  src={`${window.server}${
                    this.state.reciept_path &&
                    this.state.reciept_path.replace("public", "")
                  }`}
                  alt='image'
                  height='300px'
                  width='400px'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    this.setState({ table_loading: true });
    let cheque_id = this.props.match.params.cheque_id;
    let id = this.props.match.params.id;
    console.log(id);
    fetch(
      `${window.server}/utils/admin/users-checkoff?cheque_id=${cheque_id}`,
      {
        headers: {
          Authorization: localStorage.token,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        let data = [];
        response.data.map((d, i) => {
          data.push({
            // id: d.id,
            full_name: d.full_name,
            "Phone Number": d.msisdn,
            amount: parseFloat(d.user_amount).toLocaleString(),
            date_created: moment
              .utc(d.date_created)
              .format("Do MMMM YYYY, h:mm:ss a"),
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });

    fetch(`${window.server}/utils/admin/get-checkoff?id=${id}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.setState({ ...response.data });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };
}

export default Details;
