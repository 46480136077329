/** @format */

import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";

import _403 from "./img/403.png";
import _500 from "./img/500.jpg";
import {
  progressBarFetch,
  setOriginalFetch,
  ProgressBar,
} from "react-fetch-progressbar";
import Access from "./components/accessManager";
import Menu from "./components/Menu";
import config from "./config.js";
import Alert from "./components/alert";
import Members from "./pages/members/members";
import MembersView from "./pages/members/membersView";
import stamp from "./img/dark_logo.png";
import { LogOut, Info } from "react-feather";
import memberAdd from "./pages/members/memberAdd";
import LoanSummaries from "./pages/loans/loanSummaries";
import LoanAdd from "./pages/loans/loanAdd";
import Loans from "./pages/loans/loans";
import Employees from "./pages/employees/employees";
import EmployeeAdd from "./pages/employees/employeeAdd";
import LoanRequests from "./pages/loans/loanRequests";
import loanView from "./pages/loans/loansView";
import LoanApprove from "./pages/loans/loanApprove";
import TrialBalance from "./pages/finance/trialBalance";
import BalanceSheet from "./pages/finance/balanceSheet";
import IncomeStatement from "./pages/finance/incomeStatement";
import CashFlowStatement from "./pages/finance/cashFlowStatement";
import Payments from "./pages/finance/payments";
import Petty from "./pages/finance/petty";
import PettyRecords from "./pages/finance/pettyRecords";
import MpesaDeposits from "./pages/finance/mpesaDeposits";
import Disbursements from "./pages/finance/disbursements";
import SMS from "./pages/sms/sms";
import SMSCreate from "./pages/sms/smsCreate";
import AddressBook from "./pages/sms/addressBooks";
import AddressBookCreate from "./pages/sms/addressBookCreate";
import MessageTemplate from "./pages/sms/messageTemplate";
import Savings from "./pages/finance/savings";
import Shares from "./pages/finance/shares";
import LoanProducts from "./pages/loans/loanProducts";
import SMSList from "./pages/sms/smsList";
import Suspense from "./pages/finance/suspense";
import LoanProductCreate from "./pages/loans/loanProductCreate";
import AccountsChart from "./pages/finance/accountsChart";
import Vendors from "./pages/finance/vendors";
import Invoices from "./pages/finance/invoices";
import MembersPending from "./pages/members/membersPending";
import MemberApprove from "./pages/members/memberApprove";
import Groups from "./pages/groups";
import Roles from "./pages/roles";
import SuspendedMembers from "./pages/members/suspendedMembers";
import BlockedMembers from "./pages/members/blockedMembers";
import ExitMembers from "./pages/members/exitMembers";
import DepositsRecon from "./pages/finance/depositsRecon";
import WithdrawalRecon from "./pages/finance/withdrawalRecon";
import AccountsReport from "./pages/finance/accountsReport";
import RecoveryReport from "./pages/reports/recoveryReport";
import AllMembers from "./pages/members/allMembers";
import Summaries from "./pages/summaries";
import ApproveDepositsRecon from "./pages/finance/approveDepositsRecon";
import RegistrationReport from "./pages/reports/registrationReport";
import SharesReport from "./pages/reports/sharesReport";
import SavingsReport from "./pages/reports/savingsReport";
import MpesaReport from "./pages/reports/mpesaReport";
import CommissionsReport from "./pages/reports/commissionsReport";
import WithdrawalReport from "./pages/reports/withdrawalReport";
import ScheduleReport from "./pages/reports/scheduleReport";
import PrincipalReport from "./pages/reports/principalReport";
import InterestReport from "./pages/reports/interestReport";
import SmsReport from "./pages/reports/smsReport";
import ExecutiveReport from "./pages/reports/execReport";
import InterestRepaid from "./pages/reports/interestRepaid";
import PrincipalRepaid from "./pages/reports/principalRepaid";
import RecoverySummary from "./pages/reports/recoverSummary";
import ApproveSuspense from "./pages/finance/approveSuspense";
import AuditTrail from "./pages/settings/audit";
import ChangePassword from "./pages/settings/password";
import ManageView from "./pages/access/manage";
import EmployeesView from "./pages/employees/employeeView";
import DefaultedLoans from "./pages/reports/defaultedReport";
import CheckOff from "./pages/finance/checkoff";
import CheckOffApproval from "./pages/finance/checkoffApproval";
import ReconciliationStatus from "./pages/finance/reconciliationStatus";
import CheckoffView from "./pages/finance/checkoffView";
import linkedAccounts from "./pages/members/linkedAccounts";
import OTP from "./pages/otp";
import AgeingReport from "./pages/reports/ageingReport";
import DepositsReturnReport from "./pages/reports/depositReturn";
import SavingsShareReport from "./pages/reports/savingsShareReport";
import loanPortfolioReportSummary from "./pages/reports/loanPortfolioReportSummary";
import LoanPortfolioReport from "./pages/reports/loanPortfolioReport";
import LoanReport from "./pages/reports/loanStandingReport";
import Dashboard from "./pages/Dashboard";
import ExtendedSharesReport from "./pages/reports/expoundedShareReport";
import ExtendedSavingsReport from "./pages/reports/expoundedSavingsReport";
import ChairmansReport from "./pages/reports/chairmansReport";
import LoanClassificationReport from "./pages/reports/loanClassificationReport";
import Marketing from "./pages/marketing/marketing";
import DefaultedLoansExtended from "./pages/reports/defaultedReportExtended";
import SpecialActions from "./pages/actions/specialActions";
import LoanInterest from "./pages/loans/loansInterest";
import LoanRollover from "./pages/loans/loansRollover";
//special loans
import SpecialLoansDetails from "./pages/loans/specialLoans/loans";
import SpecialLoansGuarantors from "./pages/loans/specialLoans/guarantors";
import SpecialLoansApproval from "./pages/loans/specialLoans/loanApprove";
import SpecialLoansRequests from "./pages/loans/specialLoans/loanRequests";
import SpecialLoansInterest from "./pages/loans/specialLoans/loansInterest";
import SpecialLoansTransactions from "./pages/loans/specialLoans/transactions";
import Agents from "./pages/agents/agents";
import SasraSavingsReport from "./pages/sasra/savingsReport";
import MobileLoans from "./pages/loans/mobileLoans";
import ShareConversion from "./pages/reports/shareConversionReport";
import LoanRecoveryGuarantor from "./pages/reports/loanRecoveryGuarantor";

import OldInterestAccount from "./pages/old/InterestAccount";
import OldLoanAccount from "./pages/old/LoanAccount";
import OldLoanRecords from "./pages/old/LoanRecords";
import OldMemberAccounts from "./pages/old/MemberAccounts";
import OldQueuedInterest from "./pages/old/QueuedInterest";
import OldDeposits from "./pages/old/Deposits";
import OldSavingsAccount from "./pages/old/SavingsAccount";
//import ManageRoles from "./pages/settings/manageRoles";
// import MemberTransactions from "./pages/members/transactions"

// import Petty from "./pages/finance/petty";
// import Payments from "./pages/finance/payments";

// Configs
window.server = config.server_url;

// create a fetch that will no trigger the progreebar in the background
window.silentFetch = fetch;

// check token validity
let checkTokenValidity = () => {
  window
    .silentFetch(`${window.server}/utils/auth`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
    .then((response) => response.json())
    .then((response) => {
      // console.log(response);
      if (response.code === 401) {
        window.logout(true);
        setTimeout(() => {
          alert("Your Session has expired");
        }, 0);
      } else {
        setTimeout(() => {
          // console.log("checking validity");
          if (localStorage.token) {
            checkTokenValidity();
          }
        }, 30000);
      }
    })
    .catch((d) => {
      console.log(d);
    });
};

window.logout = (force) => {
  let logout = () => {
    let path = window.location.pathname;
    localStorage.clear();
    localStorage.previousPath = path;
    window.location = "/login";
  };

  if (force) {
    logout();
    return false;
  }

  window.alert2.confirm({
    icon: <Info size={70}></Info>,
    title: "Are you sure that you want to Logout ?",
    message: "If you agree your session will be terminated",
    confirmText: "Log Out",
    onSubmit: () => {
      logout();
    },
  });

  // window.alert2.onConfirm = () => {
  //   logout();
  // };

  // let path = window.location.pathname;
  // localStorage.clear();
  // localStorage.previousPath = path;
  // window.location = "/login";
};

// set Progress Bar
setOriginalFetch(window.fetch);
window.standardFetch = progressBarFetch;

// set app globals

// end Configs

// check if logged in
if (localStorage.token && localStorage.user) {
  window.user = JSON.parse(localStorage.user);
  //console.log(window.user.user);
  window.user.group = window.user.user;
} else {
}

class App extends Component {
  state = {
    loaded: false,
    status: 0,
    errorload: false,
    blur: false,
    authenticated: false,
  };

  render() {
    return (
      <>
        <div className={!this.state.blur || "blur-alert "}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/otp" component={OTP} />
            {(this.state.loaded || !localStorage.token) && (
              <Route path="/" component={Portal} />
            )}

            {/* {this.state.authenticated && (
             <Route path="/" component={Portal} />
            )} */}
            {this.getStatus(this.state.status, this.state.errorload)}
          </Switch>
        </div>
        <Alert
          onRef={(ref) => {
            window.alert2 = ref;
          }}
          toggle={(blur) => {
            this.setState({ blur });
          }}
        ></Alert>
      </>
    );
  }

  componentDidMount = () => {
    this.get("products", (response) => {
      console.log(response);
      window.products = response.data;
      window.productsList = {};
      response.data.map((d) => {
        window.productsList[d.product_code] = d.product_name;
      });
    });
    if (window.user) {
      this.get(
        `admin-group-roles?group_id=${
          window.user && window.user.group.group_id
        }&$skip=0&$limit=1000`,
        (response) => {
          let roles = {};
          response.data.map((d) => {
            if (!roles[d.role.name]) roles[d.role.name] = [];
            roles[d.role.name].push(d.group.id);
          });
          console.log("rolessss", roles);
          window.roles = roles;
        }
      );
    }
    this.get(`admin-groups?$skip=0&$limit=1000`, (response) => {
      window.groups = response.data;
      console.log("window.groups ", window.groups);
    });

    // let roles = {};

    // roles = { CUSTOMER_READ: [1], CUSTOMER_CREATE: [1] };

    // window.roles = roles;

    this.checkStatus();
  };

  checkStatus = () => {
    this.setState({ loaded: true, errorload: true });
  };

  checkStatus = () => {
    if (window.roles && window.groups)
      this.setState({ loaded: true, errorload: true });
  };

  get = (url, callback) => {
    fetch(`${window.server}/${url}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response.code === 401) {
          localStorage.clear();
          this.props.history.push("/login");
        }
        if (response.code) {
          this.setState({ status: response.code, errorload: true });
        }
        callback(response);
        this.checkStatus();
      })
      .catch((d) => {
        console.log(d);
      });
  };

  inactivityTime = function () {
    let time;
    let logout = () => {
      if (localStorage.token) {
        localStorage.clear();
        window.location = "/login";
      }
    };

    let resetTimer = () => {
      clearTimeout(time);
      time = setTimeout(window.logout, 900);
    };

    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
  };

  getStatus = () => {
    if (!this.state.errorload)
      return (
        <div className="loader h-100 w-100 d-flex flex-row align-items-center justify-content-center show-loader">
          <div className="lds-roller">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      );

    if (this.state.status !== 0 && this.state.status !== 0) {
      if (this.state.status === 403) {
        return (
          <div className="loader h-100 w-100 d-flex flex-row align-items-center justify-content-center show-loader">
            <img src={_403} alt="" />
          </div>
        );
      } else {
        return (
          <div className="loader h-100 w-100 d-flex flex-row align-items-center justify-content-center show-loader">
            <img src={_500} alt="" />
          </div>
        );
      }
    }
  };
}

class Portal extends Component {
  state = { showMenu: false };
  render() {
    if (typeof localStorage.token === "undefined") {
      this.props.history.push("/login");
    } else if (
      window.user.authentication.payload !== undefined &&
      localStorage.otp_verified != 2
    ) {
      this.props.history.push("/otp");
    } else if (window.user.user.is_active == 0) {
      this.props.history.push("/login");
      setTimeout(() => {
        window.alert2.notify({
          title: "Your Account has been set to in-active",
          description: "Your Account has been set to in-active",
          color: "material-deep-orange",
          autoClose: true,
        });
      }, 2000);
    }
    return (
      <>
        <div id="wrapper" className="pt-5 mt-md-0 pt-md-0 mt-2">
          <Menu></Menu>
          <div id="content-wrapper" className="d-flex flex-column bg-light">
            <div className="preloader-container w-100">
              <ProgressBar />
            </div>
            <div className="d-flex flex-fill flex-column">
              <Route path="/" exact component={Home} />
              <Route
                path="/chairmansReport"
                exact
                component={ChairmansReport}
              />
              <Route
                path="/loanClassificationReport"
                exact
                component={LoanClassificationReport}
              />
              <Route
                path="/old/InterestAccount"
                exact
                component={OldInterestAccount}
              />
              <Route path="/old/LoanAccount" exact component={OldLoanAccount} />
              <Route path="/old/LoanRecords" exact component={OldLoanRecords} />
              <Route
                path="/old/MemberAccounts"
                exact
                component={OldMemberAccounts}
              />
              <Route
                path="/old/QueuedInterestAccount"
                exact
                component={OldQueuedInterest}
              />
              <Route path="/old/Deposits" exact component={OldDeposits} />
              <Route
                path="/old/SavingsAccount"
                exact
                component={OldSavingsAccount}
              />
              <Route path="/dashboard" exact component={Dashboard} />
              <Route path="/members" exact component={Members} />
              <Route path="/members-pending" exact component={MembersPending} />
              <Route
                path={[
                  "/member-view/:path/:customer_id",
                  "/member-view/:path/:customer_id/:msisdn",
                ]}
                exact
                component={MembersView}
              />
              <Route
                path={[
                  "/employee-view/:path/:id",
                  "/employee-view/:path/:id/:msisdn",
                ]}
                exact
                component={EmployeesView}
              />
              <Route path="/member-add/:step" exact component={memberAdd} />
              <Route path="/loan-summarries" exact component={LoanSummaries} />
              <Route path="/loans/:status" exact component={Loans} />
              <Route path="/loans" exact component={Loans} />
              <Route path="/loan-interest" exact component={LoanInterest} />
              <Route path="/loan-roll-over" exact component={LoanRollover} />
              <Route path="/loan-requests" exact component={LoanRequests} />
              <Route path="/loan-add/:step/:id" exact component={LoanAdd} />
              <Route path="/loan-approve/:id" exact component={LoanApprove} />
              <Route path="/loan-view/:path/:id" exact component={loanView} />
              <Route path="/employees" exact component={Employees} />
              <Route path="/employee-add" exact component={EmployeeAdd} />
              <Route path="/trial-balance" exact component={TrialBalance} />
              <Route path="/balance-sheet" exact component={BalanceSheet} />
              <Route path="/payments" exact component={Payments} />
              <Route path="/petty" exact component={Petty} />
              <Route path="/petty-records" exact component={PettyRecords} />
              <Route path="/mpesa-deposits" exact component={MpesaDeposits} />
              <Route path="/disbursements" exact component={Disbursements} />
              <Route path="/marketing" exact component={Marketing} />
              <Route path="/sms" exact component={SMS} />
              <Route path="/sms-create" exact component={SMSCreate} />
              <Route path="/address-books" exact component={AddressBook} />
              <Route path="/sms-template" exact component={MessageTemplate} />
              <Route
                path="/reconciliation-status"
                exact
                component={ReconciliationStatus}
              />
              <Route
                path="/address-book-create/:step"
                exact
                component={AddressBookCreate}
              />
              <Route
                path="/income-statement"
                exact
                component={IncomeStatement}
              />
              <Route
                path="/cash-flow-statement"
                exact
                component={CashFlowStatement}
              />
              <Route path="/savings" exact component={Savings} />
              <Route
                path="/suspended-members"
                exact
                component={SuspendedMembers}
              />
              <Route path="/link-accounts" exact component={linkedAccounts} />
              <Route path="/blocked-members" exact component={BlockedMembers} />
              <Route path="/exit-members" exact component={ExitMembers} />
              <Route path="/shares" exact component={Shares} />
              <Route path="/loan-products" exact component={LoanProducts} />
              <Route path="/sms-list" exact component={SMSList} />
              <Route path="/suspense" exact component={Suspense} />
              <Route path="/accounts-chart" exact component={AccountsChart} />
              <Route
                path="/product-create"
                exact
                component={LoanProductCreate}
              />
              <Route path="/vendors" exact component={Vendors} />
              <Route path="/invoices" exact component={Invoices} />
              <Route path="/deposits-c2b" exact component={DepositsRecon} />
              <Route path="/withdraws-b2c" exact component={WithdrawalRecon} />
              <Route
                path="/approve-deposits"
                exact
                component={ApproveDepositsRecon}
              />
              <Route path="/accounts" exact component={AccountsReport} />
              <Route path="/recoveries" exact component={RecoveryReport} />
              <Route path="/all-members" exact component={AllMembers} />
              <Route path="/summaries" exact component={Summaries} />
              <Route
                path="/registration-report"
                exact
                component={RegistrationReport}
              />
              <Route path="/shares-report" exact component={SharesReport} />
              <Route path="/savings-report" exact component={SavingsReport} />
              <Route
                path="/loan-ageing-report"
                exact
                component={AgeingReport}
              />
              <Route
                path="/savings-share-report"
                exact
                component={SavingsShareReport}
              />
              <Route
                path="/loan-portfolio-report"
                exact
                component={LoanPortfolioReport}
              />
              <Route
                path="/loan-portfolio-report-summary"
                exact
                component={loanPortfolioReportSummary}
              />
              <Route
                path="/deposit-return-report"
                exact
                component={DepositsReturnReport}
              />
              <Route
                path="/extended-savings-report"
                exact
                component={ExtendedSavingsReport}
              />
              <Route
                path="/extended-shares-report"
                exact
                component={ExtendedSharesReport}
              />
              <Route path="/mpesa-report" exact component={MpesaReport} />
              <Route path="/loan-report" exact component={LoanReport} />
              <Route
                path="/commissions-report"
                exact
                component={CommissionsReport}
              />
              <Route
                path="/withdrawal-report"
                exact
                component={WithdrawalReport}
              />
              <Route path="/schedule-report" exact component={ScheduleReport} />
              <Route
                path="/principal-repaid-report"
                exact
                component={PrincipalReport}
              />
              <Route
                path="/interest-repaid-report"
                exact
                component={InterestReport}
              />
              <Route path="/sms-report" exact component={SmsReport} />
              <Route
                path="/executive-summary"
                exact
                component={ExecutiveReport}
              />
              <Route
                path="/interest-repaid-summary"
                exact
                component={InterestRepaid}
              />
              <Route
                path="/principal-repaid-summary"
                exact
                component={PrincipalRepaid}
              />
              <Route
                path="/recoveries-summary"
                exact
                component={RecoverySummary}
              />
              <Route
                path="/approve-suspense"
                exact
                component={ApproveSuspense}
              />
              <Route path="/password" exact component={ChangePassword} />
              <Route path="/audit-trail" exact component={AuditTrail} />
              <Route path="/defaulted-loans" exact component={DefaultedLoans} />
              <Route
                path="/defaulted-loans-extended"
                exact
                component={DefaultedLoansExtended}
              />
              <Route path="/checkoff" exact component={CheckOff} />
              <Route
                path="/checkoff-approval"
                exact
                component={CheckOffApproval}
              />
              <Route
                path={[
                  "/check-view/:path/:id",
                  "/check-view/:path/:id/:cheque_id",
                ]}
                exact
                component={CheckoffView}
              />
              <Route path={["/manage-view/:id"]} exact component={ManageView} />
              <Route
                path="/member-approve/:id"
                exact
                component={MemberApprove}
              />
              {/* <Route path="/petty" exact component={Petty} />
              <Route path="/payments" exact component={Payments} /> */}
              <Route path="/roles" exact component={Roles} />{" "}
              <Route path="/groups" exact component={Groups} />
              <Route path="/special-actions" exact component={SpecialActions} />
              {/* special loans */}
              <Route
                path="/special-loans"
                exact
                component={SpecialLoansDetails}
              />
              <Route
                path="/special-loan-interest"
                exact
                component={SpecialLoansInterest}
              />
              <Route
                path="/special-loan-requests"
                exact
                component={SpecialLoansRequests}
              />
              <Route
                path="/special-loan-guarantors"
                exact
                component={SpecialLoansGuarantors}
              />
              <Route
                path="/special-loan-approve/:id"
                exact
                component={SpecialLoansApproval}
              />
              <Route
                path="/special-loan-transactions"
                exact
                component={SpecialLoansTransactions}
              />
              <Route path="/agent_details" exact component={Agents} />
              <Route
                path="/sasraSavingsReport"
                exact
                component={SasraSavingsReport}
              />
              <Route
                path="/mobile-loan-requests"
                exact
                component={MobileLoans}
              />
              <Route
                path="/share-conversion"
                exact
                component={ShareConversion}
              />
              <Route
                path="/loan-recovery-guarantor"
                exact
                component={LoanRecoveryGuarantor}
              />
            </div>
            <img src={stamp} className="stamp" alt="" />
          </div>
        </div>
      </>
    );
  }
  componentDidMount() {
    if (localStorage.token) {
      checkTokenValidity();
    }
  }
}

window.verifyNumber = (n) => {
  n = n + "";
  if (n[0] + n[1] + n[2] === "254") {
    return parseInt(n);
  } else {
    return parseInt("254" + parseInt(n));
  }
};

window.fetch = (url, options) => {
  return new Promise((resolve, reject) => {
    window
      .standardFetch(url, options)
      .then((response) => {
        resolve(response);
        return response.clone().json();
      })
      .then((response) => {
        // console.log(response);
        // response.json = () => {
        //   return response;
        // };
        // resolve(response);

        if (response.code === 401 && localStorage.token) {
          window.alert2.notify({
            title: "Your Session has Expired",
            description: "Please Login once more to continue using the system",
            color: "material-deep-orange",
            autoClose: false,
            onClose: () => {
              window.logout(true);
            },
          });
        }
      })
      .catch((error) => {
        // window.alert2.notify("Your session has expired");
        reject(error);

        // window.alert2.notify({
        //   title: "Connection error",
        //   description:
        //     "There seems like there's a connection problem, please try again later",
        //   color: "material-deep-orange",
        //   autoClose: false,
        //   onClose: () => {
        //     window.logout(true);
        //   }
        // });

        // console.log(error);
        reject(error);
      });
  });
};

export default withRouter(App);
