/** @format */

import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class AllMembers extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };

  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='All Active Members'
          // buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className='mt-3 table-card  border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Table
              //search={["firstname", "middlename", "surname"]}
              sort='id'
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchAllMembers = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    fetch(`${window.server}/entity-accounts?${urlParams}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        let data = [];
        response.data.map((d, i) => {
          data.push({
            id: d.customer_id,
            full_name:
              d.customer === null
                ? "--"
                : `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`,
            phone_number: d.msisdn.msisdn,
            iD_number: d.details.id_number,
            // gender: { M: "Male", F: "Female" }[d.details.gender],
            available_balance: parseFloat(
              parseFloat(d.wallet.available_balance)
            ).toLocaleString(),
            actual_balance: parseFloat(
              parseFloat(d.wallet.actual_balance)
            ).toLocaleString(),
            date_of_registration:
              d.customer === null
                ? "--"
                : moment(d.customer.created_at).format("Do MMMM YYYY"),
            pledge_amount:
              d.customer === null
                ? "--"
                : parseFloat(parseFloat(d.customer.pledge)).toLocaleString(),
            // identity_type: d.identity_type,
            // sub_county_id: d.sub_county_id,
            // ward_id: d.ward_id,
            // kyc_verification: (
            //   <button className="btn btn-success btn-round pc-3">
            //     Verified
            //   </button>
            // ),
            action: (
              <div className='d-flex flex-row'>
                <Link
                  to={"/member-view/details/" + d.customer_id}
                  className='btn btn-sm btn-primary px-3 btn-round'
                >
                  View
                </Link>
              </div>
            ),
          });
        });

        let dts = {};
        dts.data = data;
        console.log("reponse", response , "dts", dts)
        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchAllMembers();
      }, 100);
    }
  }
}

export default AllMembers;
