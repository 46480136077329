import React, { Component } from "react";
import Modal from "../../components/modal";
import { CheckCircle, XCircle } from "react-feather";
import Nav from "../../components/Nav";

class WithdrawalRecon extends Component {
  state = {};
  render() {
    return (
      <div className="">
        <Nav
          name="Withdraws/B2C"
          // buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow mb-5">
          <div className="p-md-4 p-3">
            <div className="row mt-5">
              <div className="col-md-6">
                <div class="form-group">
                  <label for="fileInput1">
                    Upload an M-PESA statement file
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="fileInput1"
                    aria-describedby="emailHelp"
                    placeholder="Enter file"
                    onChange={(e) => this.setState({ file: e.target.files[0] })}
                  />
                  <button
                    className="btn btn-primary mt-5 px-5"
                    disabled={!this.state.file}
                    onClick={this.reconcile}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          visible={this.state.modalVisible}
          dismiss={false}
          close={() => this.setState({ modalVisible: false })}
        >
          <div>
            <div className="d-flex flex-row align-items-center">
              {this.state.uploadSuccessful ? (
                <CheckCircle color="#4caf50" size={64} />
              ) : (
                <div className="lds-spinner">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              )}
              <div className="ml-5 text-dark">
                {!this.state.uploadSuccessful ? (
                  <h3>Uploading ...</h3>
                ) : (
                  <h3>{this.state.message}</h3>
                )}
              </div>
            </div>
            {this.state.uploadSuccessful && (
              <div className="text-center">
                <button
                  className="btn btn-primary mt-4 px-5"
                  onClick={() =>
                    this.setState({ modalVisible: false }, function () {
                      window.location.reload();
                    })
                  }
                >
                  Done
                </button>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }

  reconcile = () => {
    let formData = new FormData();

    formData.append("withdrawal", this.state.file);
    
    this.setState({ modalVisible: true });
    fetch(`${window.server}/utils/admin/upload-withdrawals`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        // "Content-Type": "application/json"
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("bayo", response);

        if (response.status == "201") {
          this.setState({ uploadSuccessful: true, message: response.result });
          // alert(
          //   response.result +
          //   " \n " +
          //   (response.errors[0] ? response.errors[0].result : "")
          // );
          // this.setState({ modalVisible: false });
        } else {
          window.alert2.show({
            loader: false,
            icon: <XCircle size={60}></XCircle>,
            title: 'Error ' + response.code,
            buttons: true,
            message:
              response.message +
              ' \n ' +
              (response.errors[0] ? response.errors[0].message : '')
          });

          this.setState({ modalVisible: false });
          //  window.location.reload();
        }
      })
      .catch((d) => {
        //console.log("Error saving the data");
        //console.log(d);
        this.setState({ modalVisible: false });
      });
  };
}

export default WithdrawalRecon;