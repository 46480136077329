/** @format */

import React, { Component } from "react";
import Table from "../../components/Table";
import moment from "moment";
import Nav from "../../components/Nav";
import Form from "../../components/form";
import Modal from "../../components/modal";
import { Plus, Target, Circle, Edit2, Smartphone } from "react-feather";
class Agents extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    cancelModal: false,
    ApprovalModal: false,
    searchCondition: 0,
    PhotoModal: false,
    active_photo: null,
    moreModal: false,
    activeData: null,
  };
  timeout = null;
  render() {
    return (
      <>
        <div className="">
          <Nav name="Agents"></Nav>
          <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
            <div className="p-4">
              <div>
                <select
                  placeholder="..."
                  class="form-control bg-light sms-input"
                  required=""
                  onChange={(e) => {
                    this.setState({
                      searchCondition: parseInt(e.target.value),
                    });
                    setTimeout(() => {
                      this.fetchAgents();
                    }, 1000);
                  }}
                >
                  <option value="0">Filter By Status</option>
                  <option value="0">Waiting Review</option>
                  <option value="1">Waiting Approval</option>
                  <option value="2">Approved</option>
                  <option value="3">Cancelled</option>
                </select>
              </div>
              <Table
                search={["national_id", "msisdn"]}
                sort="id"
                sortDirection={-1}
                data={this.state.tableData}
                fetch={(params) => {
                  this.setState({ query: params });
                }}
                loading={this.state.table_loading}
                fetchError={this.state.tableError}
              />
            </div>
          </div>
        </div>

        <Modal
          visible={this.state.cancelModal}
          close={() => this.setState({ cancelModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Disapprove Registration</h5>
          </div>
        </Modal>
        <Modal
          visible={this.state.ApprovalModal}
          close={() => this.setState({ ApprovalModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Approve Registration</h5>
          </div>
        </Modal>
        {this.state.active_photo && (
          <Modal
            visible={this.state.PhotoModal}
            close={() => this.setState({ PhotoModal: false })}
          >
            <div className="d-flex flex-row align-items-center">
              <Edit2 className="mr-3"></Edit2>
              <h5 className="m-0 mt-1">{this.state.active_photo.name}</h5>
            </div>
            <div className="mt-3">
              <img
                src={this.state.active_photo.url}
                width={400}
                height={300}
              ></img>
            </div>
          </Modal>
        )}
        {this.state.activeData && (
          <Modal
            visible={this.state.moreModal}
            close={() => this.setState({ moreModal: false })}
          >
            <div className="d-flex flex-row align-items-center">
              <Edit2 className="mr-3"></Edit2>
              <div className="mt-3">
                <Form
                  submitText={"Close"}
                  back={false}
                  inputs={[
                    {
                      label: "KRA PIN",
                      readonly: "readonly",
                      name: "kra_pin",
                      value: this.state.activeData.kra_pin,
                    },
                    {
                      label: "Email",
                      readonly: "readonly",
                      name: "email",
                      value: this.state.activeData.email,
                    },
                    {
                      label: "physical_address",
                      readonly: "readonly",
                      name: "physical_address",
                      value: this.state.activeData.physical_address,
                    },
                    {
                      label: "postal_address",
                      readonly: "readonly",
                      name: "postal_address",
                      value: this.state.activeData.postal_address,
                    },
                    {
                      label: "nearest_shopping_center",
                      readonly: "readonly",
                      name: "nearest_shopping_center",
                      value: this.state.activeData.nearest_shopping_center,
                    },
                    {
                      label: "nearest_public_school",
                      readonly: "readonly",
                      name: "nearest_public_school",
                      value: this.state.activeData.nearest_public_school,
                    },
                    {
                      label: "next_of_kin_names",
                      readonly: "readonly",
                      name: "next_of_kin_names",
                      value: this.state.activeData.next_of_kin_names,
                    },
                    {
                      label: "next_of_kin_phone",
                      readonly: "readonly",
                      name: "next_of_kin_phone",
                      value: this.state.activeData.next_of_kin_phone,
                    },
                    {
                      label: "next_of_kin_relation",
                      readonly: "readonly",
                      name: "next_of_kin_relation",
                      value: this.state.activeData.next_of_kin_relation,
                    },
                  ]}
                  submit={() => {
                    this.setState({ moreModal: false, activeData: null });
                  }}
                />
              </div>
              {/* <h5 className="m-0 mt-1">{this.state.active_photo.name}</h5> */}
            </div>
          </Modal>
        )}
      </>
    );
  }

  fetchAgents = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      //status: 0 // fto fetch only the initiated ones
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    console.log("condition ", parseInt(this.state.searchCondition));
    fetch(
      `${window.server}/utils/agents?status=${parseInt(
        this.state.searchCondition
      )}`,
      {
        headers: {
          Authorization: localStorage.token,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("res", response);
        let status_text = {
          0: "Waiting Review",
          1: "Reviewed",
          2: "Approved",
          3: "Cancelled",
        };
        let data = [];

        response.data.sort((a, b) => {
          return a.status - b.status;
        });

        response.data.map((d, i) => {
          data.push({
            customer_id: d.customer_id,
            agent_code: d.agent_code,
            msisdn: d.msisdn,
            id_number: d.id_number,
            /* kra_pin: d.kra_pin, */
            /*  dealer_id: d.dealer_id, */
            status: status_text[d.status],
            county: d.county,
            // gender: d.gender,
            "ID(Back)": (
              <img
                src={`${window.server}${
                  d.id_back ? d.id_back.replace("public", "") : ""
                }`}
                width={50}
                height={50}
                alt="ID BACK"
                onClick={() => {
                  this.setState({
                    PhotoModal: true,
                    active_photo: {
                      url: `${window.server}${
                        d.id_back ? d.id_back.replace("public", "") : ""
                      }`,
                      name: "BACK ID",
                    },
                  });
                }}
              ></img>
            ),
            "ID(front)": (
              <img
                src={`${window.server}${
                  d.id_front ? d.id_front.replace("public", "") : ""
                }`}
                width={50}
                height={50}
                alt="ID FRONT"
                onClick={() => {
                  this.setState({
                    PhotoModal: true,
                    active_photo: {
                      url: `${window.server}${
                        d.id_front ? d.id_front.replace("public", "") : ""
                      }`,
                      name: "FRONT ID",
                    },
                  });
                }}
              ></img>
            ),
            passport: (
              <img
                src={`${window.server}${
                  d.passport ? d.passport.replace("public", "") : ""
                }`}
                width={50}
                height={50}
                alt="Passport photo"
                onClick={() => {
                  this.setState({
                    PhotoModal: true,
                    active_photo: {
                      url: `${window.server}${
                        d.passport ? d.passport.replace("public", "") : ""
                      }`,
                      name: "Passport Photo",
                    },
                  });
                }}
              ></img>
            ),
            "Good conduct": (
              <img
                src={`${window.server}${
                  d.certificate_of_good_conduct
                    ? d.certificate_of_good_conduct.replace("public", "")
                    : ""
                }`}
                width={50}
                height={50}
                alt="Certificate of good conduct"
                onClick={() => {
                  this.setState({
                    PhotoModal: true,
                    active_photo: {
                      url: `${window.server}${
                        d.certificate_of_good_conduct
                          ? d.certificate_of_good_conduct.replace("public", "")
                          : ""
                      }`,
                      name: "Passport Photo",
                    },
                  });
                }}
              ></img>
            ),
            date_created: d.date_created,
            reviewed_by: d.status == 1 ? d.reviewed_by : "--",
            /* approved_by: d.status ==0 ? d.reviewed_by : '--'; */
            action: (
              <div className="d-flex flex-row">
                <div className="d-flex flex-row">
                  <button
                    className="btn btn-primary btn-round pl-2 pr-2 mr-2"
                    onClick={() => {
                      this.setState({ moreModal: true, activeData: d });
                    }}
                  >
                    More..
                  </button>
                </div>
                {d.status == 0 ? (
                  <>
                    <button
                      onClick={() => {
                        this.submitReview(d);
                      }}
                      className="btn btn-sm btn-success px-3 btn-round"
                    >
                      Submit for Approve
                    </button>
                  </>
                ) : d.status == 1 ? (
                  <>
                    <button
                      onClick={() => {
                        this.cancelRegistration(d);
                      }}
                      className="btn btn-sm btn-danger px-3 btn-round"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        /*  this.setState({
                          ApprovalModal: true,
                          activeDetails: i,
                        }); */
                        this.approveRegistration(d);
                      }}
                      className="btn btn-sm btn-success px-3 btn-round"
                    >
                      Approve
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            ),
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  cancelRegistration = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to disapprove the agents registration?"
      )
    )
      return false;
    const user = JSON.parse(localStorage.user);
    let agent_data = {
      ...data,
      status: 3,
      cancelled_by: user.user.user_id,
    };
    fetch(`${window.server}/utils/agents`, {
      method: "PATCH",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(agent_data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
          this.fetchAgents();
        } else {
          alert("Agent registration Cancelled");
          this.fetchAgents();
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
      });
  };

  approveRegistration = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to approve agents registration?"
      )
    )
      return false;
    const user = JSON.parse(localStorage.user);
    let agent_data = {
      ...data,
      status: 2,
      approved_by: user.user.user_id,
    };
    fetch(`${window.server}/utils/agents`, {
      method: "PATCH",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(agent_data),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
        } else {
          console.log(response);
          alert("Agent  approved successfully");
          this.fetchAgents();
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
      });
  };

  submitReview = (data) => {
    if (
      !window.confirm(
        "Please make sure you have verified all the details before continuing?"
      )
    )
      return false;
    console.log(data);
    const user = JSON.parse(localStorage.user);
    console.log("user", user.user_id);
    let agent_data = {
      ...data,
      status: 1,
      reviewed_by: user.user.user_id,
    };
    console.log("agent_data");
    fetch(`${window.server}/utils/agents`, {
      method: "PATCH",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(agent_data),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
        } else {
          console.log(response);
          alert("Agent  Review submitted successfully");
          this.fetchAgents();
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
      });
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchAgents();
      }, 100);
    }
  }
}

export default Agents;
