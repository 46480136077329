/** @format */

import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class LoanRequests extends Component {
  state = {
    pdfData: [],
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              search={["firstname", "middlename", "surname", "msisdn"]}
              sort="id"
              sortDirection={-1}
              hasPdf={true}
              pdfData={this.state.pdfData}
              pdfName={`${this.props.match.params.customer_id}_loans_statement`}
              pdf_type={"loan_statement"}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchLoanRequests = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      customer_id: this.props.match.params.customer_id,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/loan-transactions?${urlParams}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        let data = [];
        let pdfData = [];
        response.data.map((d, i) => {
          let diff =
            d.clearance_status === 1
              ? d.request.repayment_duration
              : parseInt(
                  moment().diff(
                    new Date(d.account.date_created),
                    "months",
                    true
                  ) > d.request.repayment_duration
                    ? d.request.repayment_duration
                    : parseInt(
                        moment().diff(
                          new Date(d.account.date_created),
                          "months",
                          true
                        )
                      )
                );
          data.push({
            load_id: d.id,
            loan_request: d.loan_request_id,
            //customer_name: `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`,
            loan_type: d.product.product_name,
            // loan_type: d.product_name,
            amount: parseFloat(parseFloat(d.request.amount)).toLocaleString(),
            //interest_rate: d.interest_rate,
            interest_charged:
              diff < 1
                ? parseFloat(
                    parseFloat(d.request.amount * (d.interest_rate / 100) * 1)
                  ).toLocaleString()
                : parseFloat(
                    parseFloat(
                      (d.request.amount / diff) * (d.interest_rate / 100) * diff
                    )
                  ).toLocaleString(),
            // diff: diff,
            // "one month interest": parseFloat(
            //   parseFloat((d.request.amount *(d.interest_rate/100)))
            // ).toLocaleString(),
            //commision: d.commission_rate,
            // interest_charged: parseFloat(
            //     parseFloat((d.request.amount * 0.05))
            //   ).toLocaleString(),
            processing_fee: parseFloat(
              parseFloat(d.request.amount * 0.025)
            ).toLocaleString(),
            repayment_duration: d.request.repayment_duration + " months",

            /* outstanding_balance: parseFloat(
              parseFloat(d.account.principal_balance) 
            ).toLocaleString(), */
            principal_balance: parseFloat(
              parseFloat(d.account.principal_balance)
            ).toLocaleString(),
            interest_balance: parseFloat(
              parseFloat(d.account.interest_balance)
            ).toLocaleString(),
            commission_balance: parseFloat(
              parseFloat(d.account.commission_balance)
            ).toLocaleString(),
            other_interest_balance: parseFloat(
              parseFloat(d.account.other_interest_balance)
            ).toLocaleString(),
            rollover_balance: parseFloat(
              parseFloat(d.account.rollover_balance)
            ).toLocaleString(),
            penalty_balance: parseFloat(
              parseFloat(d.account.penalty_balance)
            ).toLocaleString(),
            outstanding_balance: parseFloat(
              parseFloat(d.account.principal_balance) +
                parseFloat(d.account.interest_balance) +
                parseFloat(d.account.other_interest_balance) +
                parseFloat(d.account.rollover_balance) +
                /* parseFloat(d.account.penalty_balance) + */
                parseFloat(d.account.commission_balance)
            ).toLocaleString(),
            payment_status: ["Not cleared", "Cleared"][d.clearance_status],
            // status: d.status,
            status: parseFloat(d.loan_status) === 1 ? "Active" : "Defaulted",
            guarantor_status: ["Self Guaranteed", "Other Guaranteed"][
              d.request.guarantor_status
            ],
            guaranteed_amount: parseFloat(
              parseFloat(d.request.guaranteed_amount)
            ).toLocaleString(),
            // loan_products: d["loan-products"],
            loan_date: moment
              .utc(d.account.date_created)
              .format("DD MMMM, YYYY.  HH:mm:ss"),
            repayment_date: moment(d.account.date_created)
              .add(d.request.repayment_duration, "months")
              .format("Do MMMM YYYY"),

            action: (
              <div className="d-flex flex-row">
                <Link
                  to={"/loan-view/details/" + d.id}
                  className="btn btn-sm btn-primary px-3 btn-round"
                >
                  View
                </Link>
              </div>
            ),
          });
          pdfData.push({
            load_id: d.id,
            loan_type: d.product.product_name,

            payment_status: ["Not cleared", "Cleared"][d.clearance_status],
            // status: d.status,
            status: parseFloat(d.loan_status) === 1 ? "Active" : "Defaulted",
            transactions: d.transactions,
            customer: d.customer,
          });
        });

        let dts = {};
        dts.data = data;
        console.log("pdfData", pdfData);
        this.setState({
          pdfData: pdfData,
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
        console.log("ffff", this.state.pdfData);
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchLoanRequests();
      }, 100);
    }
  }
}

export default LoanRequests;
