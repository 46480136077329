import React, { Component } from 'react';
import Nav from '../../components/Nav';
import { Route } from 'react-router-dom';
import Form from '../../components/form';
import moment from 'moment';
import { X, XCircle, CheckCircle } from 'react-feather';

class ChangePassword extends Component {
  state = {
    details: {}
  };
  render() {
    return (
      <div className='page border-left'>
        <Nav name='Change Password'></Nav>
        <div className='mt-3 table-card  border-0 card shado mx-3 shadow mb-5'>
          <div className='p-md-4 p-3'>
            <Route
              exact
              render={props => (
                <div className='mt-2 '>
                  <h4>Change Password</h4>

                  <Form
                    submitText={'Change Password'}
                    back={false}
                    inputs={[
                      {
                        label: 'Password',
                        name: 'password',
                        value: this.state.details.password,
                        type: 'password'
                      },
                      {
                        label: 'Confirm Password',
                        name: 'password2',
                        value: this.state.details.password2,
                        type: 'password'
                      }
                    ]}
                    submit={data => {
                      //this.setState({ details: data });
                      setTimeout(() => {
                        this.changePassword(data);
                      }, 0);
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
    );
  }

  changePassword = data => {
    if (
      !window.confirm("Are you sure that you want to change user Password?")
    )
      return false;
    //console.log(data);
      let sdata = JSON.stringify(data);
      let obj = JSON.parse(sdata);
      let values = Object.values(obj);

      //alert(sdata);

    window.alert2.show({
      loader: true,
      title: 'Change Password',
      buttons: true
    });

    fetch(`${window.server}/utils/change-password`, {
      method: 'POST',
       headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        password: data.password,
        password2: data.password2
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.code) {
          window.alert2.show({
            loader: false,
            icon: <XCircle size={60}></XCircle>,
            title: 'Error ' + response.code,
            buttons: true,
            message:
              response.message +
              ' \n ' +
              (response.errors[0] ? response.errors[0].message : '')
          });

          this.setState({ modalVisible: false });
        } else {
          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: 'User Changed password Successfully',
            buttons: true
          });
        }
      })
      .catch(d => {
        //console.log("Error saving the data");
        //console.log(d);
        this.setState({ modalVisible: false });
      });
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
}

export default ChangePassword;
