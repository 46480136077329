import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import Form from "../../components/form";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import "moment-timezone";
import Nav from "../../components/Nav";
class OldSavingsAccount extends Component {
  state = {
    startValue: "",
    endValue: "",
    members: [],
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    ModalVisible: false,
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Old Savings Account"
          // buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className="mt-3 border-0 card shado mx-3 shadow">
          <div className="text-center">
            <h4 className="font-weight-bold">Old Savings Account </h4>
          </div>
          <div className="mt-3 align-items-center">
            <Form
              submitText={"Generate"}
              back={false}
              inputs={[
                {
                  label: "From",
                  name: "start",
                  type: "datetime-local",
                  value: this.state.startValue,
                },
                {
                  label: "To",
                  name: "end",
                  type: "datetime-local",
                  value: this.state.endValue,
                },
              ]}
              submit={(data) => {
                //console.log(data);
                //this.setState({ details: data });
                setTimeout(() => {
                  this.handleGenerate(data);
                }, 0);
              }}
            />
          </div>
        </div>

        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              // search={["firstname", "middlename", "surname", "msisdn", "id_number"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
        <Modal
          visible={this.state.ModalVisible}
          close={() => this.setState({ ModalVisible: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <h5 className="m-0 mt-1">Shares statement</h5>
          </div>
        </Modal>
      </div>
    );
  }

  handleOnChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleGenerate = (data) => {
    console.log(data);
    if (data) {
      let sdata = JSON.stringify(data);
      let obj = JSON.parse(sdata);
      let fdat = obj.start;
      let ldat = obj.end;
      let sdat = moment.utc(fdat).format("YYYY-MM-DD HH:mm:00");
      let edat = moment.utc(ldat).format("YYYY-MM-DD HH:mm:59");

      fetch(
        `${window.server}/utils/old_savings_account?start=${sdat}&end=${edat}`,
        {
          headers: {
            Authorization: localStorage.token,
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          let data = [];
          const d = response.data;
          console.log("x", d[0]);
          d[0].map((d, i) => {
            data.push({
              memberId: d.memberId,
              actualBal: d["actualBal"],
              availBal: d["availBal"],
              dateCreated: d["dateCreated"],
            });
          });

          console.log("data", data);

          let dts = {};
          dts.data = data;

          this.setState({
            tableData: { ...response, ...dts },
            response,
            table_loading: false,
          });
        })
        .catch((d) => {
          this.setState({ table_loading: false });
          console.error(d);
        });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    let $t = this;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      $t.handleGenerate();
    }, 100);
  }
}

export default OldSavingsAccount;
