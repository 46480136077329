import React, { Component } from "react";
import moment from "moment";
import { DollarSign, User, LogOut, MapPin, XCircle } from "react-feather";
import { Link } from "react-router-dom";

class Details extends Component {
  state = { data: {}, balanceInfo: {}, salaryVisible: false, exitInfo: {} };
  render() {
    return (
      <div className="p-3 mt-3">
        <div className="row">
          <div className="col-md-6">
            <ul className="list-group user-details loan-card ">
              <div className="p-3 bg-light font-weight-bold border-bottom">
                <DollarSign size={18}></DollarSign> Mobile Loan Details
              </div>
              {Object.keys(this.state.data).map((d) => (
                <li className="list-group-item d-flex flex-row  text-dark border-0">
                  <div className="user-detail-title font-weight-bold text-capitalize">
                    {d.replace(/_/g, " ")}
                  </div>
                  <div>{this.state.data[d]}</div>
                </li>
              ))}
            </ul>
          </div>
          {/*  <div className="col-md-6">
            {this.state.customer && (
              <>
                <div className="officer-card-parent mx-3 text-input mb-3">
                  <div className=" bg-light font-weight-bold d-flex flex-row justify-content-between align-items-center">
                    <h5 className="p-3 m-0 font-weight-bold">Client</h5>

                    <Link
                      to={`/member-view/details/${this.state.customer_id}`}
                      className="btn btn-outline-primary px-3 btn-round mr-3"
                    >
                      View
                    </Link>
                  </div>
                  <div className=" d-flex flex-row guarantor-card align-items-center">
                    <div className="mx-3">
                      <div className="user-profile">
                        <span className="position-absolute">
                          {this.state.customer.firstname[0]}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 w-100 pr-3">
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <h5 className="font-weight-bold">
                          {this.state.customer.firstname}{" "}
                          {this.state.customer.middlename}{" "}
                          {this.state.customer.surname}{" "}
                        </h5>
                      </div>
                      <div className="d-flex flex-row align-items-center flex-fill">
                        <div>
                          <small className="font-weight-bold">Nationa ID</small>
                          <div>{this.state.customer.id_number}</div>
                        </div>
                        <div className="mx-3 content-divider"> </div>
                        <div>
                          <small className="font-weight-bold">
                            Phone Number
                          </small>
                          <div>{this.state.customer.msisdn}</div>
                        </div>
                      </div>
                      <div className=" mt-2 d-flex flex-row justify-content-between align-items-center w-100">
                        <div className="badge badge-primary px-3 d-inline-block">
                          client
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.officer && (
                  <div className="officer-card-parent mx-3 text-input mb-3">
                    <div className=" bg-light font-weight-bold d-flex flex-row justify-content-between align-items-center">
                      <h5 className="p-3 m-0 font-weight-bold">Officer</h5>

                      <Link
                        to={`/member-view/`}
                        className="btn btn-outline-primary px-3 btn-round mr-3"
                      >
                        View
                      </Link>
                    </div>
                    <div className=" d-flex flex-row guarantor-card align-items-center">
                      <div className="mx-3">
                        <div className="user-profile">
                          <span>{this.state.officer.firstname[0]}</span>
                        </div>
                      </div>
                      <div className="py-3 w-100 pr-3">
                        <div className="d-flex flex-row align-items-center justify-content-between">
                          <h5 className="font-weight-bold">
                            {this.state.officer.firstname}{" "}
                            {this.state.officer.middlename}{" "}
                            {this.state.officer.surname}{" "}
                          </h5>
                        </div>
                        <div className="d-flex flex-row align-items-center flex-fill">
                          <div>
                            <small className="font-weight-bold">
                              Nationa ID
                            </small>
                            <div>{this.state.officer.id_number}</div>
                          </div>
                          <div className="mx-3 content-divider"> </div>
                          <div>
                            <small className="font-weight-bold">
                              Phone Number
                            </small>
                            <div>{this.state.officer.msisdn}</div>
                          </div>
                        </div>
                        <div className=" mt-2 d-flex flex-row justify-content-between align-items-center w-100">
                          <div className="badge badge-primary px-3 d-inline-block">
                            Officer
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {this.state.rolloverData && (
              <div className="officer-card-parent mx-3 text-input mb-3">
                <div className=" bg-light font-weight-bold d-flex flex-row justify-content-between align-items-center">
                  <h5 className="p-3 m-0 font-weight-bold">
                    Roll over details
                  </h5>
                </div>
                <div className=" d-flex flex-row guarantor-card align-items-center">
                  <div className="py-3 w-100 pr-3">
                    <div className="d-flex flex-column ml-3 justify-content-between">
                      <div>
                        <small className="font-weight-bold">
                          loan default amount :
                        </small>
                        <div>
                          {" "}
                          {
                            this.state.rolloverData
                              .defaulted_amount_to_charge_rollover
                          }
                        </div>
                      </div>
                      <div>
                        <small className="font-weight-bold">
                          date defaulted :
                        </small>
                        <div>
                          {" "}
                          {this.state.rolloverData.loan_defaulted_date.format(
                            "Do MMMM YYYY, h:mm:ss a"
                          )}
                        </div>
                      </div>
                      <div>
                        <small className="font-weight-bold">
                          rollover charge :
                        </small>
                        <div>
                          {" "}
                          {this.state.rolloverData.roll_over_charge_on_default.toString()}{" "}
                          <small className="font-weight-bold pl-3">
                            {"("}
                            {this.state.rolloverData.roll_over_percentage.toString()}

                            {"%"}
                            {" )"}
                          </small>
                        </div>
                      </div>
                      <div>
                        <div>
                          <small className="font-weight-bold">
                            amount due after :
                          </small>
                        </div>{" "}
                        {this.state.rolloverData.total_amount_due_after.toString()}
                      </div>
                      <div>
                        <div>
                          <small className="font-weight-bold">
                            date rolled over:
                          </small>
                        </div>{" "}
                        {this.state.rolloverData.loan_roll_over_date.format(
                          "Do MMMM YYYY, h:mm:ss a"
                        )}
                      </div>
                      <div>
                        <div>
                          <small className="font-weight-bold">
                            new loan due date:
                          </small>
                        </div>{" "}
                        {this.state.rolloverData.new_loan_due_date.format(
                          "Do MMMM YYYY, h:mm:ss a"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.recoveryData && (
              <div className="officer-card-parent mx-3 text-input mb-3">
                <div className=" bg-light font-weight-bold d-flex flex-row justify-content-between align-items-center">
                  <h5 className="p-3 m-0 font-weight-bold">Recovery details</h5>
                </div>
                <div className=" d-flex flex-row guarantor-card align-items-center">
                  <div className="py-3 w-100 pr-3">
                    <div className="d-flex flex-column ml-3 justify-content-between">
                      <div>
                        <small className="font-weight-bold">
                          Amount to recover :
                        </small>
                        <div> {this.state.recoveryData.amount_to_recover} </div>
                      </div>
                      <div>
                        <small className="font-weight-bold">
                          Recovery done after :
                        </small>
                        <div>
                          {" "}
                          {this.state.recoveryData.recovery_date.format(
                            "Do MMMM YYYY, h:mm:ss a"
                          )}
                        </div>
                      </div>
                      <div>
                        <small className="font-weight-bold">
                          recovery charge :
                        </small>
                        <div>
                          {this.state.recoveryData.recovery_charge.toString()}{" "}
                          <small className="font-weight-bold pl-3">
                            {"("}
                            {this.state.recoveryData.recovery_percentage.toString()}

                            {"%"}
                            {" )"}
                          </small>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            )}
          </div> */}
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      loan_id: this.props.match.params.id,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/utils/simu/loan_requests?${urlParams}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        let data = [];
        response.data.map((d, i) => {
          let status_text = {
            0: "Normal",
            1: "Pending Payment",
            2: "Cancelled",
            3: "Processed",
          };

          data.push({
            // id: d.id,
            phone_name: d.phone_name,
            phone_model: d.phone_model,
            loan_request_id: d.loan_request_id,
            phone_retail_price: d.phone_amount,
            phone_retail_price:
              "KES " +
              parseFloat(parseFloat(d.phone_retail_price)).toLocaleString(),
            daily_payment:
              "KES " + parseFloat(parseFloat(d.daily_payment)).toLocaleString(),
            weekly_payment:
              "KES " +
              parseFloat(parseFloat(d.weekly_payment)).toLocaleString(),
            monthly_payment:
              "KES " +
              parseFloat(parseFloat(d.monthly_payment)).toLocaleString(),
            phone_collected: d.collection_otp_utilized == 1 ? "Yes" : "No",

            repayment_duration: d.repayment_duration + " days",
            status:
              status_text[
                d.status
              ] /* parseFloat(d.loan_status) === 1 ? "Normal" : "Defaulted", */,
          });
        });
        console.log(data);
        this.setState({ data: data[0], ...response.data[0] });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };
}

export default Details;
