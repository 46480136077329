import React, { Component } from "react";
import Nav from "../../components/Nav";
import { MessageSquare, Send } from "react-feather";

class SpecialActions extends Component {
  state = {
    loading: false,
    cards: [
      {
        icon: <MessageSquare size={50}></MessageSquare>,
        title: "All Sent SpecialActionss",
        to: "sms-list",
      },
    ],
  };
  sendDefaultersSMS = () => {
    console.log("XXXX");
    this.setState({ loading: true });
    fetch(`${window.server}/utils/admin/send_defaulters_sms`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: null,
    })
      .then((response) => {
        //window.location = "/sms";
        this.setState({ loading: false });

        window.alert2.notify({
          title: "Messages Queued",
          description: "Messages Queued",
          color: "material-green",
          autoClose: true,
        });
      })
      .catch((d) => {
        alert(d);
        console.log(d);
      });
  };
  render() {
    return (
      <div>
        <Nav name="SpecialActions"></Nav>
        {this.state.loading && (
          <div className="loader h-100 w-100 d-flex flex-row align-items-center justify-content-center show-loader ">
            <div className="lds-roller">
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
            <br></br>
            <br></br>
            <p>Sending messages please wait as it may take a few minutes</p>
          </div>
        )}
        {!this.state.loading && (
          <div
            className="mt-3 table-card sms-table-card  border-0 card shado mx-3 shadow p-4 d-flex flex-column justify-content-center"
            onClick={() => {
              this.sendDefaultersSMS();
            }}
          >
            <div className="row justify-content-center w-100">
              <div className="d-inline-block px-3 mb-4">
                <div className="sms-card p-3 card d-flex flex-column align-items-center py-4">
                  <div className="">
                    <Send size={50}></Send>
                  </div>
                  <div className="mt-4">
                    <h5 className="font-weight-bold">Send Defaulters sms</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SpecialActions;
