import React, { Component } from "react";
import { Route, Link, Switch } from "react-router-dom";

import Tabs from "../../components/tabs";
import loanDetails from "./details";
import Guarantors from "./guarantors";
import MemberPayments from "./transactions";
import Schedules from "./schedules";
import walletPayments from "./walletPayments";
import LoanStatement from "./statement";
import MobileDetails from "./mobileDetails";

class LoanView extends Component {
  state = { data: [{}], tableData: [] };
  render() {
    return (
      <div className="card table-card loan-parent-card border-0 shadow m-3">
        <Tabs
          tabs={[
            {
              label: "details",
              link: "/loan-view/details/" + this.props.match.params.id,
            },
            {
              label: "payments",
              link: "/loan-view/transactions/" + this.props.match.params.id,
            },
            {
              label: "Guarantors",
              link: "/loan-view/guarantors/" + this.props.match.params.id,
            },
            {
              label: "Repayment Schedules",
              link: "/loan-view/schedules/" + this.props.match.params.id,
            },
            {
              label: "Wallet Payments",
              link: "/loan-view/wallet_payments/" + this.props.match.params.id,
            },
            {
              label: "Loan Statement",
              link: "/loan-view/loan_statement/" + this.props.match.params.id,
            },
            {
              label: "Mobile Loan Details",
              link:
                "/loan-view/mobile_loan_details/" + this.props.match.params.id,
            },
          ]}
        >
          <Route path="/loan-view/details/:id" exact component={loanDetails} />
          <Route
            path="/loan-view/guarantors/:id"
            exact
            component={Guarantors}
          />
          <Route
            path="/loan-view/transactions/:id"
            exact
            component={MemberPayments}
          />
          <Route path="/loan-view/schedules/:id" exact component={Schedules} />
          <Route
            path="/loan-view/wallet_payments/:id"
            exact
            component={walletPayments}
          />
          <Route
            path="/loan-view/loan_statement/:id"
            exact
            component={LoanStatement}
          />
          <Route
            path="/loan-view/mobile_loan_details/:id"
            exact
            component={MobileDetails}
          />
        </Tabs>
      </div>
    );
  }
}

export default LoanView;
