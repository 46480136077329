/** @format */

import React, { Component } from "react";
import { Printer, CheckCircle } from "react-feather";
import logo from "../../img/digital2.png";
import { Link } from "react-router-dom";
import moment from "moment";
import Nav from "../../components/Nav";

class MemberApprove extends Component {
  state = {
    details: {
      passport: null,
      firstname: "gabriel",
      middlename: "kamau",
      surname: "muhangia",
      msisdn: "0717638548",
      date_of_birth: "2020-01-08",
      // gender: "1",
      marital_status: "1",
      identity_type: "1",
      id_number: "2324234",
      kra_pin: "KSDNF32324234",
      sub_county_id: "1",
      ward_id: "2",
      village: "KIJABE",
      address: "17 kingstion road",
      nationality: "1",
      pledge: "3000",
    },
    kins: [
      {
        type: "parent",
        firstname: "samuel",
        othername: "kariega",
        id_number: "22323423",
        msisdn: "0717342422",
        relationship: "father",
      },
    ],
  };
  render() {
    return (
      <>
        <Nav
          name='Approve Member'
          buttons={[
            {
              text: "Print",
              onClick: () => {
                window.print();
              },
            },
          ]}
        ></Nav>
        <div className='d-flex flex-row justify-content-'>
          <div className='virtual-form w-100' id='virtual-form'>
            <div className='text-center'>
              <img src={logo} alt='' className='logo' />
              <h4 className='font-weight-bold my-3'>
                DIGITAL SACCO MANAGEMENT SYSTEM
              </h4>
              <h5 className='font-weight-bold'>MEMBER REGISTRATION FORM</h5>
            </div>

            <div className='mt-5'>
              <div className='document-passport'>
                <img
                  src={this.state.details.passport}
                  className='w-109 h-100'
                  alt=''
                />
              </div>

              <div className='mb-5'>
                <div className='page-title'>APPLICANT'S DETAILS</div>
                <div className=''>
                  <div className='page-card p-3'>
                    <div className='row'>{this.generateInputs()}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='mb-5'>
              <div className='page-title'>NEXT OF KIN(S)</div>
              <div className=''>
                <div className='page-card'>
                  <table className='table table-striped'>
                    <thead>
                      {Object.keys(this.state.kins[0]).map((d) => (
                        <th className='text-capitalize'>
                          {d.replace(/_/g, " ")}
                        </th>
                      ))}
                    </thead>
                    <tbody>
                      {this.state.kins.map((d) => (
                        <tr>
                          {Object.keys(this.state.kins[0]).map((d1) => (
                            <td className='text-uppercas'>{d[d1]}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <div className='mb-2 font-weight-bold'>ID Front</div>
                <div className='id-photo'>
                  <img
                    src={this.state.details.idFront}
                    className='w-100 h-100'
                    alt=''
                  />
                </div>
              </div>

              <div className='col-md-6'>
                <div className='mb-2 font-weight-bold'>ID Back</div>
                <div className='id-photo'>
                  <img
                    src={this.state.details.idBack}
                    className='w-100 h-100'
                    alt=''
                  />
                </div>
              </div>
            </div>

            <hr className='print-hide my-5' />

            <div className='text-center print-hide'>
              <div className='d-flex flex-row align-items-center'>
                <label>
                  <input
                    type='checkbox'
                    className='mr-3 styled-checkbox'
                    id='342'
                    name=''
                    checked={this.state.acceptAgreement}
                    onChange={() => {
                      this.setState({
                        acceptAgreement: !this.state.acceptAgreement,
                      });
                    }}
                  />
                  <div></div>
                </label>
                <div className='text-left'>
                  I declare that the information given herein is true to the
                  best of my knowledge and belief: I further authorise the SACCO
                  to verify the information given herein and make reference from
                  any person(s)/institution(s) named herein.
                </div>
              </div>

              <button
                className='btn btn-primary btn-round px-5 mt-4 print-hide'
                onClick={this.addClient}
                disabled={!this.state.acceptAgreement}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  fetchMembers = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/pending?${urlParams}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        this.setState({
          details: response.data[0],
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  generateInputs = () => {
    let data = {
      full_name: `${this.state.details.firstname} ${this.state.details.middlename} ${this.state.details.surname}`,
      date_of_birth: moment(this.state.details.date_of_birth).format(
        "Do MMMM YYYY"
      ),
      "Phone Number": this.state.details.msisdn,
      KRA_pin: this.state.details.kra_pin,
      // gender: ["Male", "Female"][0],
      // sub_county: window.wardsList[parseInt(this.state.details.sub_county_id)],
      // ward: window.wardsList[parseInt(this.state.details.ward_id)],
      village: this.state.details.village,
      ID_number: this.state.details.national_id,
      address: this.state.details.address,
      nationality: "Kenya",
      pledge: "KES " + parseInt(this.state.details.pledge).toLocaleString(),
    };

    console.log(data);

    return Object.keys(data).map((d) => (
      <div className='col-md-3 pb-3'>
        <div className='page-label text-capitalize'>{d.replace(/_/g, " ")}</div>
        <div>{data[d] || "-"}</div>
      </div>
    ));
  };

  verify(data) {
    let result = true;
    let missing = [];
    Object.keys(data).map((d) => {
      if (data[d] === "") {
        missing.push(d.replace("_id", "").replace(/_/g, " "));
        result = false;
      }
    });
    missing.join(", ");
    if (!result) alert("Please fill all the require fields : " + missing);
    return result;
    // return true;
  }

  componentDidMount = () => {
    this.fetchMembers();
  };

  addClient = () => {
    let formData = new FormData();
    let { details } = this.state;
    details.msisdn = window.verifyNumber(details.msisdn);
    let postData = {
      ...this.state.details,
      ...this.state.documents,
      ...this.state.business,
      kins: this.state.kins,
      ...this.state.other,
    };

    Object.keys(postData).map((d) => {
      if (d === "kins") return;
      let data = postData[d];
      try {
        if (data.substring(0, 11) === "data:image/") {
          formData.append(d, this.dataURLtoFile(data), "file.png");
        } else {
          formData.append(d, data);
        }
      } catch (error) {
        console.error(error);
        formData.append(d, data);
      }
    });

    //console.log(postData);

    postData.kins.map((d, i) => {
      Object.keys(d).map((d1) => {
        formData.append(`kins[${i}][${d1}]`, d[d1]);
      });
    });

    window.alert2.show({
      loader: true,
      title: "Approving User",
      buttons: true,
    });

    fetch(`${window.server}/customer/registration`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.code) {
          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: "User Approve Successfully",
            buttons: true,
            onSubmit: () => {
              this.props.history.push(`/members`);
            },
          });

          this.setState({ modalVisible: false });
        } else {
          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: "User Created Successfully",
            buttons: true,
            onSubmit: () => {
              // this.props.history.push(
              //   `/member-view/details/${response.user_id}`
              // );

              this.props.history.push(`/members`);
            },
          });
        }
      })
      .catch((d) => {
        //console.log("Error saving the data");
        //console.log(d);
        this.setState({ modalVisible: false });
      });
  };
}

export default MemberApprove;
