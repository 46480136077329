import React, { Component } from "react";
import Nav from "../../components/Nav";
import logo from "../../img/_logo.png";
import { Calendar } from "react-feather";
import DatePicker from "../../components/datePicker";
import moment from "moment";

class TrialBalance extends Component {
  state = {
    data: [
      {
        account: "Cash",
        debit: 200,
        credit: 3000
      },
      {
        account: "Cash",
        debit: 200,
        credit: 3000
      },
      {
        account: "Cash",
        debit: 200,
        credit: 0
      },
      {
        account: "Cash",
        debit: 200,
        credit: 0
      },
      {
        account: "Cash",
        debit: 200,
        credit: 3000
      },
      {
        account: "Cash",
        debit: 200,
        credit: 0
      },
      {
        account: "Cash",
        debit: 200,
        credit: 3000
      },
      {
        account: "Cash",
        debit: 200,
        credit: 3000
      }
    ],
    showReport: false,
    dates: {
      startdate: null,
      enddate: null
    }
  };
  render() {
    return (
      <div className="d-flex flex-column justify-content-between page align-items-center print-block">
        <div className="w-100">
          <Nav
            name="Trial Balance"
            buttons={[
              {
                text: "Choose Date",
                onClick: () => this.setState({ showReport: false })
              },
              { text: "Print", onClick: window.print }
            ]}
          ></Nav>
        </div>

        {!this.state.showReport && (
          <div className="date-picker-card shadow p-4">
            <h4 className="text-center font-weight-bold">
              <Calendar className="icon"></Calendar> Select Date Range
            </h4>

            <div className="my-5 row">
              <div className="col-md-6 text-center">
                <div className="font-weight-bold mb-3">Start Date</div>
                <div className="picker">
                  <DatePicker
                    placeholder="choose start date"
                    date={this.state.startdate}
                    onChange={date => {
                      let { dates } = this.state;
                      dates.startdate = date;
                      this.setState({});
                    }}
                  ></DatePicker>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="font-weight-bold mb-3">End Date</div>
                <div className="picker">
                  <DatePicker
                    placeholder="choose end date"
                    date={this.state.startdate}
                    onChange={date => {
                      let { dates } = this.state;
                      dates.enddate = date;
                      this.setState({});
                    }}
                  ></DatePicker>
                </div>
              </div>
            </div>

            <div className=" text-center">
              <button
                className="btn btn-primary px-5 btn-round"
                onClick={() => this.setState({ showReport: true })}
                disabled={
                  !(this.state.dates.startdate && this.state.dates.enddate)
                }
              >
                Generate Report
              </button>
            </div>
          </div>
        )}

        {this.state.showReport && (
          <div className="virtual-form trial-balance-form d-flex flex-column justify-content-start page align-items-center">
            <div className="w-100">
              <div className="text-center">
                <img src={logo} alt="" className="logo" />
                <h4 className="font-weight-bold my-3">
                  SACCO MANAGEMENT SYSTEM
                </h4>
                <h5 className="font-weight-bold">TRIAL BALANCE</h5>
                <p className="font-weight-bold">
                  {moment(this.state.dates.startdate).format("Do MMMM  YYYY")} -{" "}
                  {moment(this.state.dates.enddate).format("Do MMMM  YYYY")}
                </p>
              </div>
            </div>
            <div className="m-3 trial-balance  bg-white mt-5">
              <table className="table table-striped border">
                <thead className="bg-white">
                  <tr>
                    <th>Account Title</th>

                    <th>Debit</th>
                    <th>Credit</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map(d => (
                    <tr>
                      <td>{d.account}</td>
                      <td>KES {d.debit.toLocaleString()}</td>
                      <td>KES {d.credit.toLocaleString()}</td>
                    </tr>
                  ))}

                  <tr className="bg-white">
                    <td className="font-weight-bold">Total</td>
                    <td className="font-weight-bold">
                      KES {this.getTotals("debit").toLocaleString()}
                    </td>
                    <td className="font-weight-bold">
                      KES {this.getTotals("credit").toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div></div>
          </div>
        )}
        <div></div>
      </div>
    );
  }

  getTotals = i => {
    let total = 0;
    this.state.data.map(d => {
      total += d[i];
    });
    return total;
  };
}

export default TrialBalance;
