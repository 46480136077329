import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import Form from "../../components/form";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import "moment-timezone";
import Nav from "../../components/Nav";

class DefaultedLoans extends Component {
  state = {
    startValue: "",
    endValue: "",
    members: [],
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Defaulted Loans"
          // buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className="mt-3 border-0 card shado mx-3 shadow">
          <div className="text-center">
            <h4 className="font-weight-bold">Defaulted Loans</h4>
          </div>
          <div className="mt-3 align-items-center">
            <Form
              submitText={"Generate"}
              back={false}
              inputs={[
                {
                  label: "From",
                  name: "start",
                  type: "datetime-local",
                  value: this.state.startValue,
                },
                {
                  label: "To",
                  name: "end",
                  type: "datetime-local",
                  value: this.state.endValue,
                },
              ]}
              submit={(data) => {
                //console.log(data);
                //this.setState({ details: data });
                setTimeout(() => {
                  this.handleGenerate(data);
                }, 0);
              }}
            />
          </div>
        </div>

        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              // search={["firstname", "middlename", "surname", "msisdn", "id_number"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  handleOnChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleGenerate = (data) => {
    console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    let values = Object.values(obj);
    let fdat = obj.start;
    let ldat = obj.end;
    let sdat = moment.utc(fdat).format("YYYY-MM-DD HH:mm:00");
    let edat = moment.utc(ldat).format("YYYY-MM-DD HH:mm:59");

    console.log(obj.start);
    console.log(obj.end);

    //alert(values);
    // let q = {
    //    // ...this.state.filter,
    //    ...this.state.query,
    //    "$or[0][account_type_id]": 20
    //    // date_created: values[0],
    //    // date_created: values[1]
    //  };

    //  let urlParams = Object.entries(q)
    //    .map(e => e.join("="))
    //    .join("&");

    //alert(values);
    fetch(
      `${window.server}/utils/defaulters_extended?start=${sdat}&end=${edat}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.token,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("data", response);
        let data = [];

        response.data.map((d, i) => {
          data.push({
            /* member_id: d["Member ID"],
            member_name: `${d.Member_Name} `, */
            phone_no: d["Phone No"],
            load_id: d["Loan Id"],
            loan_type: d["Product Name"],
            Loan_Amount: parseFloat(
              parseFloat(d["Amount Borrowed"])
            ).toLocaleString(),
            date_disbursed: /* moment( */ d["Date Borrowed"] /* ) */ /* .format(
              "DD MMMM YYYY, h:mm:ss a"
            ), */,
            principal_balance:
              parseFloat(
                parseFloat(d["Principal  Balance"])
              ).toLocaleString() || 0.0,
            interest_balance:
              parseFloat(parseFloat(d["Interest Balance"])).toLocaleString() ||
              0.0,
            "Commision/proc fee Balance":
              parseFloat(
                parseFloat(d["Processing Fee Balance"])
              ).toLocaleString() || 0.0,
            "Other Fees balance": d["Other Fee Balances"] || 0.0,
            "RollOver Fee Balances": d["RollOver Fee Balances"] || 0.0,
            "Penalty Fee Balances": d["Penalty Fee Balances"] || 0.0,
            //"Amount paid": d["Amount paid"],
            "Repaid Amounts (Total)": d["Repaid Amounts (Total)"],
            "Defaulted Amounts (total)": d["Defaulted Amounts (total)"],
            "Final Due Date": d["Final Due Date"],
            "Days in Default": d["Days in Default"],
            "Defaulted amount RolledOver": d["Defaulted amount RolledOver"],
            "Roll over charged": d["Roll over charged"],
            "Total Principal Paid": d["Total Principal Paid"],
            "Total Processing Fee Paid": d["Total Processing Fee Paid"],
            "Total Interest Paid": d["Total Interest Paid"],
            "Recovery Applied after Date": d["Recovery Applied after Date"],
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    let $t = this;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      $t.handleGenerate();
    }, 100);
  }
}

export default DefaultedLoans;
