import React, { Component } from "react";
import Nav from "../../components/Nav";
import Form from "../../components/form";

class LoanProductCreate extends Component {
  state = {
    inputs: {
      product_name: "",
      interest_rate: "",
      commission: "",
      guarantor_status: "",
      status: "",
      description: "",
      duration_to_qualify: "",
      charge_type: "",
      qualification_percentage: "",
      repayment_mode: ""
    }
  };
  render() {
    return (
      <div>
        <Nav name="Loan Product Create"></Nav>

        <div className="mt-3 table-card  border-0 card shado mx-3 px-4 shadow mb-5">
          <div className="product-form">
            <Form
              submitText={"Submit"}
              back={false}
              inputs={[
                {
                  label: "product name",
                  name: "product_name",
                  value: ""
                },
                {
                  label: "interest rate",
                  name: "interest_rate",
                  value: "",
                  type: "number"
                },
                {
                  label: "commission",
                  name: "commission",
                  value: "",
                  type: "number"
                },
                {
                  label: "guarantor status",
                  name: "guarantor_status",
                  value: "",
                  type: "select",
                  options: [
                    { name: "Self Guarantee", value: 0 },
                    { name: "Needs Guarantors", value: 1 },
                    { name: "Pool", value: 2 }
                  ]
                },
                {
                  label: "status",
                  name: "status",
                  value: "",
                  type: "select",
                  options: [
                    { name: "Active", value: 1 },
                    { name: "Inactive", value: 2 }
                  ]
                },
                {
                  label: "description",
                  name: "description",
                  value: "",
                  type: "number"
                },
                {
                  label: "duration to qualify",
                  name: "duration_to_qualify",
                  value: "",
                  type: "number"
                },
                {
                  label: "charge type",
                  name: "charge_type",
                  value: ""
                },
                {
                  label: "qualification percentage",
                  name: "qualification_percentage",
                  value: "",
                  type: "number"
                },
                {
                  label: "repayment mode",
                  name: "repayment_mode",
                  value: "",
                  type: "select",
                  options: [
                    { name: "Reducing Balance", value: 1 },
                    { name: "Flat rate", value: 2 }
                  ]
                },
                {
                  type: "break"
                },
                {
                  label: "Minimum amount",
                  name: "min_amount",
                  value: "",
                  type: "number"
                },
                {
                  label: "Maximum amount",
                  name: "max_amount",
                  value: "",
                  type: "number"
                },
                {
                  label: "Maximum period",
                  name: "max_period",
                  value: "",
                  type: "number"
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default LoanProductCreate;
