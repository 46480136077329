/** @format */

import React, { Component } from "react";
import logo from "../../img/_logo.png";
import TableSM from "../../components/tableSM";
import Nav from "../../components/Nav";
import { MapPin, XCircle, CheckCircle } from "react-feather";
import moment from "moment";
class LoanApprove extends Component {
  state = { comments: "", checks: [], commentsEdit: true, guarantors: [] };
  render() {
    return (
      <div>
        <Nav
          name='Approve Loan'
          buttons={[
            {
              text: "Print",
              onClick: () => {
                window.print();
              },
            },
          ]}
        ></Nav>

        <div className='py-3'>
          {this.state.id && (
            <div className='d-flex flex-row justify-content-center'>
              <div className='virtual-form w-100' id='virtual-form'>
                <div className='text-center'>
                  <img src={logo} alt='' className='logo' />
                  <h4 className='font-weight-bold my-3'>KACOMA SACCO</h4>
                  <h5 className='font-weight-bold'>LOAN APPRAISAL FORM</h5>
                </div>

                <div className='mt-5'>
                  <div className='mt-5'>
                    <div className='document-passport position-relative'>
                      {this.state.customer.passport_path && (
                        <img
                          src={`${
                            window.server
                          }${this.state.customer.passport_path.replace(
                            "public",
                            ""
                          )}`}
                          className='w-100 h-100'
                          alt=''
                        />
                      )}
                    </div>

                    <div className='mb-5'>
                      <div className='page-title'>APPLICANT'S DETAILS</div>
                      <div className=''>
                        <div className='page-card p-3'>
                          <div className='row'>
                            {this.state.customer.firstname &&
                              this.generateUserInputs()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='mb-5'>
                    <div className='page-title'>LOAN DETAILS</div>
                    <div className=''>
                      <div className='page-card p-3'>
                        <div className='row'>{this.generateLoanInputs()}</div>
                      </div>
                    </div>
                  </div>

                  <div className='mb-5'>
                    <div className='page-title'>LOAN SECURITIES</div>
                    <div className=''>
                      <div className='page-card'>
                        <table className='table table-striped'>
                          <thead>
                            <th> Name </th>
                            <th> Description </th>
                            <th> Original Value </th>
                            <th> Current Value </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>MOBILE PHONE</td>
                              <td>
                                Lorem ipsum, dolor sit amet consectetur
                                adipisicing elit.
                              </td>
                              <td>KES 1,000</td>
                              <td>
                                <b>KES 3,000</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className='mb-5'>
                    <div className='page-title'>GUARANTOR DETAILS</div>
                    <div className=''>
                      <div className='page-card'>
                        <TableSM
                          data={this.state.guarantors.map((d) => {
                            if (!d.customer) return false;
                            return {
                              full_name: `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`,
                              ID_number: d.customer.id_number,
                              //   savings_balance:
                              //     "KES " +
                              //     parseFloat(
                              //       d.savings.actual_balance
                              //     ).toLocaleString(),
                              guaranteed_amount:
                                "KES " +
                                parseFloat(
                                  d.guaranteed_amount
                                ).toLocaleString(),
                            };
                          })}
                        ></TableSM>
                      </div>
                    </div>
                  </div>

                  <div className='text-uppercase'>
                    <h5 className='font-weight-bold'>LOAN APPRAISAL</h5>
                  </div>

                  <div className='mb-5'>
                    <div className='page-title'>CREDIT HISTORY</div>
                    <div className=''>
                      <div className='page-card'>
                        <table className='table table-striped'>
                          <thead>
                            <th> Amount </th>
                            <th> Period </th>
                            <th> Loan Type </th>
                            <th> Amount </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>SIMON KIHARA</td>
                              <td>23443534</td>
                              <td>07122323432</td>
                              <td>
                                <b>KES 3,000</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <h5 className='font-weight-bold mb-4 mt-3'>REVIEWED BY</h5>

                  <div className='mb-5'>
                    {this.state.checks.map((d) => (
                      <>
                        <div className='row d-flex align-items-center'>
                          <div className=' d-flex flex-row guarantor-card align-items-center col-md-4'>
                            <div className='mx-3'>
                              <div className='user-profile'>
                                <span>{d.user.firstname[0]}</span>
                              </div>
                            </div>
                            <div className='py-3 w-100'>
                              <div className='d-flex flex-row align-items-center justify-content-between'>
                                <h5 className='font-weight-bold'>
                                  {d.user.firstname} {d.user.middlename}{" "}
                                  {d.user.surname}{" "}
                                </h5>
                              </div>
                              <div className='d-flex flex-row align-items-center flex-fill'>
                                <div>
                                  <small className='font-weight-bold'>
                                    Nationa ID
                                  </small>
                                  <div>{d.user.id_number}</div>
                                </div>
                                <div className='mx-3 content-divider'> </div>
                                <div>
                                  <small className='font-weight-bold'>
                                    Phone Number
                                  </small>
                                  <div>{d.user.msisdn}</div>
                                </div>
                              </div>
                              <div className=' mt-3 d-flex flex-row  align-items-center w-100'>
                                <div className='badge badge-primary px-3 d-inline-block'>
                                  Loan Officer
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-1'></div>

                          <div className='col-md-7'>
                            <div className='d-flex flex-row mb-2 justify-content-between align-items-center'>
                              <h5 className='font-weight-bold m-0'>Comments</h5>
                            </div>
                            <div className='form-comments'>
                              <div className='text-data'>
                                {d.comments}
                                {d.comments.trim() === "" && (
                                  <div className='text-muted'>No comment</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}
                  </div>

                  {(this.state.approved || this.state.rejected) && (
                    <>
                      <div className='row d-flex align-items-center'>
                        <div className=' d-flex flex-row guarantor-card align-items-center col-md-4'>
                          <div className='mx-3'>
                            <div className='user-profile'>
                              <span>{window.user.user.firstname[0]}</span>
                            </div>
                          </div>
                          <div className='py-3 w-100'>
                            <div className='d-flex flex-row align-items-center justify-content-between'>
                              <h5 className='font-weight-bold'>
                                {window.user.user.firstname}{" "}
                                {window.user.user.middlename}{" "}
                                {window.user.user.surname}{" "}
                              </h5>
                            </div>
                            <div className='d-flex flex-row align-items-center flex-fill'>
                              <div>
                                <small className='font-weight-bold'>
                                  Nationa ID
                                </small>
                                <div>{window.user.user.id_number}</div>
                              </div>
                              <div className='mx-3 content-divider'> </div>
                              <div>
                                <small className='font-weight-bold'>
                                  Phone Number
                                </small>
                                <div>{window.user.user.msisdn}</div>
                              </div>
                            </div>
                            <div className=' mt-3 d-flex flex-row  align-items-center w-100'>
                              <div className='badge badge-primary px-3 d-inline-block'>
                                Loan Officer
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-1'></div>

                        <div className='col-md-7'>
                          <div className='d-flex flex-row mb-2 justify-content-between align-items-center'>
                            <h5 className='font-weight-bold m-0'>Comments</h5>
                            {this.state.commentsEdit && (
                              <button
                                onClick={() => {
                                  this.setState({
                                    commentsEdit: !this.state.commentsEdit,
                                  });
                                }}
                                className='btn btn-primary btn-sm btn-round px-3'
                              >
                                Done
                              </button>
                            )}

                            {!this.state.commentsEdit && (
                              <button
                                onClick={() => {
                                  this.setState({
                                    commentsEdit: !this.state.commentsEdit,
                                  });
                                }}
                                className='btn btn-outline-primary btn-sm btn-round px-3'
                              >
                                Edit
                              </button>
                            )}
                          </div>
                          <div className='form-comments'>
                            {!this.state.commentsEdit && (
                              <div className='text-data'>
                                {this.state.comments}
                                {this.state.comments.trim() === "" && (
                                  <div className='h-100 flex-fill d-flex flex-row justify-content-center align-items-center'>
                                    <div className='text-muted'>
                                      Click Edit to add comment
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            {this.state.commentsEdit && (
                              <div className='print-hide'>
                                <div>
                                  <textarea
                                    className='w-100 bg-light form-control'
                                    value={this.state.comments}
                                    rows={3}
                                    onChange={(e) => {
                                      this.setState({
                                        comments: e.target.value,
                                      });
                                    }}
                                  ></textarea>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className='text-center print-hide'>
                        {/* {!this.state.acceptAgreement && ( */}
                        <div className='d-flex flex-row align-items-center'>
                          <label>
                            <input
                              type='checkbox'
                              className='mr-3 styled-checkbox'
                              id='342'
                              name=''
                              checked={this.state.acceptAgreement}
                              onChange={() => {
                                this.setState({
                                  acceptAgreement: !this.state.acceptAgreement,
                                });
                              }}
                            />
                            <div></div>
                          </label>
                          <div className='text-left mt-3'>
                            I declare that the information given herein is true
                            to the best of my knowledge and belief: I further
                            authorise the SACCO to verify the information given
                            herein and make reference from any
                            person(s)/institution(s) named herein.
                          </div>
                        </div>
                        {/* )} */}
                        {this.state.rejected && (
                          <div className='d-flex flex-row justify-content-between'>
                            <button
                              className='btn btn-outline-danger btn-round px-5 mt-5 print-hide'
                              onClick={() => this.setState({ rejected: false })}
                            >
                              Cancel
                            </button>
                            <button
                              className='btn btn-danger btn-round px-5 mt-5 print-hide'
                              onClick={this.approve}
                              disabled={!this.state.acceptAgreement}
                            >
                              Reject
                            </button>
                          </div>
                        )}

                        {this.state.approved && (
                          <div className='d-flex flex-row justify-content-between'>
                            <button
                              className='btn btn-outline-primary btn-round px-5 mt-5 print-hide'
                              onClick={() => this.setState({ approved: false })}
                            >
                              Cancel
                            </button>
                            <button
                              className='btn btn-primary btn-round px-5 mt-5 print-hide'
                              onClick={() => {
                                window.alert2.show({
                                  icon: <CheckCircle size={60}></CheckCircle>,
                                  title: "Approved Successfully",
                                });
                              }}
                              disabled={!this.state.acceptAgreement}
                            >
                              Approve
                            </button>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {!(this.state.approved || this.state.rejected) && (
                    <div className='text-center print-hide'>
                      <div className='d-flex flex-row justify-content-between'>
                        <button
                          className='btn btn-danger btn-round px-5 mt-5 print-hide'
                          onClick={() => {
                            this.setState({ rejected: true });
                          }}
                        >
                          Reject
                        </button>

                        <button
                          className='btn btn-primary btn-round px-5 mt-5 print-hide'
                          onClick={() => {
                            this.setState({ approved: true });
                          }}
                        >
                          Approve
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  generateUserInputs = () => {
    let data = {
      full_name: `${this.state.customer.firstname} ${this.state.customer.middlename} ${this.state.customer.surname}`,
      date_of_birth: moment(this.state.customer.date_of_birth).format(
        "Do MMMM YYYY"
      ),
      "Phone Number": this.state.customer.msisdn,
      KRA_pin: this.state.customer.kra_pin,
      // gender: ["Male", "Female"][this.state.customer.gender],
      village: this.state.customer.village,
      ID_number: this.state.customer.id_number,
      address: this.state.customer.address,
      nationality: this.state.customer.nationality,
      pledge: "KES " + parseInt(this.state.customer.pledge).toLocaleString(),
    };

    // console.log(data);

    return Object.keys(data).map((d) => {
      if (typeof data[d] === "object") return false;

      return (
        <div className='col-md-3 pb-3'>
          <div className='page-label text-capitalize'>
            {d.replace(/_/g, " ")}
          </div>
          <div>{data[d] || "-"}</div>
        </div>
      );
    });
  };

  generateLoanInputs = () => {
    let data = {
      "Loan Amount": "KES " + this.state.amount,
      product: this.state.product_id,
      repayment_duration: this.state.repayment_duration + " days",
      description: this.state.description,
      // officer_id: this.state.officer_id
    };

    // console.log(data);

    let obj = Object.keys(data).map((d) => (
      <div className='col-md-3 pb-3'>
        <div className='page-label text-capitalize'>{d.replace(/_/g, " ")}</div>
        <div>{data[d] || "-"}</div>
      </div>
    ));

    let d = this.state.officer;

    // obj.push(
    //   <>
    //     <div className="px-3">
    //       <div className="mb-2 font-weight-bold">Loan Officer</div>
    //       <div className="officer-card-parent text-input pr-3">
    //         <div className=" d-flex flex-row guarantor-card align-items-center">
    //           <div className="mx-3">
    //             <div className="user-profile">
    //               <span>{d.firstname[0]}</span>
    //             </div>
    //           </div>
    //           <div className="py-3 w-100">
    //             <div className="d-flex flex-row align-items-center justify-content-between">
    //               <h5 className="font-weight-bold">
    //                 {d.firstname} {d.middlename} {d.surname}{" "}
    //               </h5>
    //               {/* <div
    //                 className="action-icon"
    //                 onClick={() => {
    //                   let { details } = this.state;
    //                   details.officer_id = null;
    //                   this.setState({ details });
    //                 }}
    //               >
    //                 <XCircle></XCircle>
    //               </div> */}
    //             </div>
    //             <div className="d-flex flex-row align-items-center flex-fill">
    //               <div>
    //                 <small className="font-weight-bold">Nationa ID</small>
    //                 <div>{d.id_number}</div>
    //               </div>
    //               <div className="mx-3 content-divider"> </div>
    //               <div>
    //                 <small className="font-weight-bold">Phone Number</small>
    //                 <div>{d.msisdn}</div>
    //               </div>
    //             </div>
    //             <div className=" mt-2 d-flex flex-row justify-content-between align-items-center w-100">
    //               <div className="center">
    //                 <MapPin size={16} className="mr-2"></MapPin>
    //                 <span className="font-weight-bold">
    //                   {window.wardsList[d.ward_id]}
    //                 </span>
    //               </div>
    //               <div className="badge badge-primary px-3 d-inline-block">
    //                 Officer
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </>
    // );

    return obj;
  };

  get = (url, callback) => {
    fetch(`${window.server}${url}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        callback(response);
      })
      .catch((d) => {
        console.error(d);
      });
  };

  componentDidMount = () => {
    this.get(`/loan-request?id=${this.props.match.params.id}`, (response) => {
      this.setState({ ...response.data[0] });
      // console.log(response);

      this.get(`/customer?id=${this.state.customer_id}`, (response) => {
        this.setState({ customer: response.data[0] });
      });

      this.get(`/guarantors?`, (response) => {
        console.log(response);
        this.setState({ guarantors: response.data });
      });

      // this.get(
      //   `/maker-checker?checker_id=${this.state.checker_id}`,
      //   response => {
      //     this.setState({ checks: response.data[0].logs });
      //   }
      // );
    });
  };
}

export default LoanApprove;
