import React, { Component } from "react";
import logo from "../img/digital2.png";
import { User, Lock, Mail, Eye, EyeOff } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../components/modal";
import LButton from "../components/loadingButton";
import config from "../config";
import Countdown from "react-countdown";

class OTP extends Component {
  state = {
    custom: true,
    loading: false,
    OTP: "",
    otp_timeout: false,
    password: "",
    resetVisible: false,
    resetStatus: 0,
    OTPStatus: 0,
    showPassword: false,
    msisdn : '245743411403'
  };
  render() {
    return (
      <div className="d-flex flex-fill flex-column main-cover justify-content-center wallpaper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5 login-card">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="p-md-5 p-4">

                        <div className="text-center mb-4">
                          <small className="text-muted">Submit One Time Pin</small>
                        </div>
                        <form
                          onSubmit={e => {
                            e.preventDefault();
                            this.verifyOTP();
                          }}
                        >
                          <div className="form-group d-flex flex-row align-items-center">
                            <Mail
                              className="login-icon align-self-center position-absolute ml-2"
                              color="gray"
                              size={18}
                            />

                            <input
                              type="text"
                              className="form-control form-control-user  icon-input"
                              placeholder="OTP"
                              value={this.state.otp}
                              required={true}
                              onChange={e => {
                                this.setState({ OTP: e.target.value });
                              }}
                            />
                          </div>
                          <div className="text-center">
                            <div className="d-inline-block">
                              <LButton
                                text="OTP"
                                status={this.state.OTPStatus}
                              ></LButton>
                            </div>
                            <div className="d-inline-block" style={{margin:"10px"}}>
                              {/* { this.state.otp_timeout && 
                              <LButton
                              text="  Resend OTP"
                              status={this.state.OTPStatus}
                              onClick={(e) => {
                                e.preventDefault();
                                this.resendOTP()
                              }
                              }
                            ></LButton> } */}
                            <Countdown date={Date.now() + 60000}>
                                <LButton
                                  text="Resend OTP"
                                  status={this.state.OTPStatus}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.resendOTP()
                                  }
                                  }
                                ></LButton>
                            </Countdown>
                            </div>
                          </div>
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  verifyOTP = () => {
    let { OTP , msisdn } = this.state;
    if (!(OTP !== "")) {
      alert("Please fill in the OTP sent");
      return false;
    }

    this.setState({ OTPStatus: 1 });
    // let data = { name, email, password };
   // console.log("otp", OTP)
    fetch(window.server + "/utils/verify-otp", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      body: `{"otp": "${OTP}" , "msisdn": ${msisdn} ,"id": ${window.user.user.id}}`
    })
      .then(response => response.json())
      .then(response => {
        

        if (response.code) {
            window.alert2.notify({
                title: "OTP ",
                description: response.message,
                color: "material-deep-orange",
                autoClose: true,
                onClose: () => {
                    console.log("logout")
                },
              });
          this.setState({ OTPStatus: 0 });
        } else if (response) {
            //console.log("k" , response);
          let location = "/";
          if (localStorage.previousPath) location = localStorage.previousPath;
          window.location = location;
         // console.log(JSON.parse(localStorage.otp_verified))
          localStorage.otp_verified = 2
          this.setState({ OTPStatus: 2 });
        }
      })
      .catch((err) => {
          console.log(err)
        this.setState({ loading: false });
        alert("Something went wrong. Please try again.");
        this.setState({ OTPStatus: 0 });
      });
  };
  resendOTP = () => {
    const user = JSON.parse(localStorage.user);
    /* console.log("user", user.user.msisdn) */
    let  msisdn  = user.user.msisdn;
    if (!(msisdn !== "")) {
      alert("Missing msisdn");
      return false;
    }
    
    this.setState({ OTPStatus: 1 });
    // let data = { name, email, password };
    //console.log("otp", OTP)
    fetch(window.server + "/utils/send-otp", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
         Authorization: localStorage.token
      },
      body: `{"msisdn": ${msisdn}}`
    })
      .then(response => response.json())
      .then(response => {
        

        if (response) {
            window.alert2.notify({
                title: "Your OTP is invalid",
                description: response.message,
                color: "material-deep-orange",
                autoClose: true,
                onClose: () => {
                    console.log("logout")
                },
              });
          this.setState({ OTPStatus: 0 });
        }
      })
      .catch((err) => {
          //console.log(err)
        this.setState({ loading: false });
        alert("Something went wrong. Please try again.");
        this.setState({ OTPStatus: 0 });
      }); 
  };

}

export default OTP;
