import React, { Component } from "react";

class TableSM extends Component {
  state = { data: this.props.data };
  render() {
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            {this.state.data.length > 0 &&
              Object.keys(this.state.data[0]).map(d => (
                <th className="text-capitalize">{d.replace(/_/g, " ")}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {this.state.data.map(d => (
            <tr>
              {Object.keys(this.state.data[0]).map(d1 => {
                if (typeof d[d1] === "object")
                  return <td className="text-uppercas">-</td>;

                return <td className="text-uppercas">{d[d1]}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  componentDidUpdate = props => {
    if (JSON.stringify(this.state.data) !== JSON.stringify(this.props.data)) {
      this.setState({ data: this.props.data });
    }
  };
}

export default TableSM;
