import React, { Component } from 'react';
import Nav from '../../components/Nav';
import logo from '../../img/_logo.png';
import { Calendar } from 'react-feather';
import DatePicker from '../../components/datePicker';
import moment from 'moment';

class CashFlowStatement extends Component {
  state = {
    data: {
      'Cash Flow from Operations': [
        { name: 'Cash receipts from customers', amount: 86772 },
        { name: 'Cash paid for inventory', amount: -7400 },
        { name: 'Cash paid for wages', amount: -53000 }
      ],
      'Cash flow from Investing': [
        {
          name: 'Cash receipts from sale of property and equipment',
          amount: 13500
        },
        { name: 'cash paid for purchase of equipment', amount: 17500 }
      ],
      'Cash flow from Financing': [
        {
          name: 'Cash paid for loan repayment',
          amount: -5000
        },
        { name: 'cash paid for purchase of equipment', amount: 17500 }
      ]
    },
    showReport: false,
    dates: {
      startdate: null,
      enddate: null
    }
  };
  render() {
    return (
      <div className='d-flex flex-column justify-content-between page align-items-center print-block'>
        <div className='w-100'>
          <Nav
            name='Cash Flow Statement'
            buttons={[
              {
                text: 'Choose Date',
                onClick: () => this.setState({ showReport: false })
              },
              { text: 'Print', onClick: window.print }
            ]}></Nav>
        </div>

        {!this.state.showReport && (
          <div className='date-picker-card shadow p-4'>
            <h4 className='text-center font-weight-bold'>
              <Calendar className='icon'></Calendar> Select Date Range
            </h4>

            <div className='my-5 row'>
              <div className='col-md-6 text-center'>
                <div className='font-weight-bold mb-3'>Start Date</div>
                <div className='picker'>
                  <DatePicker
                    placeholder='choose start date'
                    date={this.state.startdate}
                    onChange={date => {
                      let { dates } = this.state;
                      dates.startdate = date;
                      this.setState({ dates });
                    }}></DatePicker>
                </div>
              </div>
              <div className='col-md-6 text-center'>
                <div className='font-weight-bold mb-3'>End Date</div>
                <div className='picker'>
                  <DatePicker
                    placeholder='choose end date'
                    date={this.state.startdate}
                    onChange={date => {
                      let { dates } = this.state;
                      dates.enddate = date;
                      this.setState({ dates });
                    }}></DatePicker>
                </div>
              </div>
            </div>

            <div className=' text-center'>
              <button
                className='btn btn-primary px-5 btn-round'
                onClick={() => this.setState({ showReport: true })}
                disabled={
                  !(this.state.dates.startdate && this.state.dates.enddate)
                }>
                Generate Report
              </button>
            </div>
          </div>
        )}

        {this.state.showReport && (
          <div className='virtual-form trial-balance-form d-flex flex-column justify-content-start page align-items-center'>
            <div className='w-100'>
              <div className='text-center'>
                <img src={logo} alt='' className='logo' />
                <h4 className='font-weight-bold my-3'>
                  SACCO MANAGEMENT SYSTEM
                </h4>
                <h5 className='font-weight-bold'>CASH FLOW STATEMENT</h5>
                <p className='font-weight-bold'>
                  {moment(this.state.dates.startdate).format('Do MMMM  YYYY')} -{' '}
                  {moment(this.state.dates.enddate).format('Do MMMM  YYYY')}
                </p>
              </div>
            </div>
            <div className='p-3 balance-sheet income-statement  bg-white mt-5'>
              {this.generateColumn(this.state.data)}
            </div>
            <div></div>
          </div>
        )}
        <div></div>
      </div>
    );
  }

  generateColumn = (assets, name) => {
    let cumulativeTotal = 0;

    let col = Object.keys(assets).map(d => {
      let data = assets[d];

      let total = 0;

      let o = data.map(d1 => {
        total += d1.amount;
        return (
          <>
            <div className='col-list'>
              <div className='p-2 col-list-item'>
                <div>{d1.name}</div>
                <div className='text-right'>
                  {Math.sign(d1.amount) === -1
                    ? '(' + Math.abs(d1.amount).toLocaleString() + ')'
                    : d1.amount.toLocaleString()}
                </div>
              </div>
            </div>
          </>
        );
      });

      o.unshift(<div className='col-subtitle'>{d}</div>);
      o.push(
        <>
          <div className='p-2 col-list-item cumulative-total border-top'>
            <div>Net {d}</div>
            <div className='text-right'>KES {total.toLocaleString()}</div>
          </div>
          <hr />
        </>
      );
      if (d === 'Expenses and Losses') {
        cumulativeTotal -= total;
      } else {
        cumulativeTotal += total;
      }
      return o;
    });

    // col.unshift(<div className='col-title'>{name}</div>);

    col.push(
      <>
        <hr />
        <div className='col-list-item p-2 cumulative-total border-top global-total position-relative w-auto'>
          <div>Net Increase In Cash</div>
          <div className='text-right'>
            KES {cumulativeTotal.toLocaleString()}
          </div>
        </div>
      </>
    );

    return col;
  };
}

export default CashFlowStatement;
