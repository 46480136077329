import React from "react";
import { useEffect, useState } from "react";
import logo from "../../img/logo.png";
import { UserCheck, Users, BookOpen, UserPlus, UserX } from "react-feather";
import Filter from "../../components/filter";
import moment from "moment";
export default function ChairmansReport() {
  const [chairmansData, setChairmansData] = useState([]);
  const [filter, setFilter] = useState({
    enddate: moment().format("YYYY-MM-DD"),
    startdate: moment().format("YYYY-MM-DD"),
  });
  const fetchData = () => {
    console.log("fetchData | picking ... ", filter.startdate);

    if (filter.startdate == undefined || filter.startdate == "") {
      filter.startdate = moment().format("YYYY-MM-DD");
    }
    fetch(
      `${window.server}/utils/admin/chairmansReport?startdate=${filter.startdate}&enddate=${filter.enddate}`,
      {
        headers: {
          Authorization: localStorage.token,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response.data[0]);

        if (response.code) {
        } else {
          setChairmansData(response.data[0]);
        }
      })
      .catch((d) => {
        console.error(d);
      });
  };
  useEffect(() => {
    fetchData();
    console.log("chairmans report");
  }, []);
  return (
    <div>
      <div className="px-3 my-5">
        <div className="d-flex flex-row align-items-center">
          <img src={logo} className="dasboard-logo mr-3" alt="" />
          <div>
            <h1 className="mb-0 font-weight-bold text-primary">
              DIGITAL SACCO
            </h1>
          </div>
        </div>
      </div>
      <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
        <div className="px-3">
          <Filter
            getFilter={(filter) => {
              setTimeout(() => {
                setFilter(filter);
                console.log("set filter");
                //fetchData();
              }, 3000);
            }}
            onChange={(ranges) => {
              setFilter({
                enddate: moment(ranges.startDate).format("YYYY-MM-DD"),
                startdate: moment(ranges.endDate).format("YYYY-MM-DD"),
              });
              fetchData();
            }}
            dateRange={["startdate", "enddate"]}
          />
          <div className="row mb-5">
            {/* total collections */}
            <div className="col-md-3 intro tab mt-3">
              <div className="card rounded p-3 d-flex flex-row align-items-center text-white material-green">
                <div className="icon-holder p-3 mr-3">
                  <UserCheck />
                </div>
                <div>
                  <div className="text-capitalize">Total collections</div>
                  <div className="mt-1 font-weight-bold">
                    <small>
                      <small> </small>
                    </small>{" "}
                    {chairmansData.total_amount_collected || 0}
                  </div>
                </div>
              </div>
            </div>
            {/* total principal */}
            <div className="col-md-3 intro tab mt-3">
              <div className="card rounded p-3 d-flex flex-row align-items-center text-white material-deep-purple">
                <div className="icon-holder p-3 mr-3">
                  <Users />
                </div>
                <div>
                  <div className="text-capitalize">
                    Total Principal Collected
                  </div>
                  <div className="mt-1 font-weight-bold">
                    <small>
                      <small> </small>
                    </small>{" "}
                    {chairmansData.total_principal_collected || 0}
                  </div>
                </div>
              </div>
            </div>
            {/* total interest */}
            <div className="col-md-3 intro tab mt-3">
              <div className="card rounded p-3 d-flex flex-row align-items-center text-white material-deep-orange">
                <div className="icon-holder p-3 mr-3">
                  <Users />
                </div>
                <div>
                  <div className="text-capitalize">
                    Total Interest Collected
                  </div>
                  <div className="mt-1 font-weight-bold">
                    <small>
                      <small> </small>
                    </small>{" "}
                    {chairmansData.total_interest_collected || 0}
                  </div>
                </div>
              </div>
            </div>
            {/* loans paid in advance*/}
            <div className="col-md-3 intro tab mt-3">
              <div className="card rounded p-3 d-flex flex-row align-items-center text-white material-blue">
                <div className="icon-holder p-3 mr-3">
                  <Users />
                </div>
                <div>
                  <div className="text-capitalize">Total Paid In advance</div>
                  <div className="mt-1 font-weight-bold">
                    <small>
                      <small> </small>
                    </small>{" "}
                    {chairmansData.total_loans_paid_in_advance || 0}
                  </div>
                </div>
              </div>
            </div>
            {/* loans paid after default*/}
            <div className="col-md-3 intro tab mt-3">
              <div className="card rounded p-3 d-flex flex-row align-items-center text-white  material-red">
                <div className="icon-holder p-3 mr-3">
                  <BookOpen />
                </div>
                <div>
                  <div className="text-capitalize">Total Paid on default</div>
                  <div className="mt-1 font-weight-bold">
                    <small>
                      <small> </small>
                    </small>{" "}
                    {chairmansData.total_loans_paid_on_default || 0}
                  </div>
                </div>
              </div>
            </div>
            {/* loans paid after default*/}
            <div className="col-md-3 intro tab mt-3">
              <div className="card rounded p-3 d-flex flex-row align-items-center text-white  material-pink">
                <div className="icon-holder p-3 mr-3">
                  <UserPlus />
                </div>
                <div>
                  <div className="text-capitalize">
                    Total Expected Principal
                  </div>
                  <div className="mt-1 font-weight-bold">
                    <small>
                      <small> </small>
                    </small>{" "}
                    {chairmansData.total_expected_principal || 0}
                  </div>
                </div>
              </div>
            </div>
            {/* loans paid after default*/}
            <div className="col-md-3 intro tab mt-3">
              <div className="card rounded p-3 d-flex flex-row align-items-center text-white  material-cyan">
                <div className="icon-holder p-3 mr-3">
                  <UserX />
                </div>
                <div>
                  <div className="text-capitalize">Total Expected Interest</div>
                  <div className="mt-1 font-weight-bold">
                    <small>
                      <small> </small>
                    </small>{" "}
                    {chairmansData.total_expected_interest || 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
