import React, { Component } from "react";

import Filter from "../../components/filter";
import { Plus, Circle, MapPin } from "react-feather";
import { Link } from "react-router-dom";
import moment from "moment";
import Nav from "../../components/Nav";

class LoanSummaries extends Component {
  state = {
    tableData: { data: [] },
    tableError: false,
    query: {},
    filter: {
      startdate: moment()
        .subtract(300, "days")
        .format("YYYY-MM-DD"),
      enddate: moment().format("YYYY-MM-DD")
    },
    summaries: {},
    // status: {
    //   all_loans: {
    //     total: 343500,
    //     amount: 23445500,
    //     color: "material-blue",
    //     value: 1
    //   },
    //   active_loans: {
    //     total: 343500,
    //     amount: 34525000,
    //     color: "material-green",
    //     value: 2
    //   },
    //   defaulted_loans: {
    //     total: 232340,
    //     amount: 3222300,
    //     color: "material-red",
    //     value: 1
    //   },
    //   prepaid_loans: {
    //     total: 4450,
    //     amount: 3343900,
    //     color: "material-purple",
    //     value: 1
    //   }
    // }
  };
  timeout = null;
  render() {
    return (
      <div>
        <Nav name="Loans"></Nav>

        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-3">
            <Filter
              getFilter={filter => {
                setTimeout(() => {
                  this.setState({
                    filter
                  });
                }, 0);
              }}
              dateRange={["startdate", "enddate"]}
            />

            <div className="px-3">
              <div className="row my-5 align-items-start justify-content-center ">
                {Object.keys(this.state.summaries).map(d => (
                  <Link
                    to={"/loans/" + this.state.summaries[d].value}
                    className="col-md-3 px-2 mb-3 icon bt loan-tab"
                  >
                    <div
                      className={
                        "card client-status text-white " +
                        this.state.summaries[d].color
                      }
                    >
                      <div className="card-header trg-header d-flex flex-row align-items-center justify-content-center">
                        {/* <Circle className='' /> */}
                        <span className="title font-weight-bold text-capitalize">
                          {d.replace("_", " ")}
                        </span>
                      </div>
                      <div className="card-body text-white text-center">
                        <h3 className="font-weight-bold">
                          {typeof this.state.summaries[d].total !==
                            "undefined" && (
                            <span>
                              {this.state.summaries[d].total.toLocaleString()}
                            </span>
                          )}
                          {typeof this.state.summaries[d].total ===
                            "undefined" && <br />}
                        </h3>
                        {typeof this.state.summaries[d].amount !== "undefined" && (
                          <span className="font-weight-bol">
                            KES {this.state.summaries[d].amount}
                          </span>
                        )}
                        {typeof this.state.summaries[d].amount === "undefined" && (
                          <br />
                        )}
                        {typeof this.state.summaries[d].pending !==
                          "undefined" && (
                          <>
                            <br />
                            <span className="font-weight-bold">
                              Balance : KES{" "}
                              {this.state.summaries[d].pending}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>

            <div className="col-md-6 my-5"></div>
          </div>
        </div>
      </div>
    );
  }

  fetchSales = () => {
    this.setState({ modalVisible: true });
    //console.log(this.state.totalsStartDate);
    fetch(
      `${window.server}/utils/loantotals?&startdate=${
        this.state.filter.startdate
      }&enddate=${moment(this.state.filter.enddate)
        .add(1, "day")
        .format("YYYY-MM-DD")}`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.token
        }
      }
    )
      .then(response => response.json())
      .then(response => {
        console.log(response);

        let summaries = [];

        response.data.map(d => {
          summaries.push({
            "pending_requests": {
                "total": d.all_loan_requests.total,
                "amount": d.all_loan_requests.amount,
                "value": 0,
                "color": "material-blue"
            },
            "active_loans": {
                "total": d.active_loans.total,
                "amount": d.active_loans.amount,
                "value": 2,
                "color": "material-green"
            },
            "cleared_loans": {
                "total": d.cleared_loans.total,
                "amount": d.cleared_loans.amount,
                 "value": 3,
                "color": "material-deep-purple"
            },
            "defaulted_loans": {
                "total": d.defaulted_loans.total,
                "amount": d.defaulted_loans.amount,
                 "value": 4,
                "color": "material-red"
            }
          });
        });
         
         console.log(summaries);
         response.data = summaries;
         this.setState({ summaries: summaries[0] });

      })
      .catch(d => {
        // //console.log("Error saving the data");
        console.error(d);
        this.setState({ modalVisible: false });
      });
  };

  componentDidMount() {
    this.fetchSales();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.state.filter) !== JSON.stringify(prevState.filter)
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function() {
        $t.fetchSales();
      }, 100);
    }
  }
}

export default LoanSummaries;
