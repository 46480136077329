import React, { Component } from "react";
import moment from "moment";
import { DollarSign, User, LogOut, MapPin, XCircle } from "react-feather";
import { Link } from "react-router-dom";

class Details extends Component {
  state = { data: {}, balanceInfo: {}, salaryVisible: false, exitInfo: {} };
  render() {
    return (
      <div className="p-3 mt-3">
        <div className="row">
          <div className="col-md-6">
            <ul className="list-group user-details loan-card ">
              <div className="p-3 bg-light font-weight-bold border-bottom">
                <DollarSign size={18}></DollarSign> Loan Details
              </div>
              {Object.keys(this.state.data).map((d) => (
                <li className="list-group-item d-flex flex-row  text-dark border-0">
                  <div className="user-detail-title font-weight-bold text-capitalize">
                    {d.replace(/_/g, " ")}
                  </div>
                  <div>{this.state.data[d]}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-6">
            {this.state.customer && (
              <>
                <div className="officer-card-parent mx-3 text-input mb-3">
                  <div className=" bg-light font-weight-bold d-flex flex-row justify-content-between align-items-center">
                    <h5 className="p-3 m-0 font-weight-bold">Client</h5>

                    <Link
                      to={`/member-view/details/${this.state.customer_id}`}
                      className="btn btn-outline-primary px-3 btn-round mr-3"
                    >
                      View
                    </Link>
                  </div>
                  <div className=" d-flex flex-row guarantor-card align-items-center">
                    <div className="mx-3">
                      <div className="user-profile">
                        <span className="position-absolute">
                          {this.state.customer.firstname[0]}
                        </span>
                      </div>
                    </div>
                    <div className="py-3 w-100 pr-3">
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <h5 className="font-weight-bold">
                          {this.state.customer.firstname}{" "}
                          {this.state.customer.middlename}{" "}
                          {this.state.customer.surname}{" "}
                        </h5>
                      </div>
                      <div className="d-flex flex-row align-items-center flex-fill">
                        <div>
                          <small className="font-weight-bold">Nationa ID</small>
                          <div>{this.state.customer.id_number}</div>
                        </div>
                        <div className="mx-3 content-divider"> </div>
                        <div>
                          <small className="font-weight-bold">
                            Phone Number
                          </small>
                          <div>{this.state.customer.msisdn}</div>
                        </div>
                      </div>
                      <div className=" mt-2 d-flex flex-row justify-content-between align-items-center w-100">
                        <div className="badge badge-primary px-3 d-inline-block">
                          client
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.officer && (
                  <div className="officer-card-parent mx-3 text-input mb-3">
                    <div className=" bg-light font-weight-bold d-flex flex-row justify-content-between align-items-center">
                      <h5 className="p-3 m-0 font-weight-bold">Officer</h5>

                      <Link
                        to={`/member-view/`}
                        className="btn btn-outline-primary px-3 btn-round mr-3"
                      >
                        View
                      </Link>
                    </div>
                    <div className=" d-flex flex-row guarantor-card align-items-center">
                      <div className="mx-3">
                        <div className="user-profile">
                          <span>{this.state.officer.firstname[0]}</span>
                        </div>
                      </div>
                      <div className="py-3 w-100 pr-3">
                        <div className="d-flex flex-row align-items-center justify-content-between">
                          <h5 className="font-weight-bold">
                            {this.state.officer.firstname}{" "}
                            {this.state.officer.middlename}{" "}
                            {this.state.officer.surname}{" "}
                          </h5>
                        </div>
                        <div className="d-flex flex-row align-items-center flex-fill">
                          <div>
                            <small className="font-weight-bold">
                              Nationa ID
                            </small>
                            <div>{this.state.officer.id_number}</div>
                          </div>
                          <div className="mx-3 content-divider"> </div>
                          <div>
                            <small className="font-weight-bold">
                              Phone Number
                            </small>
                            <div>{this.state.officer.msisdn}</div>
                          </div>
                        </div>
                        <div className=" mt-2 d-flex flex-row justify-content-between align-items-center w-100">
                          <div className="badge badge-primary px-3 d-inline-block">
                            Officer
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {this.state.rolloverData && (
              <div className="officer-card-parent mx-3 text-input mb-3">
                <div className=" bg-light font-weight-bold d-flex flex-row justify-content-between align-items-center">
                  <h5 className="p-3 m-0 font-weight-bold">
                    Roll over details
                  </h5>
                </div>
                <div className=" d-flex flex-row guarantor-card align-items-center">
                  <div className="py-3 w-100 pr-3">
                    <div className="d-flex flex-column ml-3 justify-content-between">
                      <div>
                        <small className="font-weight-bold">
                          loan default amount :
                        </small>
                        <div>
                          {" "}
                          {
                            this.state.rolloverData
                              .defaulted_amount_to_charge_rollover
                          }
                        </div>
                      </div>
                      <div>
                        <small className="font-weight-bold">
                          date defaulted :
                        </small>
                        <div>
                          {" "}
                          {this.state.rolloverData.loan_defaulted_date.format(
                            "Do MMMM YYYY, h:mm:ss a"
                          )}
                        </div>
                      </div>
                      <div>
                        <small className="font-weight-bold">
                          rollover charge :
                        </small>
                        <div>
                          {" "}
                          {this.state.rolloverData.roll_over_charge_on_default.toString()}{" "}
                          <small className="font-weight-bold pl-3">
                            {"("}
                            {this.state.rolloverData.roll_over_percentage.toString()}

                            {"%"}
                            {" )"}
                          </small>
                        </div>
                      </div>
                      <div>
                        <div>
                          <small className="font-weight-bold">
                            amount due after :
                          </small>
                        </div>{" "}
                        {this.state.rolloverData.total_amount_due_after.toString()}
                      </div>
                      <div>
                        <div>
                          <small className="font-weight-bold">
                            date rolled over:
                          </small>
                        </div>{" "}
                        {this.state.rolloverData.loan_roll_over_date.format(
                          "Do MMMM YYYY, h:mm:ss a"
                        )}
                      </div>
                      <div>
                        <div>
                          <small className="font-weight-bold">
                            new loan due date:
                          </small>
                        </div>{" "}
                        {this.state.rolloverData.new_loan_due_date.format(
                          "Do MMMM YYYY, h:mm:ss a"
                        )}
                      </div>

                      {/* {this.state.customer.middlename}{" "}
                        {this.state.customer.surname}{" "} */}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.recoveryData && (
              <div className="officer-card-parent mx-3 text-input mb-3">
                <div className=" bg-light font-weight-bold d-flex flex-row justify-content-between align-items-center">
                  <h5 className="p-3 m-0 font-weight-bold">Recovery details</h5>
                </div>
                <div className=" d-flex flex-row guarantor-card align-items-center">
                  <div className="py-3 w-100 pr-3">
                    <div className="d-flex flex-column ml-3 justify-content-between">
                      <div>
                        <small className="font-weight-bold">
                          Amount to recover :
                        </small>
                        <div> {this.state.recoveryData.amount_to_recover} </div>
                      </div>
                      <div>
                        <small className="font-weight-bold">
                          Recovery done after :
                        </small>
                        <div>
                          {" "}
                          {this.state.recoveryData.recovery_date.format(
                            "Do MMMM YYYY, h:mm:ss a"
                          )}
                        </div>
                      </div>
                      <div>
                        <small className="font-weight-bold">
                          recovery charge :
                        </small>
                        <div>
                          {this.state.recoveryData.recovery_charge.toString()}{" "}
                          <small className="font-weight-bold pl-3">
                            {"("}
                            {this.state.recoveryData.recovery_percentage.toString()}

                            {"%"}
                            {" )"}
                          </small>
                        </div>
                      </div>

                      {/* {this.state.customer.middlename}{" "}
                        {this.state.customer.surname}{" "} */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      id: this.props.match.params.id,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/loan-transactions?${urlParams}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);

        let data = [];
        let status_text = "";
        response.data.map((d, i) => {
          let status_text = {
            1: "Normal",
            2: "Defaulted",
            3: "Cancelled",
            0: "Pending",
          };
          let diff =
            d.clearance_status === 1
              ? d.request.repayment_duration
              : parseInt(
                  moment().diff(
                    new Date(d.account.date_created),
                    "months",
                    true
                  ) > d.request.repayment_duration
                    ? d.request.repayment_duration
                    : parseInt(
                        moment().diff(
                          new Date(d.account.date_created),
                          "months",
                          true
                        )
                      )
                );
          let loan_repayment_date = moment(d.account.date_created).add(
            d.request.repayment_duration,
            "months"
          );
          let repayment_schedule = d.repayment_schedule;
          let rolloverSchedule = repayment_schedule.filter((a) => {
            return a.narration.includes("Rollover Charge for loan Id ");
          });
          let recoverySchedule = repayment_schedule.filter((a) => {
            return a.narration.includes("Rollover Charge for loan Id"); //Recovery Charge for loan Id
          });
          if (rolloverSchedule.length > 0 /* d.loan_status == 2 */) {
            console.log("repayment_schedule", d.repayment_schedule);

            console.log("rolloverSchedule", rolloverSchedule);
            let loan_defaulted_date = moment(loan_repayment_date).add(
              1,
              "months"
            );
            let loan_roll_over_date = moment(loan_defaulted_date).add(3, "d");
            let roll_over_info = rolloverSchedule[0] || null;
            console.log("bbbbb", roll_over_info);
            let defaulted_amount_to_charge_rollover =
              parseFloat(roll_over_info ? roll_over_info.principal : 0) || 0;
            let roll_over_charge_on_default =
              parseFloat(roll_over_info ? roll_over_info.interest : 0) || 0;
            let roll_over_percentage = d.product.interest_rate || "N/A";
            let total_amount_due_after = parseFloat(
              defaulted_amount_to_charge_rollover + roll_over_charge_on_default
            ).toFixed(2);
            let new_loan_due_date = moment(loan_defaulted_date).add(
              1,
              "months"
            );
            this.state.rolloverData = {
              loan_defaulted_date,
              loan_roll_over_date,
              defaulted_amount_to_charge_rollover,
              roll_over_charge_on_default,
              roll_over_percentage,
              total_amount_due_after,
              new_loan_due_date,
            };
          }
          if (recoverySchedule.length > 0 /* d.loan_status == 2 */) {
            console.log("recoverySchedule", d.account);
            let amount_to_recover =
              parseFloat(d.account.commission_balance) +
              parseFloat(d.account.interest_balance) +
              parseFloat(d.account.other_interest_balance) +
              parseFloat(d.account.rollover_balance) +
              parseFloat(d.account.principal_balance);
            this.state.recoveryData = {
              amount_to_recover: this.state.rolloverData.total_amount_due_after,

              recovery_date: this.state.rolloverData.new_loan_due_date,
              recovery_charge: parseFloat(0.1 * amount_to_recover).toFixed(2),
              recovery_percentage: 10,
            };
          }
          data.push({
            // id: d.id,
            loan_id: d.id,
            loan_request_id: d.loan_request_id,
            customer_name: `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`,
            // product_id: d.product_id,
            amount:
              "KES " +
              parseFloat(parseFloat(d.request.amount)).toLocaleString(),
            interest_charged:
              diff < 1
                ? parseFloat(
                    parseFloat(d.request.amount * (d.interest_rate / 100) * 1)
                  ).toLocaleString()
                : parseFloat(
                    parseFloat(
                      (d.request.amount / diff) * (d.interest_rate / 100) * diff
                    )
                  ).toLocaleString(),
            processing_fee:
              "KES " +
              parseFloat(parseFloat(d.request.amount * 0.025)).toLocaleString(),
            repayment_duration: d.request.repayment_duration + " months",
            description: d.request.description,
            status:
              status_text[
                d.loan_status
              ] /* parseFloat(d.loan_status) === 1 ? "Normal" : "Defaulted", */,
            guarantor_status: ["Not Guaranteed", "Fully Guaranteed"][
              d.request.guarantor_status
            ],
            guaranteed_amount:
              "KES " +
              parseFloat(
                parseFloat(d.request.guaranteed_amount)
              ).toLocaleString(),
            loan_date: moment(d.account.date_created).format(
              "Do MMMM YYYY, h:mm:ss a"
            ),
            repayment_date: moment(d.account.date_created)
              .add(d.request.repayment_duration, "months")
              .format("Do MMMM YYYY, h:mm:ss a"),
            // loan_products: d["loan-products"]
          });
        });

        this.setState({ data: data[0], ...response.data[0] });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };
}

export default Details;
