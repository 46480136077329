/** @format */

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Grid,
  User as UsersIcon,
  DollarSign,
  Target,
  Menu as MenuBars,
  UserCheck,
  FileText,
  GitBranch,
  Users,
  LogIn,
  Upload,
  User,
  LogOut,
  Settings,
  Briefcase,
  ChevronDown,
  ChevronUp,
  MapPin,
  Tool,
  MessageSquare,
  Shield,
  List,
} from "react-feather";
import logo from "../img/logo.png";
import Access from "./accessManager";
import config from "../config";
import user from "../img/user.svg";

class Menu extends Component {
  state = {
    title: config.name,
    menu: [
      {
        name: "Dashboard",
        icon: <Grid size={20} />,
        link: "/",
        access: "DASHBOARD_MENU",
      },
      {
        name: "Chairmans Report",
        icon: <Grid size={20} />,
        link: "/chairmansReport",
        access: "CHAIRMANS_REPORT",
      },
      {
        name: "Dashboard2",
        icon: <Grid size={20} />,
        link: "/dashboard",
        access: "DASHBOARD_MENU_2",
      },
      {
        name: "Summaries",
        icon: <List size={20} />,
        link: "/summaries",
        access: "SUMMARIES_MENU",
        /* children: [
          {
            name: "SUMMARIES",
            link: "/summaries",
            access: "SUMMARIES_MENU",
          },
          {
            name: "ELECTION sUMMARIES",
            link: "/members",
            access: "SEARCH_MEMBER",
          },
        ] */
      },
      {
        name: "Member Details",
        icon: <Users size={20} />,
        link: "/users",
        access: "MEMBER_MENU",
        children: [
          {
            name: "Members",
            link: "/members",
            access: "SEARCH_MEMBER",
          },
          {
            name: "All Active Members",
            link: "/all-members",
            access: "ALL_MEMBER",
          },
          {
            name: "Pending Reg Requests",
            link: "/members-pending",
            access: "PENDING_REQUESTS",
          },
          {
            name: "Member Exit",
            link: "/exit-members",
            access: "MEMBER_EXITS",
          },
          {
            name: "Blocked Accounts",
            link: "/blocked-members",
            access: "BLOCKED_ACCOUNTS",
          },
          {
            name: "Suspended Accounts",
            link: "/suspended-members",
            access: "SUSPENDED_ACCOUNTS",
          },
          {
            name: "Linked Accounts",
            link: "/link-accounts",
            access: "LINK_ACCOUNT",
          },
        ],
      },
      {
        name: "Users",
        icon: <Users size={20} />,
        link: "/users",
        access: "USER_MENU",
        children: [
          {
            name: "Employees",
            link: "/employees",
            access: "EMPLOYEES",
          },
        ],
      },
      {
        name: "Agents",
        icon: <Users size={20} />,
        link: "/agents",
        access: "AGENTS_MENU",
        children: [
          {
            name: "Agents",
            link: "/agent_details",
            access: "AGENT_DETAILS",
          },
        ],
      },
      {
        name: "Loans",
        icon: <DollarSign size={20} />,
        link: "/loan-summaries",
        access: "LOAN_MENU",
        children: [
          {
            name: "Loan Summaries",
            link: "/loan-summarries",
            access: "LOAN_SUMMARIES",
          },
          {
            name: "Loan Requests",
            link: "/loan-requests",
            access: "LOAN_REQUESTS",
          },
          {
            name: "Mobile Loan Requests",
            link: "/mobile-loan-requests",
            access: "MOBILE_LOAN_REQUESTS",
          },
          {
            name: "Active Loans",
            link: "/loans",
            access: "ACTIVE_LOANS",
          },
          {
            name: "Loan Products",
            link: "/loan-products",
            access: "LOAN_PRODUCTS",
          },
          {
            name: "Loan Interest",
            link: "/loan-interest",
            access: "LOAN_INTEREST",
          },
        ],
      },
      {
        name: "Special Loans",
        icon: <DollarSign size={20} />,
        link: "/mobile-loans",
        access: "MOBILE_LOAN_MENU",
        children: [
          {
            name: "Mobile Loans",
            link: "/special-loans",
            access: "MOBILE_LOANS",
          },
          {
            name: "Loan Requests",
            link: "/special-loan-requests",
            access: "MOBILE_LOAN_REQUESTS",
          },
          {
            name: "Interest",
            link: "/special-loan-interest",
            access: "MOBILE_LOAN_INTEREST",
          },
          {
            name: "Guarantors",
            link: "/special-loan-guarantors",
            access: "MOBILE_LOAN_GUARANTOR",
          },
          {
            name: "Approve",
            link: "/special-loan-approve",
            access: "MOBILE_LOAN_APPROVE",
          },
          {
            name: "Transactions",
            link: "/special-loan-transactions",
            access: "MOBILE_LOAN_TRANSACTIONS",
          },
        ],
      },
      {
        name: "SMS",
        icon: <MessageSquare size={20} />,
        link: "/sms",
        access: "SMS_MENU",
      },
      {
        name: "Marketing",
        icon: <MessageSquare size={20} />,
        link: "/marketing",
        access: "MARKETING",
      },
      {
        name: "Finance",
        icon: <Briefcase size={20} />,
        link: "/finance",
        access: "FINANCE_MENU",
        children: [
          // {
          //   name: "Loan Payments",
          //   link: "/payments",
          //   access: "all"
          // },
          {
            name: "Petty Cash",
            link: "/petty",
            access: "PETTY_CASH",
          },
          {
            name: "Petty Cash Records",
            link: "/petty-records",
            access: "PETTY_RECORDS",
          },
          {
            name: "Mpesa Deposits",
            link: "/mpesa-deposits",
            access: "MPESA_DEPOSITS",
          },
          {
            name: "Checkoff",
            link: "/checkoff",
            access: "CHECKOFF",
          },
          {
            name: "Checkoff Approval",
            link: "/checkoff-approval",
            access: "APPROVE_CHECKOFF",
          },
          {
            name: "Disbursements",
            link: "/disbursements",
            access: "DISBURSEMENTS",
          },
          {
            name: "Suspense Account",
            link: "/suspense",
            access: "SUSPENSE_ACCOUNTS",
          },
          {
            name: "Approve Suspense Account",
            link: "/approve-suspense",
            access: "APPROVE_SUSPENSE_ACCOUNTS",
          },
          {
            name: "Chart of accounts",
            link: "/accounts-chart",
            access: "CHART_ACCOUNTS",
          },
          // {
          //   name: "Vendors",
          //   link: "/vendors",
          //   access: "all"
          // },
          // {
          //   name: "Invoices",
          //   link: "/invoices",
          //   access: "all"
          // },
          { type: "hr" },
          {
            name: "savings",
            link: "/savings",
            access: "SAVINGS",
          },
          {
            name: "shares",
            link: "/shares",
            access: "SHARES",
          },
        ],
      },
      {
        name: "Reconciliations",
        icon: <Tool size={20} />,
        link: "/recon",
        access: "RECON_MENU",
        children: [
          {
            name: "Deposits/C2B",
            link: "/deposits-c2b",
            access: "UPLOAD_DEPOSITS",
          },
          {
            name: "Approve Deposits",
            link: "/approve-deposits",
            access: "APPROVE_DEPOSITS",
          },
          {
            name: "Withdraws/B2C",
            link: "/withdraws-b2c",
            access: "UPLOAD_WITHDRAWALS",
          },
          {
            name: "Reconciliation",
            link: "/reconciliation-status",
            access: "RECON_STATUS",
          },
        ],
      },
      {
        name: "Sasra Reports",
        icon: <List size={20} />,
        link: "/sasra",
        access: "REPORT_MENU",
        children: [
          {
            name: "Savings Report",
            link: "/sasraSavingsReport",
            access: "EXIT_REPORT",
          },
          {
            name: "Loan Portfolio Report",
            link: "/loan-portfolio-report",
            access: "LOAN_PORTFOLIO_REPORT",
          },
          {
            name: "Loan Portfolio Summary",
            link: "/loan-portfolio-report-summary",
            access: "LOAN_PORTFOLIO_REPORT_SUMMARY",
          },
          {
            name: "Deposit Return  Report",
            link: "/deposit-return-report",
            access: "DEPOSIT_RETURN_REPORT",
          },
          {
            name: "Loan Ageing Report",
            link: "/loan-ageing-report",
            access: "LOAN_AGEING_REPORT",
          },
          {
            name: "Savings Share Report",
            link: "/savings-share-report",
            access: "EXIT_REPORT",
          },
          {
            name: "Extended Shares Report",
            link: "/extended-shares-report",
            access: "EXIT_REPORT",
          },
          {
            name: "Extended savings Report",
            link: "/extended-savings-report",
            access: "EXIT_REPORT",
          },
          {
            name: "Loan Classification Report",
            link: "/loanClassificationReport",
            access: "LOAN_CLASSIFICATION_REPORT",
          },
        ],
      },
      {
        name: "Before Migration",
        icon: <List size={20} />,
        link: "/old",
        access: "OLD_RECORDS_MENU",
        children: [
          {
            name: "Interest Accounts",
            link: "/old/InterestAccount",
            access: "OLD_INT_ACC_MENU",
          },
          {
            name: "Loan Accounts",
            link: "/old/LoanAccount",
            access: "OLD_LOAN_ACC_MENU",
          },
          {
            name: "Loan Records ",
            link: "/old/LoanRecords",
            access: "OLD_LOAN_RECORD_MENU",
          },
          {
            name: "Member Accounts",
            link: "/old/MemberAccounts",
            access: "OLD_MEMBER_ACC_MENU",
          },
          {
            name: "Queued Interest Accounts",
            link: "/old/QueuedInterestAccount",
            access: "OLD_QUEUED_INT_ACC_MENU",
          },
          {
            name: "Savnigs Accounts",
            link: "/old/SavingsAccount",
            access: "OLD_SAVINGS_ACC_MENU",
          },
          {
            name: "Deposits",
            link: "/old/Deposits",
            access: "OLD_DEPOSITS_MENU",
          },
        ],
      },
      {
        name: "Reports",
        icon: <List size={20} />,
        link: "/reports",
        access: "REPORT_MENU",
        children: [
          {
            name: "Registration Report",
            link: "/registration-report",
            access: "REGISTRATION_REPORT",
          },
          {
            name: "Shares Report",
            link: "/shares-report",
            access: "SHARES_REPORT",
          },
          {
            name: "Savings Report",
            link: "/savings-report",
            access: "SAVINGS_REPORT",
          },
          {
            name: "Mpesa Deposits Report",
            link: "/mpesa-report",
            access: "MPESA_REPORT",
          },
          {
            name: "Commissions Report",
            link: "/commissions-report",
            access: "COMMISSIONS_REPORT",
          },
          {
            name: "Withdrawal Report",
            link: "/withdrawal-report",
            access: "WITHDRAWAL_REPORT",
          },
          {
            name: "Loan Schedule",
            link: "/schedule-report",
            access: "LOAN_SCHEDULE_REPORT",
          },
          {
            name: "Loan Principal",
            link: "/principal-repaid-report",
            access: "PRINCIPAL_REPORT",
          },
          {
            name: "Loan Interest",
            link: "/interest-repaid-report",
            access: "INTEREST_REPORT",
          },
          {
            name: "Loan Rollover",
            link: "/loan-roll-over",
            access: "LOAN_ROLLOVER",
          },
          {
            name: "SMS Counts",
            link: "/sms-report",
            access: "SMS_REPORT",
          },
          {
            name: "Executive Summaries",
            link: "/executive-summary",
            access: "EXECUTIVE_REPORT",
          },
          {
            name: "Interest Repaid",
            link: "/interest-repaid-summary",
            access: "INTEREST_REPAID",
          },
          {
            name: "Principal Repaid",
            link: "/principal-repaid-summary",
            access: "PRINCIPAL_REPAID",
          },
          {
            name: "Defaulted Loans Report",
            link: "/defaulted-loans",
            access: "DEFAULTED_LOANS",
          },
          {
            name: "Defaulted Loans Extended Report ",
            link: "/defaulted-loans-extended",
            access: "DEFAULTED_LOANS_EXTENDED",
          },
          {
            name: "Recoveries",
            link: "/recoveries",
            access: "RECOVERIES_REPORT",
          },
          {
            name: "Recoveries Summary",
            link: "/recoveries-summary",
            access: "RECOVERIES_SUMMARY",
          },
          {
            name: "Loan Collection Report",
            link: "/loan-report",
            access: "LOAN_REPORT",
          },
          {
            name: "Loan Recovery Guarantor",
            link: "/loan-recovery-guarantor",
            access: "LOAN_RECOVERY_GUARANTOR",
          },
          {
            name: "Share conversion Report",
            link: "/share-conversion",
            access: "SHARE_CONVERSION_REPORT",
          },
        ],
      },
      {
        name: "Special Actions",
        icon: <Grid size={20} />,
        link: "/special-actions",
        access: "SPECIAL_ACTIONS",
      },
      {
        name: "Access Control",
        icon: <Shield size={20} />,
        link: "/admins",
        access: "ACCESS_MENU",
        children: [
          {
            name: "Groups",
            link: "/groups",
            access: "GROUPS",
          },
          {
            name: "Roles",
            link: "/roles",
            access: "ROLES",
          },
        ],
      },
      {
        name: "Settings",
        icon: <Settings size={20} />,
        link: "/settings",
        access: "SETTINGS_MENU",
        children: [
          {
            name: "Change Password",
            link: "/password",
            access: "CHANGE_PASSWORD",
          },
          {
            name: "Audit Trail",
            link: "/audit-trail",
            access: "AUDIT_TRAIL",
          },
        ],
      },
    ],
    currentRoute: window.location.pathname,
    active: 0,
  };
  render() {
    return (
      <>
        <div className="p-3 top-header d-md-none align-items-center d-flex flex-row bg-light w-100 text-dark shadow">
          <MenuBars
            className="mr-3"
            onClick={() => {
              this.setState({ showMenu: true });
            }}
          ></MenuBars>
          <div className="font-weight-bold top-bar-text">
            {this.state.title}
          </div>
        </div>
        <div
          className={"menu-overlay " + (this.state.showMenu ? "show" : "")}
          onClick={() => {
            this.setState({ showMenu: false });
          }}
        ></div>
        <div
          className={
            "sidebar-wrapper border-right shadow " +
            (this.state.showMenu ? "show" : "")
          }
          onClick={() => {
            this.setState({ showMenu: false });
          }}
        >
          <ul
            className="navbar-nav bg-light sidebar sidebar-dark accordion position-relative"
            id="accordionSidebar"
          >
            <div>
              <div className="d-flex flex-column user-login-card p-3 my-4 align-items-start">
                <div className="d-flex flex-row align-items-start justify-content-between w-100">
                  <div className="user-icon-parent">
                    <img src={user} className="user-icon" alt="" />
                  </div>
                  <div className="d-flex flex-row">
                    <div className="action-icon">
                      <Settings size={20}></Settings>
                    </div>

                    <div
                      className="action-icon"
                      onClick={() => window.logout()}
                    >
                      <LogOut size={20}></LogOut>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {window.user?.user?.firstname} {window.user?.user?.middlename}
                </div>
                <div>
                  <small className="font-weight-bold">| Super Admin</small>
                </div>
              </div>
            </div>

            <div className="sidebar-heading text-dark mb-2 text-muted">
              pages :
            </div>
            {this.state.menu.map((d, i) => {
              return (
                <li
                  className={
                    "nav-item " +
                    (this.state.active === i && d.children
                      ? "pb-4 no-hover "
                      : "") +
                    (this.state.currentRoute === d.link
                      ? "active"
                      : "text-dark")
                  }
                  key={i}
                >
                  <Access permission={d.access}>
                    {" "}
                    <Link
                      to={d.link}
                      onClick={(e) => {
                        if (this.state.active === i) {
                          this.setState({ active: 0 });
                        } else {
                          this.setState({ active: i });
                        }
                        if (d.children) e.preventDefault();
                      }}
                      className={
                        "nav-link " +
                        (this.state.currentRoute === d.link
                          ? "active"
                          : "text-dark")
                      }
                    >
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="d-flex flex-row align-items-center">
                          {d.icon}
                          <div className="text-dark ml-2 font-weight-bold link-text">
                            {d.name}
                          </div>
                        </div>
                        {d.children && (
                          <div>
                            {this.state.active !== i && (
                              <ChevronDown size={18}></ChevronDown>
                            )}
                            {this.state.active === i && (
                              <ChevronUp size={18}></ChevronUp>
                            )}
                          </div>
                        )}
                      </div>
                    </Link>
                  </Access>
                  {this.state.active === i && d.children && (
                    <div
                      id="collapseUtilities"
                      className="collapse show shadow-sm"
                      aria-labelledby="headingUtilities"
                      data-parent="#accordionSidebar"
                    >
                      <div className=" py-2 collapse-inner rounded mb-0">
                        {/* <h6 className='collapse-header'>listing:</h6> */}
                        {d.children.map((d1, i1) => {
                          if (d1.type === "hr")
                            return (
                              <>
                                <hr className={`${!d1.title || "mb-1"}`} />
                                {d1.title && (
                                  <div>
                                    <small className="text-muted text-uppercase font-weight-bold px-4">
                                      {d1.title}
                                    </small>
                                  </div>
                                )}
                              </>
                            );

                          return (
                            <Access permission={d1.access} key={i1}>
                              <Link
                                className={
                                  "collapse-item font-weight-bold my-1 text-capitalize " +
                                  (this.state.currentRoute === d1.link
                                    ? "active"
                                    : "text-dark")
                                }
                                to={d1.link}
                                key={i1}
                              >
                                {d1.name}
                              </Link>
                            </Access>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </li>
              );
            })}
            {/*  */}
            {/* <li className='nav-item'>
            <button
              className='nav-link d-flex flex-row align-items-center bg-transparent btn'
              onClick={() => {
                if (window.confirm("Are you sure you want to logout?")) {
                  localStorage.clear();
                  this.props.history.push("/login");
                }
              }}>
              <LogOut color='white' size={18} />
              <span className='text-white ml-2'>Logout</span>
            </button>
          </li> */}
          </ul>
        </div>
      </>
    );
  }

  componentWillReceiveProps(props) {
    if (props.history) {
      //   console.log(props.history);
      this.setState({ currentRoute: window.location.pathname });
    }
  }
}

export default withRouter(Menu);
