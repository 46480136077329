/** @format */

import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class MembersPending extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Joining Members'
          buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className='mt-3 table-card  border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Table
              search={["firstname", "middlename", "surname", "msisdn"]}
              sort='id'
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchMembersPending = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      status: 0,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/pending?${urlParams}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        let data = [];
        response.data.map((d, i) => {
          data.push({
            id: d.id,
            full_name: `${d.firstname} ${d.middlename} ${d.surname}`,
            phone_number: d.msisdn,
            iD_number: d.national_id,
            kyc_verification: [
              <button className='btn btn-danger btn-round pc-3'>
                Not Verified
              </button>,
            ][d.verified],

            join_date: moment(d.created_at).format("DD MMMM, YYYY.  HH:mm:ss"),
            action: (
              <Link
                to={"/member-approve/" + d.id}
                className='btn btn-sm btn-warning px-3 btn-round mr-3'
              >
                Approve
              </Link>
            ),
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchMembersPending();
      }, 100);
    }
  }
}

export default MembersPending;
