import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import Form from "../../components/form";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import "moment-timezone";
import Nav from "../../components/Nav";

class LoanPortfolioReport extends Component {
  state = {
    startValue: "",
    endValue: "",
    members: [],
    tableData: { data: [] },
    tableData2: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Loan Collections Report "
          // buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className="mt-3 border-0 card shado mx-3 shadow">
          <div className="text-center">
            <h4 className="font-weight-bold">Loan Collections Report </h4>
          </div>
          <div className="mt-3 align-items-center">
            <Form
              submitText={"Generate"}
              back={false}
              inputs={[
                {
                  label: "From",
                  name: "start",
                  type: "datetime-local",
                  value: this.state.startValue,
                },
                {
                  label: "Date",
                  name: "end",
                  type: "datetime-local",
                  value: this.state.endValue,
                },
              ]}
              submit={(data) => {
                //console.log(data);
                //this.setState({ details: data });
                setTimeout(() => {
                  this.handleGenerate(data);
                }, 0);
              }}
            />
          </div>
        </div>

        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              // search={["firstname", "middlename", "surname", "msisdn", "id_number"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  handleOnChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleGenerate = (data) => {
    console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    let values = Object.values(obj);
    let fdat = obj.start;
    let ldat = obj.end;
    let sdat = moment.utc(fdat).format("YYYY-MM-DD HH:mm:00");
    let edat = moment.utc(ldat).format("YYYY-MM-DD HH:mm:59");
    // let sdate = moment(fdat);
    // let edate = moment(ldat);
    // let sdat = sdate.tz('Africa/Nairobi').format('YYYY-MM-DD HH:mm:00');
    // let edat = edate.tz('Africa/Nairobi').format('YYYY-MM-DD HH:mm:59');

    // console.log(obj.start);
    //console.log(obj.end);

    //alert(values);
    let q = {
      ...this.state.filter,
      ...this.state.query,
      end: edat,
      start: sdat,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    //alert(values);
    fetch(`${window.server}/utils/admin/loan-standing-report?${urlParams}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        let data = [];
        var result_1 = response.data;
        result_1.map((d, i) => {
          data.push({
            loan_id: d.id,
            Customer: d.firstname + " " + d.middlename + " " + d.surname,
            customer_phone: d.msisdn,
            product_name: d.product_name,
            total_loan: parseFloat(
              parseFloat(d.Total).toFixed(2)
            ).toLocaleString(),
            //processing_fee_charged:d.processing_fee_charged ? d.processing_fee_charged : 0   ,
            expected_principal_amount: parseFloat(
              parseFloat(d.ExpectedPAmnt).toFixed(2)
            ).toLocaleString(),
            expected_interest_amount: parseFloat(
              parseFloat(d.ExpectedIAmnt).toFixed(2)
            ).toLocaleString(),
            //days_in_default:d.days_in_default ? d.days_in_default : 0,

            interest_paid: d.TotalInterestPaid
              ? parseFloat(
                  parseFloat(d.TotalInterestPaid).toFixed(2)
                ).toLocaleString()
              : 0,
            // penalty:d.penalty_fees_5_of_default ?  parseFloat(
            //   parseFloat(d.penalty_fees_5_of_default).toFixed(2)
            // ).toLocaleString() : 0,
            total_amount_repaid: d.repaid_amounts_total
              ? parseFloat(
                  parseFloat(d.repaid_amounts_total).toFixed(2)
                ).toLocaleString()
              : 0,
            //date_created: moment(d.date_created).format('Do MMMM YYYY, h:mm:ss a'),
            //next_payment_date: moment( d.next_payment_date ).format('Do MMMM YYYY, h:mm:ss a')
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.handleGenerate();
      }, 100);
    }
  }
}

export default LoanPortfolioReport;
