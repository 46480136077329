import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import Form from "../../components/form";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import "moment-timezone";
import Nav from "../../components/Nav";

class ScheduleReport extends Component {
  state = {
    startValue: "",
    endValue: "",
    members: [],
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Loan Schedule"
          // buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className="mt-3 border-0 card shado mx-3 shadow">
          <div className="text-center">
            <h4 className="font-weight-bold">Loan Schedule</h4>
          </div>
          <div className="mt-3 align-items-center">
            <Form
              submitText={"Generate"}
              back={false}
              inputs={[
                {
                  label: "From",
                  name: "start",
                  type: "datetime-local",
                  value: this.state.startValue,
                },
                {
                  label: "To",
                  name: "end",
                  type: "datetime-local",
                  value: this.state.endValue,
                },
              ]}
              submit={(data) => {
                //console.log(data);
                //this.setState({ details: data });
                setTimeout(() => {
                  this.handleGenerate(data);
                }, 0);
              }}
            />
          </div>
        </div>

        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              // search={["firstname", "middlename", "surname", "msisdn", "id_number"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  handleOnChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleGenerate = (data) => {
    console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    let values = Object.values(obj);
    let fdat = obj.start;
    let ldat = obj.end;
    let sdat = moment.utc(fdat).format("YYYY-MM-DD HH:mm:00");
    let edat = moment.utc(ldat).format("YYYY-MM-DD HH:mm:59");

    // let sdate = moment(fdat);
    // let edate = moment(ldat);
    // let sdat = sdate.tz('Africa/Nairobi').format('YYYY-MM-DD HH:mm:00');
    // let edat = edate.tz('Africa/Nairobi').format('YYYY-MM-DD HH:mm:59');

    console.log(obj.start);
    console.log(obj.end);

    //alert(values);
    // let q = {
    //    // ...this.state.filter,
    //    ...this.state.query,
    //    "$or[0][account_type_id]": 20
    //    // date_created: values[0],
    //    // date_created: values[1]
    //  };

    //  let urlParams = Object.entries(q)
    //    .map(e => e.join("="))
    //    .join("&");

    //alert(values);
    fetch(`${window.server}/utils/schedule-report?start=${sdat}&end=${edat}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        let data = [];

        response.data.map((d, i) => {
          data.push({
            // id: d.id,
            //  loan_id: d.loan_id,
            msisdn: d.msisdn,
            date_time: moment
              .utc(d.date_created)
              .format("DD MMMM, YYYY.  HH:mm:ss"),

            Loan_type: d.product_name,
            "duration (M)": d.repayment_duration,
            principal: parseFloat(parseFloat(d.principal)).toLocaleString(),
            interest: parseFloat(
              parseFloat(d.interest * d.repayment_duration)
            ).toLocaleString(),
            processing_fee: parseFloat(
              parseFloat(d.principal * 0.025)
            ).toLocaleString(),
            //description: d.narration
          });
        });
        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    let $t = this;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      $t.handleGenerate();
    }, 100);
  }
}

export default ScheduleReport;
