import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Edit2, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Form from "../../components/form";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class ApproveSuspense extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    repostModal: false,
    appRepostModal: false,
    appCancelRepostModal: false,
    reversalModal: false,
    appReversalModal: false,
    appCancelReversalModal: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;

  render() {
    return (
      <div className="">
        <Nav name="Approve Suspense Account"></Nav>
        <div className="mt-3 border-0 card shado mx-3 shadow">
          <div className="text-center">
            <h4 className="font-weight-bold">Approve Suspense Account</h4>
          </div>
          <div className="mt-3 align-items-center">
            <Form
              key={this.state.activeDetails}
              submitText={"Generate"}
              back={false}
              inputs={[
                {
                  label: "From",
                  name: "start",
                  type: "datetime-local",
                  value: this.state.startValue,
                },
                {
                  label: "To",
                  name: "end",
                  type: "datetime-local",
                  value: this.state.endValue,
                },
                {
                  label: "msisdn",
                  name: "msisdn",
                  type: "number",
                  required: false,
                  value: this.state.msisdn,
                },
                {
                  label: "Status",
                  name: "status",
                  value: this.state.status,
                  type: "select",
                  options: [
                    { name: "Pending Repost", value: 302 },
                    { name: "Pending Reversal", value: 300 },
                    { name: "Approved", value: 500 },
                    { name: "All", value: " " },
                  ],
                },
              ]}
              submit={(data) => {
                //console.log(data);
                //this.setState({ details: data });
                setTimeout(() => {
                  this.handleGenerate(data);
                }, 0);
              }}
            />
          </div>
        </div>

        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              search={["msisdn"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>

        <Modal
          visible={this.state.appCancelReversalModal}
          close={() => this.setState({ appCancelReversalModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Cancel Reversal</h5>
          </div>
          {typeof this.state.activeDetails !== "undefined" && (
            <div className="mt-3">
              <Form
                key={this.state.activeDetails}
                submitText={"Reversal"}
                back={false}
                inputs={[
                  {
                    label: "Id",
                    name: "id",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails].id,
                  },
                  {
                    label: "Payment Ref No",
                    name: "payment_ref_no",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .payment_ref_no,
                  },
                  {
                    label: "Comment",
                    name: "comment",
                    type: "textarea",
                    placeholder: "comment",
                    defaultValue: "",
                    value: "",
                    required: true,
                  },
                  {
                    label: "Confirm Pin",
                    name: "user_pin",
                    type: "password",
                    placeholder: "enter your user password",
                    value: this.state.user_pin,
                  },
                ]}
                submit={(data) => {
                  //console.log(data);
                  this.setState({ details: data });
                  setTimeout(() => {
                    this.CancelReversal(data);
                  }, 0);
                }}
              />
            </div>
          )}
        </Modal>

        <Modal
          visible={this.state.appReversalModal}
          close={() => this.setState({ appReversalModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Approve Reversal</h5>
          </div>
          {typeof this.state.activeDetails !== "undefined" && (
            <div className="mt-3">
              <Form
                key={this.state.activeDetails}
                submitText={"Approve Reversal"}
                back={false}
                inputs={[
                  {
                    label: "Id",
                    name: "id",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails].id,
                  },
                  {
                    label: "Payment Ref No",
                    name: "payment_ref_no",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .payment_ref_no,
                  },
                  {
                    label: "Comment",
                    name: "comment",
                    type: "textarea",
                    placeholder: "comment",
                    defaultValue: "",
                    value: "",
                    required: true,
                  },
                  {
                    label: "Confirm Pin",
                    name: "user_pin",
                    type: "password",
                    placeholder: "enter your user password",
                    value: this.state.user_pin,
                  },
                ]}
                submit={(data) => {
                  //console.log(data);
                  this.setState({ details: data });
                  setTimeout(() => {
                    this.approveReversal(data);
                  }, 0);
                }}
              />
            </div>
          )}
        </Modal>

        <Modal
          visible={this.state.appCancelRepostModal}
          close={() => this.setState({ appCancelRepostModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Cancel Repost</h5>
          </div>
          {typeof this.state.activeDetails !== "undefined" && (
            <div className="mt-3">
              <Form
                key={this.state.activeDetails}
                submitText={"Cancel Repost"}
                back={false}
                inputs={[
                  {
                    label: "Id",
                    name: "id",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails].id,
                  },
                  {
                    label: "Old Phone Number",
                    name: "msisdn",
                    type: "tel",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails].msisdn,
                  },
                  {
                    label: "New Phone Number",
                    name: "new_msisdn",
                    type: "tel",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .new_msisdn,
                  },
                  {
                    label: "transaction Id",
                    name: "transaction_id",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .transaction_id,
                  },
                  {
                    label: "Payment Ref No",
                    name: "payment_ref_no",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .payment_ref_no,
                  },
                  {
                    label: "Amount",
                    name: "credit",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails].credit,
                  },
                  // {
                  //   label: "Repost Date",
                  //   name: "repost_date",
                  //   type: "date",
                  //   value: this.state.repost_date
                  // },
                  {
                    label: "Comment",
                    name: "comment",
                    type: "textarea",
                    placeholder: "comment",
                    value: " ",
                    required: true,
                  },
                  {
                    label: "Confirm Pin",
                    name: "user_pin",
                    type: "password",
                    placeholder: "enter your user password",
                    value: this.state.user_pin,
                  },
                ]}
                submit={(data) => {
                  //console.log(data);
                  this.setState({ details: data });
                  setTimeout(() => {
                    this.CancelRepost(data);
                  }, 0);
                }}
              />
            </div>
          )}
        </Modal>

        <Modal
          visible={this.state.appRepostModal}
          close={() => this.setState({ appRepostModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Approve Repost</h5>
          </div>
          {typeof this.state.activeDetails !== "undefined" && (
            <div className="mt-3">
              <Form
                key={this.state.activeDetails}
                submitText={"Repost"}
                back={false}
                inputs={[
                  {
                    label: "Id",
                    name: "id",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails].id,
                  },
                  {
                    label: "Old Phone Number",
                    name: "msisdn",
                    type: "tel",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails].msisdn,
                  },
                  {
                    label: "New Phone Number",
                    name: "new_msisdn",
                    type: "tel",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .new_msisdn,
                  },
                  {
                    label: "transaction Id",
                    name: "transaction_id",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .transaction_id,
                  },
                  {
                    label: "Payment Ref No",
                    name: "payment_ref_no",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .payment_ref_no,
                  },
                  {
                    label: "Amount",
                    name: "credit",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails].credit,
                  },
                  {
                    label: "Comment",
                    name: "comment",
                    type: "textarea",
                    placeholder: "comment",
                    value: " ",
                    required: true,
                  },
                  // {
                  //   label: "Repost Date",
                  //   name: "repost_date",
                  //   type: "date",
                  //   value: this.state.repost_date
                  // },
                  {
                    label: "Confirm Pin",
                    name: "password",
                    type: "password",
                    placeholder: "enter your user password",
                    value: this.state.user_pin,
                  },
                ]}
                submit={(data) => {
                  //console.log(data);
                  this.setState({ details: data });
                  setTimeout(() => {
                    this.approveRepost(data);
                  }, 0);
                }}
              />
            </div>
          )}
        </Modal>
      </div>
    );
  }
  handleGenerate = (data) => {
    console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    //let values = Object.values(obj);
    let fdat = obj.start;
    let ldat = obj.end;
    let sts = obj.status;
    let sdat = moment.utc(fdat).format("YYYY-MM-DD HH:mm:00");
    let edat = moment.utc(ldat).format("YYYY-MM-DD HH:mm:59");
    const query_param = obj.msisdn || "";
    const msisdn = query_param.replaceAll("%", "");
    console.log(obj.start);
    console.log(obj.end);
    console.log(sts);
    console.log(obj.msisdn);

    // alert(sdat);
    // alert(edat);
    fetch(
      `${window.server}/utils/suspense-account-temp?start=${sdat}&end=${edat}&status=${sts}&msisdn=${msisdn}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.token,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        let data = [];
        console.log(response.data[1]);
        response.data.map((d, i) => {
          let status_text = {
            302: "Repost",
            505: "Reversal",
          };
          data.push({
            id: d.id,
            "Phone Number": d.msisdn,
            "New Phone NUmber": d.new_msisdn,
            transaction_id: d.transaction_id,
            credit: d.credit,
            debit: d.debit,
            payment_ref_no: d.payment_ref_no,

            //payment_account_type: d.payment_account_type,
            status: status_text[d.status],
            comment: d.comment,
            time: moment(d.date_created).format("Do MMMM YYYY, h:mm:ss a"),
            action: (
              <div className="d-flex flex-row">
                {d.status == "505" ? (
                  <>
                    <button
                      onClick={() => {
                        this.setState({
                          appCancelReversalModal: true,
                          activeDetails: i,
                        });
                        console.log(this.state.response.data[i]);
                      }}
                      className="btn btn-sm btn-info px-3 btn-round"
                    >
                      Cancel Reversal
                    </button>
                    <button
                      onClick={() => {
                        this.setState({
                          appReversalModal: true,
                          activeDetails: i,
                        });
                        console.log(this.state.response.data[i]);
                      }}
                      className="btn btn-sm btn-success px-3 btn-round"
                    >
                      Approve Reversal
                    </button>
                  </>
                ) : (
                  ""
                )}
                {d.status == "302" ? (
                  <>
                    <button
                      onClick={() => {
                        this.setState({
                          appCancelRepostModal: true,
                          activeDetails: i,
                        });
                        //console.log(this.state.response.data[0]);
                      }}
                      className="btn btn-sm btn-info px-4 btn-round"
                    >
                      Cancel Repost
                    </button>
                    <button
                      onClick={() => {
                        this.setState({
                          appRepostModal: true,
                          activeDetails: i,
                        });
                        //console.log(this.state.response.data[0]);
                      }}
                      className="btn btn-sm btn-success px-4 btn-round"
                    >
                      Approve Repost
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            ),
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };
  fetchSuspense = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    console.log(urlParams);
    fetch(`${window.server}/utils/suspense-account-temp?${urlParams}`, {
      /* fetch(`${window.server}/suspense?${urlParams}`, { */
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        let data = [];
        response.data.map((d, i) => {
          let status_text = {
            302: "Repost",
            505: "Reversal",
          };
          data.push({
            id: d.id,
            "Phone Number": d.msisdn,
            transaction_id: d.transaction_id,
            credit: d.credit,
            debit: d.debit,
            payment_ref_no: d.payment_ref_no,
            //payment_account_type: d.payment_account_type,
            status: status_text[d.status],
            comment: d.comment,
            time: moment(d.date_created).format("Do MMMM YYYY, h:mm:ss a"),
            action: (
              <div className="d-flex flex-row">
                {d.status == "505" ? (
                  <>
                    <button
                      onClick={() => {
                        this.setState({
                          appCancelReversalModal: true,
                          activeDetails: i,
                        });
                        console.log(this.state.response.data[i]);
                      }}
                      className="btn btn-sm btn-info px-3 btn-round"
                    >
                      Cancel Reversal
                    </button>
                    <button
                      onClick={() => {
                        this.setState({
                          appReversalModal: true,
                          activeDetails: i,
                        });
                        console.log(this.state.response.data[i]);
                      }}
                      className="btn btn-sm btn-success px-3 btn-round"
                    >
                      Approve Reversal
                    </button>
                  </>
                ) : (
                  ""
                )}
                {d.status == "302" ? (
                  <>
                    <button
                      onClick={() => {
                        this.setState({
                          appCancelRepostModal: true,
                          activeDetails: i,
                        });
                        //console.log(this.state.response.data[0]);
                      }}
                      className="btn btn-sm btn-info px-4 btn-round"
                    >
                      Cancel Repost
                    </button>
                    <button
                      onClick={() => {
                        this.setState({
                          appRepostModal: true,
                          activeDetails: i,
                        });
                        //console.log(this.state.response.data[0]);
                      }}
                      className="btn btn-sm btn-success px-4 btn-round"
                    >
                      Approve Repost
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            ),
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  CancelRepost = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to cancel the repost of this transaction?"
      )
    )
      return false;
    console.log(data);

    let postData = data;
    fetch(`${window.server}/utils/admin/cancel-repost`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
        } else {
          console.log(response);
          alert("Suspense account repost cancelled successfully");
          let $t = this;
          clearTimeout(this.timeout);
          this.timeout = setTimeout(function () {
            $t.fetchSuspense();
          }, 100);
          this.setState({ uploadSuccessful: true });
          this.setState({ appCancelRepostModal: false });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ appCancelRepostModal: false });
      });
  };

  approveRepost = (data) => {
    // if (
    //   !window.confirm("Are you sure that you want to Repost this transaction?")
    // )
    //   return false;
    let localuser = JSON.parse(localStorage.getItem("user"));
    //console.log("localuser", localuser.user.email);
    data.email = localuser.user.email;
    this.setState({ modalVisible: true });

    let postData = data;
    fetch(`${window.server}/utils/admin/approve-repost`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
        } else {
          console.log(response);
          alert("Suspense account approved successfully");
          let $t = this;
          clearTimeout(this.timeout);
          this.timeout = setTimeout(function () {
            $t.fetchSuspense();
          }, 100);
          this.setState({ uploadSuccessful: true });
          this.setState({ appRepostModal: false });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ repostModal: false });
      });
  };

  CancelReversal = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to cancel the reversal this transaction?"
      )
    )
      return false;

    console.log(data);
    this.setState({ modalVisible: true });

    let postData = data;
    fetch(`${window.server}/utils/admin/cancel-reversal`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
        } else {
          console.log(response);
          alert("Suspense account  reversal  cancelled successfully");
          let $t = this;
          clearTimeout(this.timeout);
          this.timeout = setTimeout(function () {
            $t.fetchSuspense();
          }, 100);
          this.setState({ uploadSuccessful: true });
          this.setState({ appCancelReversalModal: false });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ appCancelReversalModal: false });
      });
  };

  approveReversal = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to Approve Reversal of this transaction?"
      )
    )
      return false;

    console.log(data);
    this.setState({ modalVisible: true });

    let postData = data;
    fetch(`${window.server}/utils/admin/approve-reversal`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
        } else {
          console.log(response);
          alert("Suspense account reversed successfully");
          let $t = this;
          clearTimeout(this.timeout);
          this.timeout = setTimeout(function () {
            $t.fetchSuspense();
          }, 100);
          this.setState({ uploadSuccessful: true });
          this.setState({ appReversalModal: false });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ appReversalModal: false });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchSuspense();
      }, 100);
    }
  }
}

export default ApproveSuspense;
