import React, { Component } from "react";
import Nav from "../../components/Nav";
import { Route } from "react-router-dom";

class AdressBookCreate extends Component {
  state = {
    contacts: [
      {
        name: "Simon Imani",
        msisdn: "07176383343"
      },
      {
        name: "Simon Imani",
        msisdn: "07176383343"
      },
      {
        name: "Simon Imani",
        msisdn: "07176383343"
      },
      {
        name: "Simon Imani",
        msisdn: "07176383343"
      },
      {
        name: "Simon Imani",
        msisdn: "07176383343"
      },
      {
        name: "Simon Imani",
        msisdn: "07176383343"
      },
      {
        name: "Simon Imani",
        msisdn: "07176383343"
      }
    ]
  };
  render() {
    return (
      <div>
        <Nav name="Create an Address Book"></Nav>
        <div className="mt-3 table-card sms-table-card  border-0 card shado mx-3 shadow p-4 ">
          <Route
            path="/address-book-create/1"
            render={() => (
              <div>
                <h3 className="mb-5">1. Address Book Details</h3>

                <form
                  onSubmit={e => {
                    e.preventDefault();
                    this.props.history.push("/address-book-create/2");
                  }}
                >
                  <div class="mx-3 d-inline-block mb-3">
                    <span class="ml-1 mb-2 d-block font-weight-bold">
                      Address Book Name{" "}
                    </span>
                    <input
                      placeholder="..."
                      class="form-control bg-light sms-input"
                      required={true}
                    />
                  </div>

                  <div></div>

                  <div class="mx-3 d-inline-block mb-3">
                    <span class="ml-1 mb-2 d-block font-weight-bold">
                      {" "}
                      Address Book Description
                    </span>
                    <textarea
                      placeholder="..."
                      class="form-control bg-light sms-input"
                      rows={5}
                      required={true}
                    ></textarea>
                  </div>

                  <hr />
                  <div className="px-3">
                    <button className="btn btn-primary btn-round px-5">
                      Next
                    </button>
                  </div>
                </form>
              </div>
            )}
          ></Route>

          <Route
            path="/address-book-create/2"
            render={() => (
              <div>
                <div className="d-flex flex-row align-items-center justify-content-between mb-5">
                  <h3 className="mb-0">2. Address Book Contacts</h3>

                  <div className="d-flex flex-row">
                    <button className="btn btn-primary btn-round px-3 ml-2">
                      Add Members
                    </button>
                    <button className="btn btn-primary btn-round px-3 ml-2">
                      Add Employees
                    </button>
                    <button className="btn btn-primary btn-round px-3 ml-2">
                      Add a custom contact
                    </button>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <div className="contacts-list card w-100">
                    {this.state.contacts.map((d, i) => (
                      <div
                        className="p-3 border-bottom d-flex flex-row align-items-center"
                        key={i}
                      >
                        <div>
                          <div className="contact-symbol d-flex flex-row align-items-center justify-content-center mr-4">
                            {d.name[0]}
                          </div>
                        </div>
                        <div>
                          <h5 className="m-0">{d.name}</h5>
                          <div className="font-weight-bold">{d.msisdn}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          ></Route>
        </div>
      </div>
    );
  }
}

export default AdressBookCreate;
