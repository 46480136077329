import React, { Component } from "react";
import moment from "moment";
import Chart from "../components/chart";
import Filter from "../components/filter";
// import Loans from '../components/loans';
// import Agents from '../components/agents';
import { Link } from "react-router-dom";
import logo from "../img/logo.png";

import {
  Users,
  BookOpen,
  Book,
  UserCheck,
  UserMinus,
  UserPlus,
  UserX,
  User,
} from "react-feather";

class Home extends Component {
  state = {
    chartDefaults: {
      label: "Total users",
      fill: true,
      borderColor: "#4e73df",
      borderCapStyle: "square",
    },
    totalsEndDate: moment().format("YYYY-MM-DD"),
    totalsStartDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    dashboardInfo: {},
    // records: [],
    filter: {
      enddate: moment().add(1, "days").format("YYYY-MM-DD"),
      starttdate: moment().format("YYYY-MM-DD"),
    },
    performanceFilter: {},
    SalesDataset: [],
    LoansDataset: [],
    AgentsDataset: [],
  };
  render() {
    return (
      <div>
        <div className="px-3 my-5">
          <div className="d-flex flex-row align-items-center">
            <img src={logo} className="dasboard-logo mr-3" alt="" />
            <div>
              <h1 className="mb-0 font-weight-bold text-primary">
                DIGITAL SACCO
              </h1>
            </div>
          </div>
        </div>

        {/* <div className="p-3 d-flex flex-row justify-content-between align-items-center d-flex flex-row align-items-center'">
        <h2 className='font-weight-bold'>Dashboard</h2>
        </div> */}
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
        <div className="px-3">
        <Filter
              getFilter={filter => {
                setTimeout(() => {
                  this.setState({
                    filter
                  });
                }, 0);
              }}
              dateRange={["startdate", "enddate"]}
            />

          <div className="row mb-5">
            <div className="col-md-3 intro tab">
              <div className="card rounded p-3 d-flex flex-row align-items-center text-white material-blue">
                <div className="icon-holder p-3 mr-3">
                  <Users />
                </div>
                <div>
                  <div className="text-capitalize">All Members</div>
                  <div className="mt-1 font-weight-bold">
                    <small>
                      <small> </small>
                    </small>{" "}
                    {this.state.dashboardInfo.total_members}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 intro tab">
              <div className="card rounded p-3 d-flex flex-row align-items-center text-white material-green">
                <div className="icon-holder p-3 mr-3">
                  <UserCheck />
                </div>
                <div>
                  <div className="text-capitalize">
                    Fully Registered Members
                  </div>
                  <div className="mt-1 font-weight-bold">
                    <small>
                      <small> </small>
                    </small>{" "}
                    {this.state.dashboardInfo.total_fully_reg_members}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 intro tab">
              <div className="card rounded p-3 d-flex flex-row align-items-center text-white material-deep-purple">
                <div className="icon-holder p-3 mr-3">
                  <User />
                </div>
                <div>
                  <div className="text-capitalize">Blocked Members</div>
                  <div className="mt-1 font-weight-bold">
                    <small className="opacity-0">
                      <small> </small>
                    </small>{" "}
                    {this.state.dashboardInfo.total_blocked_members}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 intro tab">
              <div className="card rounded p-3 d-flex flex-row align-items-center text-white material-red">
                <div className="icon-holder p-3 mr-3">
                  <UserMinus />
                </div>
                <div>
                  <div className="text-capitalize">Total Member Exits</div>
                  <div className="mt-1 font-weight-bold">
                    <small>
                      <small> </small>
                    </small>{" "}
                    {this.state.dashboardInfo.total_exit_members}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-3 intro tab">
              <div className="card rounded p-3 d-flex flex-row align-items-center text-white material-pink">
                <div className="icon-holder p-3 mr-3">
                  <UserX />
                </div>
                <div>
                  <div className="text-capitalize">Suspended Members</div>
                  <div className="mt-1 font-weight-bold">
                    <small>
                      <small> </small>
                    </small>{" "}
                    {this.state.dashboardInfo.total_suspended_members}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 intro tab">
              <div className="card rounded p-3 d-flex flex-row align-items-center text-white material-deep-orange">
                <div className="icon-holder p-3 mr-3">
                  <UserPlus />
                </div>
                <div>
                  <div className="text-capitalize">Pending Members</div>
                  <div className="mt-1 font-weight-bold">
                    <small>
                      <small> </small>
                    </small>{" "}
                    {this.state.dashboardInfo.total_pending_members}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 intro tab">
              <div className="card rounded p-3 d-flex flex-row align-items-center text-white material-cyan">
                <div className="icon-holder p-3 mr-3">
                  <BookOpen />
                </div>
                <div>
                  <div className="text-capitalize">Total Loan Borrowed</div>
                  <div className="mt-1 font-weight-bold">
                    <small>
                      <small> </small>
                    </small>{" "}
                    {this.state.dashboardInfo.borrowed_loan_count}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 intro tab">
              <div className="card rounded p-3 d-flex flex-row align-items-center text-white material-light-green">
                <div className="icon-holder p-3 mr-3">
                  <Book />
                </div>
                <div>
                  <div className="text-capitalize">Total Loans Repaid</div>
                  <div className="mt-1 font-weight-bold">
                    <small className="opacity-0">
                      <small> </small>
                    </small>{" "}
                    {this.state.dashboardInfo.repaid_loan_count}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Chart
                title="Mpesa Records"
                updateFilter={(filter) => {
                  this.setState({ performanceFilter: filter });
                  //console.log(filter);
                }}
                datasets={this.state.SalesDataset}
              />
            </div>
            <div className="col-md-6">
              <Chart
                title="Savings"
                updateFilter={(filter) => {
                  this.setState({ performanceFilter: filter });
                  //console.log(filter);
                }}
                datasets={this.state.SalesDataset}
              />
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-md-6">
              <Chart
                title="Interest Records"
                updateFilter={(filter) => {
                  this.setState({ performanceFilter: filter });
                  //console.log(filter);
                }}
                datasets={this.state.SalesDataset}
              />
            </div>
            <div className="col-md-6">
              <Chart
                title="Loans"
                updateFilter={(filter) => {
                  this.setState({ performanceFilter: filter });
                  //console.log(filter);
                }}
                datasets={this.state.SalesDataset}
              />
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-md-6">
              <Chart
                title="Shares"
                updateFilter={(filter) => {
                  this.setState({ performanceFilter: filter });
                  //console.log(filter);
                }}
                datasets={this.state.SalesDataset}
              />
            </div>
            <div className="col-md-6">
              <Chart
                title="Registration Fee"
                updateFilter={(filter) => {
                  this.setState({ performanceFilter: filter });
                  //console.log(filter);
                }}
                datasets={this.state.SalesDataset}
              />
            </div>
          </div>

          {/* comment here */}
        </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.fetchDashboardBreakdown();
    this.fetchChart();
  }

  fetchDashboardBreakdown = () => {
    fetch(`${window.server}/utils/charts/dashbord-summary`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        let dashboardInfo = [];

        response.data.map((d) => {
          dashboardInfo.push({
            total_members: parseFloat(
              parseFloat(d.total_members)
            ).toLocaleString(),
            total_fully_reg_members: parseFloat(
              parseFloat(d.total_fully_reg_members)
            ).toLocaleString(),
            total_blocked_members: parseFloat(
              parseFloat(d.total_blocked_members)
            ).toLocaleString(),
            total_suspended_members: parseFloat(
              parseFloat(d.total_suspended_members)
            ).toLocaleString(),
            total_dormant_members: parseFloat(
              parseFloat(d.total_dormant_members)
            ).toLocaleString(),
            total_exit_members: parseFloat(
              parseFloat(d.total_exit_members)
            ).toLocaleString(),
            total_pending_members: parseFloat(
              parseFloat(d.total_pending_members)
            ).toLocaleString(),
            borrowed_loan_count: parseFloat(
              parseFloat(d.borrowed_loan_count)
            ).toLocaleString(),
            repaid_loan_count: parseFloat(
              parseFloat(d.repaid_loan_count)
            ).toLocaleString(),
          });
        });

        response.result = dashboardInfo;
        console.log(dashboardInfo);
        this.setState({
          dashboardInfo:
            typeof dashboardInfo[0] === "undefined" ? "--" : dashboardInfo[0],
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  fetchChart = () => {
    fetch(`${window.server}/utils/charts/dashbord-summary'`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        if (response.code) {
        } else {
          let interest_gained = [];

          // response.map(d => {
          //   interest_gained.push({ t: new Date(d.date), y: d.interest_gained });
          // });

          // let datasets = [
          //   {
          //     label: 'Interest',
          //     fill: false,
          //     data: interest_gained,
          //     backgroundColor: '#4caf50',
          //     borderColor: '#4caf50',
          //     borderWidth: 1
          //   }
          // ];

          // this.setState({ SalesDataset: datasets });
        }
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.performanceFilter }) !==
      JSON.stringify({ ...prevState.performanceFilter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchChart();
      }, 100);
    }
  }
}

export default Home;
