import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class MemberPayments extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              search={["firstname"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchMemberPayments = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      id: this.props.match.params.id,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/loan-transactions?${urlParams}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        let data = [];
        console.log("f", response.data[0]);
        response.data.map((d, i) => {
          let diff =
            d.clearance_status === 1
              ? d.request.repayment_duration
              : parseInt(
                  moment().diff(
                    new Date(d.account.date_created),
                    "months",
                    true
                  ) > d.request.repayment_duration
                    ? d.request.repayment_duration
                    : parseInt(
                        moment().diff(
                          new Date(d.account.date_created),
                          "months",
                          true
                        )
                      )
                );

          data.push({
            load_id: d.id,
            loan_request_id: d.loan_request_id,
            //customer_name: `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`,
            loan_type: d.product.product_name,
            // loan_type: d.product_name,
            amount: parseFloat(parseFloat(d.request.amount)).toLocaleString(),
            /* interest_charged: parseFloat(
              parseFloat(d.request.amount * 0.05)
            ).toLocaleString(), */
            interest_charged:
              diff < 1
                ? parseFloat(
                    parseFloat(d.request.amount * (d.interest_rate / 100) * 1)
                  ).toLocaleString()
                : parseFloat(
                    parseFloat(
                      d.request.amount * ((d.interest_rate / 100) * diff)
                    )
                  ).toLocaleString(),
            processing_fee: parseFloat(
              parseFloat(d.request.amount * 0.025)
            ).toLocaleString(),
            amount_paid: parseFloat(
              parseFloat(d.account.amount_paid)
            ).toLocaleString(),
            principal_balance: parseFloat(
              parseFloat(d.account.principal_balance)
            ).toLocaleString(),
            interest_balance: parseFloat(
              parseFloat(d.account.interest_balance)
            ).toLocaleString(),
            commission_balance: parseFloat(
              parseFloat(d.account.commission_balance)
            ).toLocaleString(),
            other_interest_balance: parseFloat(
              parseFloat(d.account.other_interest_balance)
            ).toLocaleString(),
            penalty_balance: parseFloat(
              parseFloat(d.account.penalty_balance)
            ).toLocaleString(),
            rollover_balance: parseFloat(
              parseFloat(d.account.rollover_balance)
            ).toLocaleString(),
            outstanding_balance: parseFloat(
              parseFloat(d.account.principal_balance) +
                parseFloat(d.account.interest_balance) +
                parseFloat(d.account.commission_balance) +
                parseFloat(d.account.other_interest_balance) +
                parseFloat(d.account.penalty_balance) +
                parseFloat(d.account.rollover_balance)
            ).toLocaleString(),
            payment_status: ["Not cleared", "Cleared"][d.clearance_status],
            repayment_duration: d.request.repayment_duration + " months",
            // status: d.status,
            // status: parseFloat(d.loan_status) === 1 ? "Normal" : "Defaulted",
            // guarantor_status: ["Not Guaranteed", "Fully Guaranteed"][
            //   d.request.guarantor_status
            // ],
            // guaranteed_amount: "KES " +
            //   parseFloat(
            //     parseFloat(d.request.guaranteed_amount)
            //   ).toLocaleString(),
            // loan_products: d["loan-products"],
            loan_date: moment(d.account.date_created).format(
              "Do MMMM YYYY, h:mm:ss a"
            ),
            repayment_date: moment(d.account.date_created)
              .add(d.request.repayment_duration, "months")
              .format("Do MMMM YYYY, h:mm:ss a"),
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchMemberPayments();
      }, 100);
    }
  }
}

export default MemberPayments;
