import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import Form from "../../components/form";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import "moment-timezone";
import Nav from "../../components/Nav";

class RecoverySummary extends Component {
  state = {
    startValue: "",
    endValue: "",
    members: [],
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Recovery Summaries"
          // buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className="mt-3 border-0 card shado mx-3 shadow">
          <div className="text-center">
            <h4 className="font-weight-bold">Recovery Summaries</h4>
          </div>
          <div className="mt-3 align-items-center">
            <Form
              submitText={"Generate"}
              back={false}
              inputs={[
                {
                  label: "From",
                  name: "start",
                  type: "datetime-local",
                  value: this.state.startValue,
                },
                {
                  label: "To",
                  name: "end",
                  type: "datetime-local",
                  value: this.state.endValue,
                },
              ]}
              submit={(data) => {
                //console.log(data);
                //this.setState({ details: data });
                setTimeout(() => {
                  this.handleGenerate(data);
                }, 0);
              }}
            />
          </div>
        </div>

        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              // search={["firstname", "middlename", "surname", "msisdn", "id_number"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  handleOnChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleGenerate = (data) => {
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    let values = Object.values(obj);
    let fdat = obj.start;
    let ldat = obj.end;

    console.log(obj.start);
    console.log(obj.end);

    fetch(`${window.server}/utils/recovery-summary?start=${fdat}&end=${ldat}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("res", response.data[0]);

        let summaries = [];
        response.data.map((d, i) => {
          summaries.push({
            "Loan Type": d.product_name,
            "No. of Loans": d.total_recoveries,
            "Total Principal Balance": d.total_principal_balance,
            "Total Interest Balance": d.total_interest_bal,
            "Total Other Interest Balance": d.total_other_interest_bal,
            "Total commission Balance": d.total_commission_bal,
            "Total rollover Balance": d.total_rollover_bal,
            "Total penalty Balance": d.total_penalty_bal,
            "Total Principal recovered": d.total_principal_rec,
            "Total Interest Recovered": d.total_interest_rec,
            "Total commission Recovered ": d.total_commission_rec,
            "Total Other Interest Recovered": d.total_other_int_rec,
            "Total Penalty  Recovered": d.total_penalty_rec,
            "Total Rollover Recovered": d.total_rollover_rec,
          });
        });
        console.log(summaries);
        let dts = {};
        dts.data = summaries;

        this.setState({
          tableData: { dts, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    let $t = this;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      $t.handleGenerate();
    }, 100);
  }
}

export default RecoverySummary;
