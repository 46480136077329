import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import Form from "../../components/form";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import "moment-timezone";
import Nav from "../../components/Nav";

class RecoveryGuarantor extends Component {
  state = {
    startValue: "",
    endValue: "",
    members: [],
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Recovery Guarantors"
          // buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className="mt-3 border-0 card shado mx-3 shadow">
          <div className="text-center">
            <h4 className="font-weight-bold">Recovery Guarantors</h4>
          </div>
          <div className="mt-3 align-items-center">
            <Form
              submitText={"Generate"}
              back={false}
              inputs={[
                {
                  label: "From",
                  name: "start",
                  type: "datetime-local",
                  value: this.state.startValue,
                },
                {
                  label: "To",
                  name: "end",
                  type: "datetime-local",
                  value: this.state.endValue,
                },
              ]}
              submit={(data) => {
                //console.log(data);
                //this.setState({ details: data });
                setTimeout(() => {
                  this.handleGenerate(data);
                }, 0);
              }}
            />
          </div>
        </div>

        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              // search={["firstname", "middlename", "surname", "msisdn", "id_number"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                if (this.state.start) {
                  this.handleGenerate({
                    start: this.state.start,
                    end: this.state.end,
                  });
                }
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  handleOnChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleGenerate = (data) => {
    console.log("data ......", data);
    if (data) {
      this.setState({ table_loading: true });
    }
    //this.setState({ table_loading: true });
    console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    let values = Object.values(obj);
    let fdat = obj.start;
    let ldat = obj.end;
    this.setState({ start: obj.start, end: obj.end });
    let sdat = moment.utc(fdat).format("YYYY-MM-DD HH:mm:00");
    let edat = moment.utc(ldat).format("YYYY-MM-DD HH:mm:59");

    let q = {
      // ...this.state.filter,
      ...this.state.query,
    };
    /*  q["$limit"] = 100000; */
    console.log("q", q);
    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    console.log("urlParams", urlParams);

    console.log(obj.start);
    console.log(obj.end);

    fetch(
      `${window.server}/utils/loan_recovery_guarantors?start=${fdat}&end=${ldat}&${urlParams}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.token,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("res", response.data[0]);

        let loan_recovery_guarantors = [];
        response.data.map((d, i) => {
          loan_recovery_guarantors.push({
            "Loan ID": d.loan_id,
            "Principal Amount": d.principal_amount,
            "Borrower customer ID": d.borrower_customer_id,
            "Guarantor customer ID": d.guarantor_customer_id,
            "Guaranteed amount": d.guaranteed_amount,
            "Amount recovered from Guarantor": d.guarantor_rec_amount,
            "Loan Balance after recovery": d.total_rec_amount,
            "Date created": d.date_created,
          });
        });
        console.log(loan_recovery_guarantors);
        let dts = {};
        dts.data = loan_recovery_guarantors;

        this.setState({
          tableData: { dts, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };

  /*  componentDidUpdate(prevProps, prevState) {
    let $t = this;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      $t.handleGenerate();
    }, 100);
  } */
}

export default RecoveryGuarantor;
