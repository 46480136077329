/** @format */

import React, { Component } from "react";
import Table from "../../components/Table";
import moment from "moment";
import Nav from "../../components/Nav";
import Form from "../../components/form";
import Modal from "../../components/modal";
import { Plus, Target, Circle, Edit2, Smartphone } from "react-feather";
class LinkedAccounts extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    cancelModal: false,
    ApprovalModal: false,
    searchCondition: 0
  };
  timeout = null;
  render() {
    return (
      <>
        <div className="">
          <Nav name="Linked Accounts"></Nav>
          <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
            <div className="p-4">
              <div>
                <select
                  placeholder="..."
                  class="form-control bg-light sms-input"
                  required=""
                  onChange={(e) => {
                    this.setState({ searchCondition: parseInt(e.target.value)});
                    setTimeout(() => {
                      this.fetchLinkedAccounts()
                    }, 1000);
                    
                  }}
                >
                  <option value="0" selec>
                    Filter By Status
                  </option>
                  <option value="0">Waiting Action</option>
                  <option value="1">Approved</option>
                  <option value="2">Cancelled</option>
                </select>
              </div>
              <Table
                search={["national_id", "msisdn"]}
                sort="id"
                sortDirection={-1}
                data={this.state.tableData}
                fetch={(params) => {
                  this.setState({ query: params });
                }}
                loading={this.state.table_loading}
                fetchError={this.state.tableError}
              />
            </div>
          </div>
        </div>

        <Modal
          visible={this.state.cancelModal}
          close={() => this.setState({ cancelModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Cancel Linking</h5>
          </div>
          {typeof this.state.activeDetails !== "undefined" && (
            <div className="mt-3">
              <Form
                key={this.state.activeDetails}
                submitText={"Cancel Linking"}
                back={false}
                inputs={[
                  {
                    label: "Id",
                    name: "id",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails].id,
                  },
                  {
                    label: "Old Phone Number",
                    name: "existing_msisdn",
                    type: "tel",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .existing_msisdn,
                  },
                  {
                    label: "New Phone Number",
                    name: "new_msisdn",
                    type: "tel",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .new_msisdn,
                  },
                  {
                    label: "national Id",
                    name: "national_id",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .national_id,
                  },
                  {
                    label: "Comment",
                    name: "approver_comment",
                    type: "textarea",
                    placeholder: "comment",
                    value: " ",
                    required: true,
                  },
                  {
                    label: "Confirm Pin",
                    name: "user_pin",
                    type: "password",
                    placeholder: "enter your user password",
                    value: this.state.user_pin,
                  },
                ]}
                submit={(data) => {
                  //console.log(data);
                  this.setState({ details: data });
                  data.status = 2;
                  setTimeout(() => {
                    this.CancelLinking(data);
                  }, 0);
                }}
              />
            </div>
          )}
        </Modal>
        <Modal
          visible={this.state.ApprovalModal}
          close={() => this.setState({ ApprovalModal: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Approve Linking</h5>
          </div>
          {typeof this.state.activeDetails !== "undefined" && (
            <div className="mt-3">
              <Form
                key={this.state.activeDetails}
                submitText={"Approve Linking"}
                back={false}
                inputs={[
                  {
                    label: "Id",
                    name: "id",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails].id,
                  },
                  {
                    label: "Old Phone Number",
                    name: "existing_msisdn",
                    type: "tel",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .existing_msisdn,
                  },
                  {
                    label: "New Phone Number",
                    name: "new_msisdn",
                    type: "tel",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .new_msisdn,
                  },
                  {
                    label: "national Id",
                    name: "national_id",
                    type: "text",
                    readonly: "readonly",
                    value:
                      this.state.response.data[this.state.activeDetails]
                        .national_id,
                  },
                  {
                    label: "Comment",
                    name: "approver_comment",
                    type: "textarea",
                    placeholder: "comment",
                    value: " ",
                    required: true,
                  },
                  {
                    label: "Confirm Pin",
                    name: "user_pin",
                    type: "password",
                    placeholder: "enter your user password",
                    value: this.state.user_pin,
                  },
                ]}
                submit={(data) => {
                  //console.log(data);
                  this.setState({ details: data });
                  setTimeout(() => {
                    data.status = 1;
                    this.approveLinking(data);
                  }, 0);
                }}
              />
            </div>
          )}
        </Modal>
      </>
    );
  }

  fetchLinkedAccounts = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      //status: 0 // fto fetch only the initiated ones
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    console.log("condition " , parseInt(this.state.searchCondition));
    fetch(`${window.server}/accounts-link-temp?status=${parseInt(this.state.searchCondition)}&${urlParams}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        let status_text = {
          0: "Waiting Action",
          1: "Approved",
          2: "Cancelled",
        };
        let data = [];

        response.data.sort((a, b) => {
          return a.status - b.status;
        });

        response.data.map((d, i) => {
          console.log("d", d);
          data.push({
            id: d.id,
            existing_msisdn: d.existing_msisdn,
            new_msisdn: d.new_msisdn,
            iD_number: d.national_id,
            initiator_comment: d.initiator_comment,
            status: status_text[d.status],
            action: (
              <div className="d-flex flex-row">
                {d.status == 0 ? (
                  <>
                    <button
                      onClick={() => {
                        this.setState({ cancelModal: true, activeDetails: i });
                        console.log(this.state.response.data[i]);
                      }}
                      className="btn btn-sm btn-info px-3 btn-round"
                    >
                      Cancel Linking
                    </button>
                    <button
                      onClick={() => {
                        this.setState({
                          ApprovalModal: true,
                          activeDetails: i,
                        });
                        console.log(this.state.response.data[i]);
                      }}
                      className="btn btn-sm btn-success px-3 btn-round"
                    >
                      Approve Linking
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            ),
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  CancelLinking = (data) => {
    if (
      !window.confirm(
        "Are you sure that you want to cancel the repost of this transaction?"
      )
    )
      return false;
    console.log(data);

    let postData = data;
    fetch(`${window.server}/utils/admin/approve-account-link`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
        } else {
          console.log(response);
          alert("Account Linking cancelled successfully");
          this.setState({ uploadSuccessful: true });
          this.setState({ appCancelRepostModal: false });
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ appCancelRepostModal: false });
      });
  };

  approveLinking = (data) => {
    if (
      !window.confirm("Are you sure that you want to Repost this transaction?")
    )
      return false;
    console.log(data);

    let postData = data;
    postData.password = postData.user_pin;
    fetch(`${window.server}/utils/admin/approve-account-link`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
        } else {
          console.log(response);
          alert("Account Linking  approved successfully");
          this.setState({ uploadSuccessful: true });
          this.setState({ appRepostModal: false });
          this.setState({ ApprovalModal: false });
          this.fetchLinkedAccounts();
        }
      })
      .catch((d) => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ repostModal: false });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchLinkedAccounts();
      }, 100);
    }
  }
}

export default LinkedAccounts;
