import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";
import Form from "../../components/form";

class MobileLoanRequests extends Component {
  state = {
    startValue: "",
    endValue: "",
    mobileLoans: [],
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Mobile Loan Requests"
          // buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className="mt-3 border-0 card shado mx-3 shadow">
          <div className="text-center">
            <h4 className="font-weight-bold">Mobile Loans Requests </h4>
          </div>
          <div className="mt-3 align-items-center">
            <Form
              submitText={"Generate"}
              back={false}
              inputs={[
                {
                  label: "From",
                  name: "start",
                  type: "datetime-local",
                  value: this.state.startValue,
                },
                {
                  label: "To",
                  name: "end",
                  type: "datetime-local",
                  value: this.state.endValue,
                },
              ]}
              submit={(data) => {
                //console.log(data);
                //this.setState({ details: data });
                setTimeout(() => {
                  this.fetchMobileLoanRequests(data);
                }, 0);
              }}
            />
          </div>
        </div>

        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              // search={["firstname", "middlename", "surname", "msisdn", "id_number"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchMobileLoanRequests = (data) => {
    console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      start: obj.start,
      end: obj.end,
    };

    let urlParams = Object.entries(q)
      .map((e) => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/utils/simu/loan_requests?${urlParams}`, {
      headers: {
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        let data = [];
        let status_text = "";
        response.data.map((d, i) => {
          let status_text = {
            0: "Pending",
            1: "Payment made :- waiting collection",
            2: "Cancelled",
            3: "Processed :- Phone collected",
          };

          data.push({
            // id: d.id,
            customer_id: d.customer_id,
            phone_name: d.phone_name,
            phone_model: d.phone_model,
            loan_request_id: d.loan_request_id,
            phone_retail_price: d.phone_amount,
            deposit_amount:
              "KES " + parseFloat(parseFloat(d.amount)).toLocaleString(),
            phone_retail_price:
              "KES " +
              parseFloat(parseFloat(d.phone_retail_price)).toLocaleString(),
            daily_payment:
              "KES " + parseFloat(parseFloat(d.daily_payment)).toLocaleString(),
            weekly_payment:
              "KES " +
              parseFloat(parseFloat(d.weekly_payment)).toLocaleString(),
            monthly_payment:
              "KES " +
              parseFloat(parseFloat(d.monthly_payment)).toLocaleString(),
            phone_collected: d.collection_otp_utilized == 1 ? "Yes" : "No",

            repayment_duration: d.repayment_duration + " days",
            status:
              status_text[
                d.status
              ] /* parseFloat(d.loan_status) === 1 ? "Normal" : "Defaulted", */,
            date_created: moment(d.date_created).format(
              "Do MMMM YYYY, h:mm:ss a"
            ),
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchMobileLoanRequests();
      }, 100);
    }
  }
}

export default MobileLoanRequests;
