/** @format */

import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import Form from "../../components/form";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class Members extends Component {
  state = {
    searchValue: "",
    // search_by: "",
    showPhone: false,
    showId: false,
    members: [],
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };

  // onChangeSearchBy = e => {
  //   this.setState({ search_by: e.target.value });
  //   alert(JSON.stringify({ search_by: e.target.value }))
  // };
  // onChangeSearchBy(e) {
  //   e.preventDefault();
  //   if (e.target.value === "1") {
  //     this.setState({ showPhone: true, showId: false })
  //     } else if (e.target.value === '2') {
  //     this.setState({ showPhone: false, showId: false })
  //     }
  //     //this.setState({ entitled_type: e.target.value })
  //   // this.setState({ agent_no: e.target.value })
  // }

  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
        // name="Members"
        // buttons={[{ text: "Add a Member", link: "member-add/1" }]}
        ></Nav>
        <div className='mt-3 border-0 card shado mx-3 shadow'>
          <div className='text-center'>
            <h4 className='font-weight-bold'>Welcome to Member Search</h4>
          </div>
          <div className='mt-3'>
            {/* <form>
              <div className="mx-3 d-inline-block mb-3">
                <span className="ml-1 mb-2 d-block text-capitalize">Search By</span>
                  <select className="form-control bg-light" value={this.state.search_by} onChange={this.onChangeSearchBy} style={{ width: '400px' }}>
                    <option value="">---</option>
                    <option value="1">Phone Number</option>
                    <option value="2">Id Number</option>
                  </select>
              </div>
              {
                this.state.showPhone &&
                <div className="mx-3 d-inline-block mb-3">
                <span className="ml-1 mb-2 d-block text-capitalize">Grades</span>
                <select className="form-control bg-light" name="entitled_id" value={this.state.entitled_id} onChange={this.onChangeGradeList} style={{ width: '400px' }} defaultValue="">
                <option  value="">---</option>
                </select>
                </div>
                }
                {
                this.state.showId &&
                <div className="mx-3 d-inline-block mb-3">
                <span className="ml-1 mb-2 d-block text-capitalize">Streams</span>
                <select className="form-control bg-light" name="entitled_id" value={this.state.entitled_id} onChange={this.onChangeStreamList} style={{ width: '400px' }} defaultValue="">
                <option  value="">---</option>
                </select>
                </div>
                }
              <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
              <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-normal">Search</button>
              </div>
            </form> */}
            <Form
              submitText={"Search"}
              back={false}
              inputs={[
                // {
                //   label: "Search By",
                //   name: "type",
                // //   readonly: "readonly",
                //   value: this.state.type,
                //   type: "select",
                //   options: [
                //     { name: "Phone Number", value: "1" },
                //     { name: "Id Number", value: "2" }
                //   ]
                // },
                {
                  label: "Search by Id number or by phone number",
                  name: "search",
                  type: "text",
                  value: this.state.searchValue,
                },
                // {
                //   label: "Search by phone number",
                //   name: "search",
                //   type: "text",
                //   value: this.state.searchValue
                // }
              ]}
              submit={(data) => {
                //console.log(data);
                //this.setState({ details: data });
                setTimeout(() => {
                  this.handleSearch(data);
                }, 0);
              }}
            />
          </div>
        </div>

        <div className='mt-3 table-card  border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Table
              // search={["firstname", "middlename", "surname", "msisdn", "id_number"]}
              sort='id'
              sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  handleOnChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleSearch = (data) => {
    console.log(data);
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);
    let values = Object.values(obj);

    //alert(values);
    fetch(`${window.server}/utils/customer-records/?search=${values}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        //console.log(response);
        let record = [];

        response.data.map((d) => {
          record.push({
            id: d.customer_id,
            full_name:
              d.customer === null
                ? "--"
                : `${d.firstname} ${d.middlename} ${d.surname}`,
            phone_number: d.msisdn,
            iD_number: d.id_number,
            // gender: { M: "Male", F: "Female" }[d.gender],
            KRA_pin: d.kra_pin,
            date_of_registration: moment(d.created_at).format("Do MMMM YYYY"),
            pledge_amount:
              d.customer === null
                ? "--"
                : parseFloat(parseFloat(d.pledge)).toLocaleString(),
            // identity_type: d.identity_type,
            // sub_county_id: d.sub_county_id,
            // ward_id: d.ward_id,
            account_status: [
              "Exited",
              "Active",
              "Blocked",
              "Pending Exit",
              "Dormant",
            ][d.account_status],
            action: (
              <div className='d-flex flex-row'>
                <Link
                  to={"/member-view/details/" + d.customer_id}
                  className='btn btn-sm btn-primary px-3 btn-round'
                >
                  View
                </Link>
              </div>
            ),
          });
        });

        response.data = record;
        //console.log(record);
        let dts = {};
        dts.data = record;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false,
        });
      })
      .catch((d) => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    let $t = this;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(function () {
      $t.handleSearch();
    }, 100);
  }
}

export default Members;
